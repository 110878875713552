import { storyblokEditable } from '@storyblok/react'

import { PlayerCard as PlayerCardComponent } from '../../../teams/components/player-card'

import type { StoryblokPlayer } from './PlayerCard.types'

export type PlayerCardProps = {
  blok: {
    _uid: string
    player: {
      content: StoryblokPlayer
    }
  }
}

export const PlayerCard = ({ blok }: PlayerCardProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <PlayerCardComponent
        first_name={blok.player.content?.first_name}
        last_name={blok.player.content?.last_name}
        image={blok.player.content?.image}
        image_hover={blok.player.content?.image_hover}
        country_code={blok.player.content?.country_code}
        position={blok.player.content?.position}
        birth_date={blok.player.content?.birth_date}
        playerPage={
          blok.player.content?.player_page?.full_slug
            ? `/${blok.player.content.player_page?.full_slug}`
            : '#'
        }
      />
    </div>
  )
}
