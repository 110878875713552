import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { useCallback } from 'react'

import { Button } from '../../../shared/components/button'
import { RadioButton } from '../../../shared/components/radio-button'
import { Heading3 } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import { StCard, StCardList, StCardNumber } from './AccountCardSelection.styled'

import type { MyCercleCard } from '../../types/myCercleCards'
import type { Dispatch, SetStateAction } from 'react'

type AccountCardSelectionProps = {
  cards: MyCercleCard[]
  selectedCard: number
  setSelectedCard: Dispatch<SetStateAction<number>>
  handleCardSelected: () => void
}

export const AccountCardSelection = ({
  cards,
  selectedCard,
  setSelectedCard,
  handleCardSelected,
}: AccountCardSelectionProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const isSelected = useCallback(
    (index: number) => index === selectedCard,
    [selectedCard]
  )
  return (
    <>
      <Heading3>{t('account_card_selection_subTitle')}</Heading3>
      <StCardList>
        {cards.map((card, index) => (
          <StCard key={card.id}>
            <RadioButton
              label={card.name}
              name="carcle-card"
              value={index}
              isSelected={isSelected(index)}
              onChange={(event) => setSelectedCard(Number(event.target.value))}
            >
              <StCardNumber>{card.number}</StCardNumber>
            </RadioButton>
          </StCard>
        ))}
        {/* Add New Card */}
        <StCard>
          <RadioButton
            label={t('account_card_selection_add_card')}
            name="carcle-card"
            value={cards.length}
            isSelected={isSelected(cards.length)}
            onChange={(event) => setSelectedCard(Number(event.target.value))}
          />
        </StCard>
      </StCardList>
      <Button
        variant="primary"
        gaEventLabel="press_select_my_cercle_card_modal"
        onClick={handleCardSelected}
        suffixIcon={faArrowRight}
      >
        {t('account_card_selection_next_step')}
      </Button>
    </>
  )
}
