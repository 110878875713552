import { isStoryblokImage, isObject } from 'types'

import type { SbBlokData } from '@storyblok/react'
import type { StoryblokImage } from 'types'

export interface ArticleHeaderContent extends SbBlokData {
  _uid: string
  image: StoryblokImage
  title: string
  subtitle: string
}

export function isPageHeaderContent(
  blok: SbBlokData
): blok is ArticleHeaderContent {
  if (isObject(blok)) {
    // UID
    const { _uid } = blok
    if (typeof _uid !== 'string') {
      return false
    }
    // Image
    const { image } = blok
    if (!isStoryblokImage(image)) {
      return false
    }
    // Title
    const { title } = blok
    if (typeof title !== 'string') {
      return false
    }
    // Subtitle
    const { subtitle } = blok
    if (typeof subtitle !== 'string') {
      return false
    }
  } else {
    return false
  }
  return true
}
