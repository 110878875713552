import styled from 'styled-components'

import { CercleCircle } from '../../../shared/components/cercle-circle'
import { HamburgerIcon } from '../../../shared/components/hamburger-icon'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'

export const StContainer = styled.div`
  height: ${({ theme }) => theme.UI.SpacingPx.Space20};
  background-color: ${({ theme }) => theme.Colors.Neutrals.Black};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  overflow: hidden;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    height: ${({ theme }) => theme.UI.SpacingPx.Space28};
  }
`

export const StBackground = styled(CercleCircle)`
  position: absolute;
  transform: scale(1.8);
  top: -280px;
  left: 0;
  right: 0;
  margin: 0 auto;
  fill: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  z-index: 1;
`

export const StContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.Page};
  height: 100%;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

export const StHeaderSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  height: 100%;
`

export const StHeaderLinks = styled.div`
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space8};
  height: 100%;
`

export const StHamburgerIcon = styled(HamburgerIcon)`
  z-index: 100;
`

export const StButtonContainer = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
  z-index: 100;
`

export const StHomeLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StHomeLogo = styled(StoryblokServiceImage)`
  width: 60px;
  height: auto;
  z-index: 100;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    width: 80px;
  }
`
export const StHeaderSpacer = styled.div`
  height: ${({ theme }) => theme.UI.SpacingPx.Space20};
  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    height: ${({ theme }) => theme.UI.SpacingPx.Space28};
  }
`
