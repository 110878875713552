import type { CookieConfig } from '../types/CookieConfig.types'

// Cookie policy
export const cookieConfig: CookieConfig = {
  policies: [
    {
      id: 'essential',
      label: 'Essential Cookies',
      description:
        'We need to save some core cookies for the website to function properly. Eg. language settings.',
      category: 'essential',
    },
    {
      id: 'statistics',
      label: 'Statistics',
      category: 'statistics',
      description:
        "We'd like to save some technical cookies, to track performance/issues of the website.",
    },
    {
      id: 'social',
      label: 'Social Media Cookies',
      category: 'social',
      description:
        "We' like to save some social cookies, to enhance your experience.",
    },
    {
      id: 'marketing',
      label: 'Marketing Cookies',
      category: 'marketing',
      description:
        "We' like to save some marketing cookies, to enhance your experience.",
    },
  ],
  essentialLabel: 'Always on',
  permissionLabels: {
    accept: 'Accept',
    acceptAll: 'Accept all',
    decline: 'Decline',
  },
  cookiePreferenceKey: 'cookie-preferences',
  header: {
    title: 'Cookie Policy',
    description: 'We use cookies to guarantee you a better experience.',
  },
  cookiePolicy: {
    url: 'https://cercle-brugge.com/general/cookie-policy',
    label: 'Read the full cookie declaration',
  },
  customizeLabel: 'Customize',
}
