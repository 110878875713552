import { storyblokEditable } from '@storyblok/react'
import { useLayoutEffect, useRef } from 'react'

import { IFrameEmbed as GenericEmbedComponent } from '../../../embed/components/iframe-embed'
import { getCrowdManagerInfo } from '../../utils/crowdManager'

interface GenericEmbedProps {
  blok: {
    _uid: string
    iframe_code: string
  }
}

export const GenericEmbed = ({ blok }: GenericEmbedProps) => {
  const firstRenderRef = useRef(true)

  useLayoutEffect(() => {
    if (!firstRenderRef.current) {
      return
    }
    firstRenderRef.current = false

    window.twttr?.widgets.load()

    window.tf?.load()

    const crowdManager = getCrowdManagerInfo('embed-' + blok._uid)
    if (crowdManager) {
      window.FD?.FD.Embedding.initialize(crowdManager)
    }
  }, [])

  return (
    <div {...storyblokEditable(blok)}>
      <GenericEmbedComponent
        containerId={'embed-' + blok._uid}
        iFrameCode={blok.iframe_code.replace('src', 'loading="lazy" src')}
      />
    </div>
  )
}
