import styled from 'styled-components'

export const StAmountList = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StAmount = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space6}`};
`

export const StRadioButtonLabel = styled.label`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

export const StButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
`
