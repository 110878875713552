export const PersonCard = () => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <p>
        Person Card is a subcomponent. Please use the main component instead of
        placing this subcomponent directly on the website.
      </p>
    )
  }
  return null
}
