import { storyblokEditable } from '@storyblok/react'

import { PageHeader as PageHeaderComponent } from '../../../content/components/page-header'

import { isPageHeaderContent } from './PageHeader.types'

import type { SbBlokData } from '@storyblok/react'
import type { StoryblokImage } from 'types'

type StoryblokPageHeaderrProps = {
  blok: SbBlokData & {
    image: StoryblokImage
  }
}

export const PageHeader = ({ blok }: StoryblokPageHeaderrProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      {isPageHeaderContent(blok) && (
        <PageHeaderComponent
          title={blok.title}
          subtitle={blok.subtitle}
          image={blok?.image}
        />
      )}
    </div>
  )
}
