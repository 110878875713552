export const theme = {
  Colors: {
    Components: {
      Button: {
        Primary: {
          ActiveBorder: 'rgba(0, 0, 0, 0)',
          ActiveFill: '#60b22c',
          ActiveIcon: '#ffffff',
          ActiveText: '#ffffff',
          Border: 'rgba(0, 0, 0, 0)',
          DisabledBorder: 'rgba(0, 0, 0, 0)',
          DisabledFill: '#323232',
          DisabledIcon: '#b4b4b4',
          DisabledText: '#b4b4b4',
          Fill: '#60b22c',
          HoverFill: '#499917',
          HoverIcon: '#ffffff',
          HoverText: '#ffffff',
          Icon: '#ffffff',
          Text: '#ffffff',
        },
        Secondary: {
          ActiveBorder: 'rgba(0, 0, 0, 0)',
          ActiveFill: '#d9d9d9',
          ActiveIcon: '#000000',
          ActiveText: '#000000',
          Border: 'rgba(240, 240, 240, 0)',
          DisabledBorder: 'rgba(0, 0, 0, 0)',
          DisabledFill: '#323232',
          DisabledIcon: '#b4b4b4',
          DisabledText: '#b4b4b4',
          Fill: '#d9d9d9',
          HoverFill: '#bfbfbf',
          HoverIcon: '#000000',
          HoverText: '#000000',
          Icon: '#000000',
          Text: '#000000',
        },
        Tertiary: {
          ActiveFill: 'rgba(255, 255, 255, 0)',
          ActiveIcon: '#529926',
          ActiveText: '#529926',
          DisabledFill: 'rgba(255, 255, 255, 0)',
          DisabledIcon: '#4b4b4b',
          DisabledText: '#4b4b4b',
          Fill: 'rgba(255, 255, 255, 0)',
          HoverFill: 'rgba(255, 255, 255, 0)',
          HoverIcon: '#60b22c',
          HoverText: '#60b22c',
          Icon: '#529926',
          Text: '#ffffff',
        },
      },
      Input: {
        ActiveBorder: '#ffffff',
        ActiveFill: '#4b4b4b',
        Border: '#7e7e7e',
        DisabledBorder: '#4b4b4b',
        DisabledFill: '#000000',
        DisabledPlaceholder: '#b4b4b4',
        ErrorBorder: '#ff4a4a',
        ErrorFill: '#4b4b4b',
        ErrorText: '#ff9b9b',
        Fill: '#4b4b4b',
        Placeholder: '#d9d9d9',
        SuccessBorder: '#60b22c',
        SuccessFill: '#323232',
        SuccessText: '#6ecc33',
        Text: '#ffffff',
      },
      Toaster: {
        Border: 'rgba(0, 0, 0, 0)',
        ErrorBorder: 'rgba(0, 0, 0, 0)',
        ErrorFill: '#e71616',
        ErrorText: '#ffffff',
        Fill: '#4b4b4b',
        SuccessBorder: 'rgba(0, 0, 0, 0)',
        SuccessFill: '#60b22c',
        SuccessText: '#ffffff',
        Text: '#ffffff',
      },
      StatsCardRed: '#ff5e7a',
      StatsCardYellow: '#ffc21c',
    },
    Neutrals: {
      Black: '#000000',
      Dark: '#4b4b4b',
      Darker: '#323232',
      Darkest: '#222222',
      Light: '#b4b4b4',
      Lighter: '#d9d9d9',
      Lightest: '#f0f0f0',
      Medium: '#7e7e7e',
      White: '#ffffff',
    },
    Primary: {
      Base: '#60b22c',
      Darker: '#499917',
      Lighter: '#6ecc33',
    },
    Semantic: {
      Disabled: '#b4b4b4',
      Error: '#ff4a4a',
      ErrorLight: '#ff9b9b',
      Success: '#60b22c',
      Warning: '#eab52c',
    },
  },
  Typography: {
    ArticleTitle: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '700',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '22px',
        'font-weight': '700',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '700',
      },
    },
    BodyLargeBold: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '700',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '700',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '700',
      },
    },
    BodyLargeLink: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '400',
        'text-decoration': 'underline',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '400',
        'text-decoration': 'underline',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '400',
        'text-decoration': 'underline',
      },
    },
    BodyLargeRegular: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '400',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '400',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '32px',
        'font-weight': '400',
      },
    },
    BodyMediumBold: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '800',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '800',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '800',
      },
    },
    BodyMediumLink: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '500',
        'text-decoration': 'underline',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '500',
        'text-decoration': 'underline',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '500',
        'text-decoration': 'underline',
      },
    },
    BodyMediumRegular: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '500',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '15px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '500',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '500',
      },
    },
    BodySmallBold: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '20px',
        'font-weight': '800',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '13px',
        'letter-spacing': '0.5px',
        'line-height': '18px',
        'font-weight': '800',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '20px',
        'font-weight': '800',
      },
    },
    BodySmallLink: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0.4px',
        'line-height': '20px',
        'font-weight': '500',
        'text-decoration': 'underline',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '13px',
        'letter-spacing': '0.4px',
        'line-height': '20px',
        'font-weight': '500',
        'text-decoration': 'underline',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0.4px',
        'line-height': '20px',
        'font-weight': '500',
        'text-decoration': 'underline',
      },
    },
    BodySmallRegular: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '20px',
        'font-weight': '500',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '13px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '500',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '20px',
        'font-weight': '500',
      },
    },
    BodyXLargeBold: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '38px',
        'font-weight': '700',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '700',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '38px',
        'font-weight': '700',
      },
    },
    BodyXLargeLink: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '38px',
        'font-weight': '400',
        'text-decoration': 'underline',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '400',
        'text-decoration': 'underline',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '38px',
        'font-weight': '400',
        'text-decoration': 'underline',
      },
    },
    BodyXLargeRegular: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '38px',
        'font-weight': '400',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '28px',
        'font-weight': '400',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '38px',
        'font-weight': '400',
      },
    },
    ButtonLabel: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.5px',
        'line-height': '16px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    ButtonLabelTertiary: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-decoration': 'underline',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.5px',
        'line-height': '16px',
        'font-weight': '400',
        'text-decoration': 'underline',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-decoration': 'underline',
        'text-transform': 'uppercase',
      },
    },
    Heading1: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '48px',
        'letter-spacing': '0px',
        'line-height': '48px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '40px',
        'letter-spacing': '0px',
        'line-height': '40px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    Heading2: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '36px',
        'letter-spacing': '0.5px',
        'line-height': '32px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '28px',
        'letter-spacing': '0.5px',
        'line-height': '28px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    Heading3: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '24px',
        'letter-spacing': '1px',
        'line-height': '24px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '18px',
        'letter-spacing': '0.5px',
        'line-height': '18px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '22px',
        'letter-spacing': '1px',
        'line-height': '24px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    Heading4: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.5px',
        'line-height': '16px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '18px',
        'letter-spacing': '0.5px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    Heading5: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.3px',
        'line-height': '16px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '15px',
        'letter-spacing': '0.3px',
        'line-height': '15px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.3px',
        'line-height': '16px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    Heading6: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '1.5px',
        'line-height': '20px',
        'font-weight': '800',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '13px',
        'letter-spacing': '1.5px',
        'line-height': '20px',
        'font-weight': '800',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '1.5px',
        'line-height': '20px',
        'font-weight': '800',
        'text-transform': 'uppercase',
      },
    },
    Tag: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.3px',
        'line-height': '16px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '15px',
        'letter-spacing': '0.2px',
        'line-height': '15px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '16px',
        'letter-spacing': '0.3px',
        'line-height': '16px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    StatCardNumber: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '24px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    StatHeading: {
      Desktop: {
        'font-family': 'Hype 1400',
        'font-size': '44px',
        'letter-spacing': '0px',
        'line-height': '44px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Mobile: {
        'font-family': 'Hype 1400',
        'font-size': '20px',
        'letter-spacing': '0px',
        'line-height': '20px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
      Tablet: {
        'font-family': 'Hype 1400',
        'font-size': '36px',
        'letter-spacing': '0px',
        'line-height': '36px',
        'font-weight': '400',
        'text-transform': 'uppercase',
      },
    },
    StatCardLabel: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '18px',
        'font-weight': '400',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '400',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '18px',
        'font-weight': '400',
      },
    },
    StatLabel: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '22px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '400',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '400',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '18px',
        'letter-spacing': '0px',
        'line-height': '18px',
        'font-weight': '400',
      },
    },
    StatSubLabel: {
      Desktop: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '700',
      },
      Mobile: {
        'font-family': 'Helvetica Now Display',
        'font-size': '14px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '700',
      },
      Tablet: {
        'font-family': 'Helvetica Now Display',
        'font-size': '16px',
        'letter-spacing': '0px',
        'line-height': '24px',
        'font-weight': '700',
      },
    },
  },
}

export type GeneratedTheme = typeof theme
