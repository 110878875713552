import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'

import {
  BottomLink,
  BottomLinkText,
  BottomLinksContainer,
} from './BottomLinks.styled'

import type { StoryblokTextLink } from '../../../storyblok/components/text-link/TextLink.types'

interface Props {
  className?: string
  links: StoryblokTextLink[]
}

export const BottomLinks = ({ className, links }: Props) => {
  return (
    <BottomLinksContainer className={className}>
      {links &&
        links.length > 0 &&
        links.map((item) => (
          <BottomLink
            key={item._uid}
            href={getHrefFromStoryblokLink(item.link)}
            title={item.link.title}
          >
            <BottomLinkText>{item.name}</BottomLinkText>
          </BottomLink>
        ))}
    </BottomLinksContainer>
  )
}
