import useEmblaCarousel from 'embla-carousel-react'
import { useCallback, useEffect, useState } from 'react'

import {
  StContainer,
  StContentContainer,
  StSlide,
  StCounterContainer,
  StCount,
  StOverlayGradient,
  StTitle,
  StImage,
} from './HighlightBannerCarousel.styled'

import type { HighlightBannerItem } from '../../types/HighlightBanner.types'

type HighlightBannerCarouselProps = {
  className?: string
  items: HighlightBannerItem[]
}

export const HighlightBannerCarousel = ({
  className,
  items,
}: HighlightBannerCarouselProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  const handleCarouselChange = useCallback(() => {
    if (emblaApi) {
      setCurrentSlideIndex(emblaApi.selectedScrollSnap())
    }
  }, [emblaApi])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('select', handleCarouselChange)
    }
  }, [emblaApi, handleCarouselChange])

  if (!items || items.length <= 0) {
    return null
  }

  return (
    <StContainer className={className} ref={emblaRef}>
      {items && items.length > 0 && (
        <>
          <StContentContainer>
            {items.map((item) => {
              return (
                <StSlide href={item.link?.href || '#'} key={item.id}>
                  <StImage priority image={item.image} />
                  <StOverlayGradient />
                  <StTitle>{item.full_title}</StTitle>
                </StSlide>
              )
            })}
          </StContentContainer>
          {items.length > 1 && (
            <StCounterContainer>
              {items.map((item, index) => {
                return (
                  <StCount
                    key={`count-${item.id}`}
                    isActive={index === currentSlideIndex}
                  />
                )
              })}
            </StCounterContainer>
          )}
        </>
      )}
    </StContainer>
  )
}
