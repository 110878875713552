import { Icon } from './SocialIcon.styled'

import type { StoryblokImage } from 'types'

type SocialIconProps = {
  image: StoryblokImage
  url: string
  title?: string
}

export const SocialIcon = ({ image, url, title }: SocialIconProps) => {
  return (
    <a href={url} title={title}>
      <Icon image={image}></Icon>
    </a>
  )
}
