import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  Heading5,
  Heading2,
  BodyMediumBold,
  BodyMediumRegular,
} from '../../../shared/components/typography'

type StOverlayProps = {
  $is_mouse_over: boolean
}

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const StPlayerImageContainer = styled.a`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`

export const StPlayerImageHover = styled(StoryblokServiceImage)<StOverlayProps>`
  position: absolute;
  top: 0;
  opacity: ${({ $is_mouse_over }) => ($is_mouse_over ? 1 : 0)};
  visibility: ${({ $is_mouse_over }) =>
    $is_mouse_over ? 'visible' : 'hidden'};
  transition: visibility 0s, opacity 0.05s linear;
  width: 100%;
  height: 100%;
`

export const StPlayerImageOverlay = styled.div<StOverlayProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(
    179.54deg,
    rgba(0, 0, 0, 0) 0.4%,
    rgba(0, 0, 0, 0.8) 65.22%
  );
  opacity: ${({ $is_mouse_over }) => ($is_mouse_over ? 1 : 0)};
  visibility: ${({ $is_mouse_over }) =>
    $is_mouse_over ? 'visible' : 'hidden'};
  transition: visibility 0s, opacity 0.2s linear;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    height: 150px;
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    height: 175px;
  }
`
export const StPlayerCountry = styled.span`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StPlayerStat = styled(BodyMediumBold)``

export const StPlayerSubStat = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
`

export const StNameContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-decoration: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    height: 150px;
  }
`

export const StFirstName = styled(Heading5)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StLastName = styled(Heading2)`
  color: ${({ theme }) => theme.Colors.Primary.Base};
  text-align: center;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};
`
