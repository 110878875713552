import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark'
import { useState, useCallback, useEffect } from 'react'
import { Portal } from 'react-portal'

import { SocialIcon } from '../../../footer/components/social-icon'

import {
  StBackground,
  StContainer,
  StSidebarHeader,
  StSidebarTitle,
  StIcon,
  StSidebarContent,
  StSidebarItemContainer,
  StSidebarItem,
  StSidebarItemLink,
  StSidebarItemIcon,
  StSubMenuContainer,
  StSubMenuItem,
  StSidebarFooter,
  StInnerContainer,
  StCercleCircle,
} from './Sidebar.styled'

import type { StoryblokImageLink } from '../../../storyblok/components/image-link/ImageLink.types'
import type { DropdownItem } from '../../types/DropdownItem.types'

interface SidebarProps {
  className?: string
  isActive: boolean
  handleSidebarToggle: () => void
  closeLabel?: string
  backLabel?: string
  teamsLabel?: string
  teamsDropdownItems: DropdownItem[]
  otherDropdownItems: DropdownItem[]
  socialLinks: StoryblokImageLink[]
}

export const Sidebar = ({
  className,
  isActive,
  handleSidebarToggle,
  closeLabel,
  backLabel,
  teamsLabel,
  teamsDropdownItems,
  otherDropdownItems,
  socialLinks,
}: SidebarProps) => {
  const [isTeamsActive, setIsTeamsActive] = useState<boolean>(false)
  const [activeMenuItem, setActiveMenuItem] = useState<undefined | string>()
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  useEffect(() => {
    if (isActive) {
      document.body.classList.add('overlay-open')
    } else {
      document.body.classList.remove('overlay-open')
    }
  }, [isActive])

  const handleMenuItemClick = useCallback(
    (id: string) => {
      if (activeMenuItem === id) {
        setActiveMenuItem(undefined)
      } else {
        setActiveMenuItem(id)
      }
    },
    [activeMenuItem]
  )

  const handleTeamsMenuItemClick = useCallback(() => {
    setIsTeamsActive((previous) => !previous)
  }, [])

  const renderSubMenu = (items: DropdownItem[]) => {
    if (!items || items.length <= 0) {
      return null
    }
    return items.map((item) => {
      if (!item.links || item.links.length <= 0) {
        return null
      }

      if (item.links.length === 1) {
        return (
          <StSidebarItemContainer key={`sidebar-${item.id}`}>
            <StSidebarItemLink
              href={item.links[0].link.href}
              title={item.title}
              target={item.links[0].link.target ?? '_self'}
            >
              <StSidebarTitle>{item.title}</StSidebarTitle>
            </StSidebarItemLink>
          </StSidebarItemContainer>
        )
      }

      return (
        <StSidebarItemContainer key={`sidebar-${item.id}`}>
          <StSidebarItem onClick={() => handleMenuItemClick(item.id)}>
            <StSidebarTitle>{item.title}</StSidebarTitle>
            <StSidebarItemIcon
              icon={activeMenuItem === item.id ? faMinus : faPlus}
            ></StSidebarItemIcon>
          </StSidebarItem>
          {activeMenuItem === item.id ? (
            <StSubMenuContainer>
              {item.links && item.links.length > 0
                ? item.links.map((link) => {
                    if (!link.link.href || link.link.href === '') {
                      return null
                    }
                    return (
                      <StSubMenuItem
                        href={link.link.href}
                        title={link.title}
                        target={link.link.target ?? '_self'}
                        key={`sub-menu-${link.id}`}
                      >
                        {link.title}
                      </StSubMenuItem>
                    )
                  })
                : null}
            </StSubMenuContainer>
          ) : null}
        </StSidebarItemContainer>
      )
    })
  }

  if (domLoaded) {
    return (
      <Portal>
        <StBackground isActive={isActive} onClick={handleSidebarToggle} />
        <StContainer isActive={isActive} className={className}>
          <StInnerContainer>
            {isTeamsActive ? (
              <StSidebarHeader onClick={handleTeamsMenuItemClick}>
                <StIcon icon={faChevronLeft}></StIcon>
                <StSidebarTitle>{backLabel ?? 'Terug'}</StSidebarTitle>
              </StSidebarHeader>
            ) : (
              <StSidebarHeader onClick={handleSidebarToggle}>
                <StIcon icon={faXmark}></StIcon>
                <StSidebarTitle>{closeLabel ?? 'Sluiten'}</StSidebarTitle>
              </StSidebarHeader>
            )}
            <StSidebarContent>
              {isTeamsActive ? null : (
                <StSidebarItemContainer>
                  <StSidebarItem onClick={handleTeamsMenuItemClick}>
                    <StSidebarTitle>{teamsLabel ?? 'Teams'}</StSidebarTitle>
                    <StSidebarItemIcon
                      icon={faChevronRight}
                    ></StSidebarItemIcon>
                  </StSidebarItem>
                </StSidebarItemContainer>
              )}
              {isTeamsActive
                ? renderSubMenu(teamsDropdownItems)
                : renderSubMenu(otherDropdownItems)}
            </StSidebarContent>
            {socialLinks && socialLinks.length > 0 ? (
              <StSidebarFooter>
                {socialLinks.map(
                  (socialLink) =>
                    socialLink.image.filename && (
                      <SocialIcon
                        image={socialLink.image}
                        url={socialLink.link.url || ''}
                        key={socialLink._uid}
                      />
                    )
                )}
              </StSidebarFooter>
            ) : null}
            <StCercleCircle variation={3} />
          </StInnerContainer>
        </StContainer>
      </Portal>
    )
  }

  return null
}
