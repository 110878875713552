import type { SbArticlePageData } from '../../components/article-page/ArticlePage.types'
import type { SbMatchLiveUpdateData } from '../../components/match-live-update/MatchLiveUpdate.types'
import type { StoryblokTeam } from '../team'
import type { ISbStoryData } from '@storyblok/react'
import type { StoryblokLink } from 'types'

export type StoryblokMatch = {
  _uid: string
  time?: string
  league: string
  league_match_info?: string
  home_team: string | ISbStoryData<StoryblokTeam>
  home_team_goals?: string
  away_team: string | ISbStoryData<StoryblokTeam>
  away_team_goals?: string
  info?: StoryblokLink
  tickets?: StoryblokLink
  vip?: StoryblokLink
  live_updates: SbMatchLiveUpdateData[]

  report?: StoryblokLink
  report2: ISbStoryData<SbArticlePageData>

  // Timings
  matchday: string
  half_time: string
  second_half: string
  full_time: string
  extra_time_first_half: string
  extra_time_half_time: string
  extra_time_second_half: string
  extra_time_full_time: string
  penalty_shootout_start: string
  penalty_shootout_end: string
  match_suspended: string
  match_resumed: string
}

export enum StoryblokMatchLiveUpdateType {
  Goal = 'goal',
  YellowCard = 'yellow_card',
  RedCard = 'red_card',
  PlayerChange = 'player_change',
  Shot = 'shot',
  Video = 'video',
  Fact = 'fact',
  Injury = 'injury',
  MatchEvent = 'match_event',
  Referee = 'referee',
  Generic = 'generic',
  Statistic = 'statistic',
}
