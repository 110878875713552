import styled from 'styled-components'

import { DefensiveStats } from '../defensive-stats'
import { GeneralStats } from '../general-stats'
import { OffensiveStats } from '../offensive-stats'
import { PlayerInformation } from '../player-information'

export const StLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-height: 400px;
`

export const StContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'info'
    'general-stats'
    'offensive-stats'
    'defensive-stats';

  @media screen and (min-width: 1250px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'general-stats info'
      'offensive-stats defensive-stats';
  }
`

export const StPlayerInformation = styled(PlayerInformation)`
  grid-area: info;
`
export const StGeneralStats = styled(GeneralStats)`
  grid-area: general-stats;
`
export const StOffensiveStats = styled(OffensiveStats)`
  grid-area: offensive-stats;
`
export const StDefensiveStats = styled(DefensiveStats)`
  grid-area: defensive-stats;
`
