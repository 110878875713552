import { storyblokEditable } from '@storyblok/react'

import { IFrameEmbed as VideoEmbedComponents } from '../../../embed/components/iframe-embed'

interface VideoEmbedProps {
  blok: {
    _uid: string
    iframe_code: string
  }
}

export const VideoEmbed = ({ blok }: VideoEmbedProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <VideoEmbedComponents
        iFrameCode={blok.iframe_code.replace('src', 'loading="lazy" src')}
        isVideo={true}
      />
    </div>
  )
}
