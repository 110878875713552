import { StoryblokComponent } from '@storyblok/react'

import { Divider } from '../../../shared/components/divider'

import { StContainer } from './PlayerCardContainer.styled'

import type { StoryblokPlayer } from '../player-card/PlayerCard.types'
import type { ISbStoryData } from '@storyblok/react'

type StoryblokPlayerCardContainer = {
  blok: {
    title?: string
    players?: {
      _uid: string
      player?: {
        content?: StoryblokPlayer
      }
    }[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const PlayerCardContainer = ({
  blok,
  locale,
  relations,
}: StoryblokPlayerCardContainer) => {
  return (
    <>
      <Divider title={blok.title}></Divider>
      {blok.players && blok.players.length > 0 && (
        <StContainer>
          {blok.players.map((player) => (
            <StoryblokComponent
              blok={player}
              key={player._uid}
              locale={locale}
              relations={relations}
            />
          ))}
        </StContainer>
      )}
    </>
  )
}
