// eslint-disable-next-line no-restricted-imports
import { faFutbol } from '@fortawesome/pro-solid-svg-icons'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import { useRouter } from 'next/router'

import { useUser } from '../../../authentication/hooks/useUser'
import { PageHeader } from '../../../content/components/page-header'
import { ButtonLink } from '../../../shared/components/button'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useTranslations } from '../../hooks/useTranslations'

import {
  StArticleContent,
  StArticleDate,
  StArticleMetaContainer,
  StArticleTag,
  StArticleTagContainer,
  StArticleTitle,
  StPremiumCard,
  StPremiumCardContainer,
  StPremiumCardBody,
  StPremiumCardTitle,
  StPremiumOverlay,
  StPremiumPreview,
  StPremiumCardBodyAccent,
  StLoadingIcon,
  StArticleContainer,
} from './ArticlePage.styled'
import { isSbArticlePageData } from './ArticlePage.types'

import type { SbArticlePageData } from './ArticlePage.types'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ISbStoryData } from '@storyblok/react'
import type { ReactNode } from 'react'

interface Props {
  blok: SbArticlePageData
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const ArticlePage = ({ blok, locale, relations }: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const router = useRouter()

  const { isAuthenticated, isLoading } = useUser()

  const renderPremiumContentCardBody = () => {
    const premiumContentCardbody = t('premium_content_card_body')
    const premiumContentCardbodyAccent = t('premium_content_card_body_accent')

    const body: (string | ReactNode)[] = []

    if (premiumContentCardbodyAccent) {
      premiumContentCardbody
        .split(premiumContentCardbodyAccent)
        .forEach((text: string) => {
          body.length > 0 &&
            body.push(
              <StPremiumCardBodyAccent>
                {premiumContentCardbodyAccent}
              </StPremiumCardBodyAccent>
            )
          body.push(text)
        })
      return body
    }
    return premiumContentCardbody
  }

  return (
    <div {...storyblokEditable(blok)}>
      {blok?.banner && <PageHeader image={blok?.banner} />}
      {isSbArticlePageData(blok) && (
        <StArticleContainer>
          <StArticleMetaContainer>
            {blok.tags && blok.tags.length > 0 && (
              <StArticleTagContainer>
                <StArticleTag>{blok.tags[0]}</StArticleTag>
              </StArticleTagContainer>
            )}
            {blok.publish_date && (
              <StArticleTagContainer>
                <StArticleDate>
                  {
                    new Date(blok.publish_date)
                      .toLocaleString('en-GB')
                      .split(',')[0]
                  }
                </StArticleDate>
              </StArticleTagContainer>
            )}
          </StArticleMetaContainer>
          <StArticleTitle>{blok.title}</StArticleTitle>

          {isLoading ? (
            <StLoadingIcon icon={faFutbol as IconProp} spin />
          ) : (
            <>
              {blok.premium && !isAuthenticated ? (
                <>
                  <StArticleContent>
                    {blok.children && blok.children.length > 0 && (
                      <>
                        <StPremiumPreview>
                          <StoryblokComponent
                            blok={blok.children[0]}
                            key={blok.children[0]._uid}
                            locale={locale}
                            relations={relations}
                          />
                        </StPremiumPreview>
                        <StPremiumOverlay />
                      </>
                    )}
                  </StArticleContent>
                  <StPremiumCardContainer>
                    <StPremiumCard>
                      <StPremiumCardTitle>
                        {t('premium_content_card_title')}
                      </StPremiumCardTitle>
                      <StPremiumCardBody>
                        {renderPremiumContentCardBody()}
                      </StPremiumCardBody>
                      <ButtonLink
                        prefixIcon="user"
                        href={`/api/auth/login?returnTo=${encodeURIComponent(
                          process.env.NEXT_PUBLIC_FRONTEND_URL + router.asPath
                        )}`}
                      >
                        {t('premium_content_button_label')}
                      </ButtonLink>
                    </StPremiumCard>
                  </StPremiumCardContainer>
                </>
              ) : (
                <StArticleContent>
                  {blok.children?.map((nestedBlok) => (
                    <StoryblokComponent
                      blok={nestedBlok}
                      key={nestedBlok._uid}
                      locale={locale}
                      relations={relations}
                    />
                  ))}
                </StArticleContent>
              )}
            </>
          )}
        </StArticleContainer>
      )}
    </div>
  )
}
