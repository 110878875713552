import { isValid } from 'date-fns'
import { useEffect, useState } from 'react'

import { setTimeOnDate } from '../../utils/setTimeOnDate'

import { StContainer, StCountdown } from './MatchCountdown.styled'

interface MatchCountdownProps {
  className?: string
  matchDate: string
  matchTime?: string
  daysShorthand?: string
}

export const MatchCountdown = ({
  className,
  matchDate,
  matchTime,
  daysShorthand,
}: MatchCountdownProps) => {
  const matchDay = new Date(matchDate.replace(' ', 'T'))
  const matchDayWithTime = setTimeOnDate(matchDay, matchTime)
  const [countDown, setCountDown] = useState(
    matchDayWithTime.getTime() - Date.now()
  )

  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  )
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(matchDayWithTime.getTime() - Date.now())
    }, 1000)

    return () => clearInterval(interval)
  }, [matchDayWithTime])

  return (
    <StContainer className={className}>
      {isValid(matchDay) && countDown > 0 && (
        <StCountdown>
          {days}
          {daysShorthand ?? 'D'} {hours < 10 ? `0${hours}` : hours}:
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </StCountdown>
      )}
    </StContainer>
  )
}
