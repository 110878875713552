import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { useCallback, useState } from 'react'

import { Button } from '../../../shared/components/button'
import { Input } from '../../../shared/components/input'
import { RadioButton } from '../../../shared/components/radio-button'
import { Heading3 } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StAmount,
  StAmountList,
  StButtonContainer,
} from './AccountAddBalance.styled'

interface AccountAddBalanceProps {
  onSubmit: (amount: number) => void
  onBack: () => void
}

const amounts = [5, 10, 20, 50]

export const AccountAddBalance = ({
  onSubmit,
  onBack,
}: AccountAddBalanceProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const [selectedAmount, setSelectedAmount] = useState(0)
  const [otherAmount, setOtherAmount] = useState(0)

  const isSelected = useCallback(
    (index: number) => index === selectedAmount,
    [selectedAmount]
  )

  const handleAmountChange = (amount: string) => {
    setSelectedAmount(Number(amount))
  }

  const handleSubmit = () => {
    onSubmit(selectedAmount === amounts.length ? otherAmount : selectedAmount)
  }

  return (
    <>
      <Heading3>{t('account_balance_add_title')}</Heading3>
      <StAmountList>
        {amounts.map((amount, index) => (
          <StAmount key={index}>
            <RadioButton
              label={`€${amount}`}
              name="add-amount"
              value={amount}
              isSelected={isSelected(amount)}
              onChange={(event) => handleAmountChange(event.target.value)}
            />
          </StAmount>
        ))}
        {/* Other option amount */}
        <StAmount>
          <RadioButton
            label={t('account_balance_add_other')}
            name="add-amount"
            value={amounts.length}
            isSelected={isSelected(amounts.length)}
            onChange={(event) => handleAmountChange(event.target.value)}
          />
        </StAmount>
        {selectedAmount === amounts.length && (
          <Input
            type="number"
            step={1}
            onBlur={() => {
              setOtherAmount(
                (previousValue) =>
                  previousValue && Math.round(Number(previousValue))
              )
            }}
            placeholder={t('account_balance_add_placeholder')}
            onChange={(event) => setOtherAmount(Number(event.target.value))}
            value={otherAmount}
          />
        )}
      </StAmountList>
      <StButtonContainer>
        <Button
          variant="primary"
          suffixIcon={faArrowRight}
          onClick={handleSubmit}
          gaEventLabel="press_confirm_add_my_cercle_balance_modal"
          gaEventMetaData={otherAmount ?? selectedAmount}
          disabled={
            selectedAmount === 0 ||
            (selectedAmount === amounts.length && !otherAmount)
          }
        >
          {t('account_balance_add_confirm')}
        </Button>
        <Button
          variant="tertiary"
          gaEventLabel="press_back_add_my_cercle_balance_modal"
          onClick={onBack}
        >
          {t('account_balance_add_back')}
        </Button>
      </StButtonContainer>
    </>
  )
}
