import { useStoryblokApi } from '@storyblok/react'
import { useCallback, useEffect, useState } from 'react'

import { getStoryblokStoryVersion } from '../utils/env'

import type { ISbStoriesParams, ISbStoryData } from '@storyblok/react'

export const useStoryblokStoriesPaginated = <T>({
  ...parameters
}: ISbStoriesParams) => {
  const [isLoading, setIsLoading] = useState(true)
  const [totalPages, setTotalPages] = useState(0)
  const [totalStories, setTotalStories] = useState(0)
  const [stories, setStories] = useState<{ [page: number]: ISbStoryData<T>[] }>(
    {}
  )
  const storyblokApi = useStoryblokApi()

  const getStories = useCallback(
    async (parameters_: ISbStoriesParams) => {
      return await storyblokApi.getStories({
        ...parameters_,
        version: getStoryblokStoryVersion(),
      })
    },
    [storyblokApi]
  )

  const getPage = useCallback(
    async (page: number) => {
      let hasLoadingIndicator = false

      if (stories[page] === undefined && stories[page - 1] === undefined) {
        hasLoadingIndicator = true
        setIsLoading(true)
      }

      const newPage = await getStories({ ...parameters, page })

      setStories((previousState) => ({
        ...previousState,
        [page]: newPage.data.stories as ISbStoryData<T>[],
      }))

      if (hasLoadingIndicator) {
        setIsLoading(false)
      }
    },
    [getStories, parameters, stories]
  )

  useEffect(() => {
    const asyncEffect = async () => {
      if (storyblokApi && Object.keys(stories).length === 0) {
        setIsLoading(true)

        const initialPage = parameters.page || 0

        const storiesPage = await getStories({
          ...parameters,
          page: initialPage,
        })
        setStories({
          [initialPage]: storiesPage.data.stories as ISbStoryData<T>[],
        })
        setTotalPages(Math.ceil(storiesPage.total / storiesPage.perPage))
        setTotalStories(storiesPage.total)
        setIsLoading(false)
      }
    }

    asyncEffect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyblokApi, JSON.stringify(parameters)])

  return {
    stories,
    getPage,
    totalPages,
    totalStories,
    isLoading,
  }
}
