import styled from 'styled-components'

export const StMobileHeaderSpacer = styled.div`
  display: block;
  height: ${({ theme }) => theme.UI.SpacingPx.Space18};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`
