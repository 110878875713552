import { useContext } from 'react'

import { SponsorsContext } from '../../../layout/components/page-layout'
import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'

import {
  StImageSecondary,
  StImagePrimary,
  StPrimarySponsor,
  StPrimarySponsorClickable,
  StPrimarySponsors,
  StSecondarySponsor,
  StSecondarySponsorClickable,
  StSecondarySponsors,
} from './Sponsors.styled'

export type SponsorsProps = {
  selection: 'b2c' | 'jeugd' | 'b2b'
}

export const Sponsors = ({ selection }: SponsorsProps) => {
  const sponsors = useContext(SponsorsContext)

  return (
    <>
      {sponsors && sponsors[`primary_sponsors_${selection}`] && (
        <StPrimarySponsors>
          {sponsors &&
            sponsors[`primary_sponsors_${selection}`].map((sponsor) => {
              const sponsorLinkHref = getHrefFromStoryblokLink(sponsor.link)

              return sponsorLinkHref ? (
                <StPrimarySponsorClickable
                  key={sponsor.image.filename}
                  href={sponsorLinkHref}
                  title={sponsor.link.title}
                  target={sponsor.link.target}
                >
                  <StImagePrimary
                    image={sponsor.image}
                    sizeMobile="30vw"
                    sizeTablet="30vw"
                    sizeDesktop="25vw"
                  />
                </StPrimarySponsorClickable>
              ) : (
                <StPrimarySponsor key={sponsor.image.filename}>
                  <StImagePrimary
                    image={sponsor.image}
                    sizeMobile="30vw"
                    sizeTablet="30vw"
                    sizeDesktop="25vw"
                  />
                </StPrimarySponsor>
              )
            })}
        </StPrimarySponsors>
      )}
      {sponsors && sponsors[`secondary_sponsors_${selection}`] && (
        <StSecondarySponsors>
          {sponsors &&
            sponsors[`secondary_sponsors_${selection}`].map((sponsor) => {
              const sponsorLinkHref = getHrefFromStoryblokLink(sponsor.link)

              return sponsorLinkHref === '#' ? (
                <StSecondarySponsor key={sponsor.image.filename}>
                  <StImageSecondary
                    image={sponsor.image}
                    sizeMobile="30vw"
                    sizeTablet="30vw"
                    sizeDesktop="10vw"
                  />
                </StSecondarySponsor>
              ) : (
                <StSecondarySponsorClickable
                  key={sponsor.image.filename}
                  href={sponsorLinkHref}
                  title={sponsor.link.title}
                  target={sponsor.link.target}
                >
                  <StImageSecondary
                    image={sponsor.image}
                    sizeMobile="30vw"
                    sizeTablet="30vw"
                    sizeDesktop="10vw"
                  />
                </StSecondarySponsorClickable>
              )
            })}
        </StSecondarySponsors>
      )}
    </>
  )
}
