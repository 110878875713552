export const getCrowdManagerInfo = (rootId: string) => {
  const scriptElement = document.querySelector(
    `#${rootId} #sc-voting ~ script, #${rootId} .sc-camp ~ script`
  )
  const iframeElement = document.querySelector(
    `#${rootId} #sc-voting iframe, #${rootId} .sc-camp iframe`
  )

  if (!scriptElement || iframeElement) {
    return
  }

  const stringifiedMatch =
    scriptElement.innerHTML.match(/initialize\((.*)\);/)?.[1]

  if (stringifiedMatch) {
    //remove "cmToken":cmToken from string
    const stringifiedMatchWithoutCmToken = stringifiedMatch?.replace(
      /,\s*"cmToken":\s*cmToken/g,
      ''
    )

    return JSON.parse(stringifiedMatchWithoutCmToken) as Record<string, unknown>
  }
}
