import styled, { css } from 'styled-components'

import { CercleCircle } from '../../../shared/components/cercle-circle'
import { PageContainer } from '../../../shared/components/page-container'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodySmallBold,
  Heading1,
  Tag,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  height: 100%;
  display: flex;
  flex-direction: column;
`
export const StTitleContainer = styled(PageContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-align: center;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space6}`};
`

export const StTitleContent = styled.div<{ $mainTitle?: boolean }>`
  display: flex;
  justify-content: center;

  flex-grow: ${({ $mainTitle }) => ($mainTitle ? 2 : 1)};
`

export const StTitle = styled(BodySmallBold)`
  margin-right: auto;
  color: ${({ theme }) => theme.Colors.Neutrals.Black};
  text-align: left;
`

export const StSponsorLink = styled.a`
  margin-left: auto;
`

export const StSponsorImage = styled(StoryblokServiceImage)`
  max-width: 150px;
  max-height: 36px;
  object-fit: contain;
  margin-left: auto;
`

export const StFullCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  height: 100%;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StCercleCirlce = styled(CercleCircle)`
  position: absolute;
  bottom: 200px;
  left: 50%;
  transform-origin: center;
  transform: translateX(-50%) scale(-2, 2);
  fill: ${({ theme }) => theme.Colors.Neutrals.Lightest};
`

export const StResultContainer = styled.div`
  display: flex;
  z-index: 2;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space12} 0;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StMatchday = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;
  text-align: center;
`

export const StLiveContainer = styled.div<{
  $grey?: boolean
  $empty?: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: ${({ theme, $grey }) =>
    $grey
      ? theme.Colors.Components.Button.Secondary.Fill
      : theme.Colors.Semantic.Error};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space2}`};
  transform: skew(-10deg);

  ${({ $empty }) =>
    $empty &&
    css`
      min-height: ${({ theme }) => theme.UI.SpacingPx.Space6};
      background-color: transparent;
    `}

  color: ${({ theme, $grey }) =>
    $grey ? theme.Colors.Neutrals.Black : theme.Colors.Neutrals.White};
`

export const StLiveBlink = styled.div`
  transform: skew(10deg);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate;

  @keyframes blinker {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`

export const StLiveTitle = styled(Tag)`
  transform: skew(10deg);
`

export const StLiveScore = styled(Heading1)`
  white-space: nowrap;
  color: ${({ theme }) => theme.Colors.Neutrals.Black};
`

export const StLiveScoreSup = styled.sup`
  font-size: 0.6em;
`

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StMatchLiveCardColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StGoalsContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space7};

    flex-grow: 1;
  }
`

export const StGoal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: ${({ theme }) => theme.Colors.Neutrals.Black};
`
