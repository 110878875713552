import { isObject, isStoryblokLink } from 'types'

import type { StoryblokLink } from 'types'

export type StoryblokTextLink = {
  _uid: string
  name: string
  link: StoryblokLink
}

export function isStoryblokTextLink(
  value: unknown
): value is StoryblokTextLink {
  if (isObject(value)) {
    // UID
    const { _uid } = value
    if (typeof _uid !== 'string') {
      return false
    }
    // Image
    const { name } = value
    if (typeof name !== 'string') {
      return false
    }
    // Link
    const { link } = value
    if (!isStoryblokLink(link)) {
      return false
    }
  } else {
    return false
  }
  return true
}
