import { isValid } from 'date-fns'

export const getValidStoryblokDate = (date: string | undefined | null) => {
  return !!date && date !== '' && date !== null
    ? new Date(date.split(' ')[0] + 'T00:00:00Z')
    : undefined
}

export const getValidStoryblokDateTime = (date: string | undefined | null) => {
  if (!!date && date !== '' && date !== null) {
    const dateParts = date.split(' ')
    console.log('DATE', dateParts, `${dateParts[0]}T${dateParts[1]}:00Z`)
    return new Date(`${dateParts[0]}T${dateParts[1]}:00Z`)
  }
}

export const getStoryblokDateString = (date: Date | undefined) => {
  return date && isValid(date) ? date.toISOString().split('T')[0] + 'Z' : ''
}
