import styled from 'styled-components'

export const StCardsBlock = styled.div`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

export const StLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
`
