import { useStoryblokStory } from './useStoryblokStory'

import type { SbAppSettingsData } from '../types/sbAppSettingsData'

export const useStoryblokCercleGeneralSettings = () => {
  const { story, isLoading } = useStoryblokStory<SbAppSettingsData>(
    'configuration/app-settings'
  )

  return { config: story, isLoading }
}
