import {
  StContainer,
  StLeagueLogo,
  StLeagueInfoContainer,
  StLeagueInfo,
  StLeagueName,
} from './MatchLeague.styled'

import type { StoryblokImage } from 'types'

type MatchLeagueProps = {
  className?: string
  name: string
  logo?: StoryblokImage
  info?: string
}

export const MatchLeague = ({
  className,
  name,
  logo,
  info,
}: MatchLeagueProps) => {
  return (
    <StContainer className={className}>
      {logo && <StLeagueLogo width={40} height={40} image={logo} />}
      <StLeagueInfoContainer>
        <StLeagueName>{name}</StLeagueName>
        {info && <StLeagueInfo>{info}</StLeagueInfo>}
      </StLeagueInfoContainer>
    </StContainer>
  )
}
