import { StLoaderGif } from './Loader.styled'

interface Props {
  className?: string
  size?: 'small' | 'default' | 'large'
}

export const Loader = ({ className, size = 'default' }: Props) => {
  return <StLoaderGif size={size} className={className} />
}
