import { createContext } from 'react'

import { Footer } from '../../../footer/components/footer'
import { Navigation } from '../../../storyblok/components/navigation'

import { StMobileHeaderSpacer } from './PageLayout.styled'

import type { StoryblokFooter } from '../../../storyblok/components/footer/Footer.types'
import type { StoryblokNavigation } from '../../../storyblok/components/navigation'
import type { StoryblokSponsors } from '../../../storyblok/components/sponsors/Sponsors.types'
import type { ReactNode } from 'react'

type PageLayoutProps = {
  navigation?: StoryblokNavigation
  footer?: StoryblokFooter
  sponsors?: StoryblokSponsors
  locale?: string
  locales?: string[]
  children?: ReactNode
}

export const SponsorsContext = createContext<StoryblokSponsors | undefined>(
  undefined
)

export function PageLayout({
  navigation,
  footer,
  sponsors,
  locale,
  locales,
  children,
}: PageLayoutProps) {
  return (
    <>
      <Navigation blok={navigation} />
      <SponsorsContext.Provider value={sponsors}>
        <main>{children}</main>
      </SponsorsContext.Provider>
      <Footer content={footer} locale={locale} locales={locales} />
      <StMobileHeaderSpacer />
    </>
  )
}
