import { ButtonLink } from '../../../shared/components/button'
import { timestampToLocaleDatestring } from '../../utils/timestampToLocaleDate'
import { timestampToShortLocaleDatestring } from '../../utils/timestampToShortLocaleDate'
import { isValidLink } from '../match-live-card'

import {
  StClubLogo,
  StContainer,
  StContentContainer,
  StTeamContainer,
  StTitle,
  StTitleContainer,
  StContent,
  StTeam,
  StInfo,
  StHomeLabel,
  StCalendarContainer,
  StCalendarMatch,
  StCalendarClubLogo,
  StCalendarClubName,
  StCalendarItem,
  StCalendarDate,
  StCalendarHome,
  StCalendarHomeLetter,
  StCalendarButtonContainer,
  StCalendarMatchContainer,
  StMobileCalendarClubName,
} from './MatchCalendarCard.styled'

import type { Match } from '../../types/Match.types'
import type { Hyperlink } from 'types'

interface MatchCalendarCardProps {
  className?: string
  title?: string
  matches?: Match[]
  allMatchesLink: Hyperlink
  allMatchesText?: string
  locale?: string
}

export const MatchCalendarCard = ({
  className,
  title,
  matches,
  allMatchesLink,
  allMatchesText,
  locale,
}: MatchCalendarCardProps) => {
  const firstMatch = matches && matches?.length > 0 ? matches[0] : undefined

  return (
    <StContainer className={className}>
      <StTitleContainer>
        <StTitle>{title}</StTitle>
      </StTitleContainer>
      <StContentContainer>
        <StTeamContainer>
          {firstMatch?.teamLogo && (
            <StClubLogo
              image={firstMatch?.teamLogo}
              width={40}
              height={40}
              sizeMobile={'40px'}
              sizeTablet={'40px'}
              sizeDesktop={'40px'}
            />
          )}
          <StContent>
            <StTeam>{firstMatch?.teamName}</StTeam>
            {firstMatch?.matchDate && (
              <StInfo>
                {timestampToLocaleDatestring(
                  firstMatch.matchDate.replace(' ', 'T'),
                  locale ?? 'nl'
                )}
                , {firstMatch.matchTime}
                <StHomeLabel>{firstMatch?.playingHome ? 'H' : 'A'}</StHomeLabel>
              </StInfo>
            )}
          </StContent>
        </StTeamContainer>
        <div>
          {firstMatch?.ticketLink?.href &&
            isValidLink(firstMatch?.ticketLink) && (
              <ButtonLink
                variant="primary"
                prefixIcon="ticket"
                href={firstMatch.ticketLink.href}
              />
            )}
        </div>
      </StContentContainer>
      <StCalendarContainer>
        <StCalendarMatchContainer>
          {matches &&
            matches.length > 0 &&
            matches?.map((match) => {
              return (
                <StCalendarMatch key={match.id}>
                  <StCalendarItem>
                    {match.teamLogo && (
                      <StCalendarClubLogo
                        image={match.teamLogo}
                        width={32}
                        height={32}
                        sizeMobile={'32px'}
                        sizeTablet={'32px'}
                        sizeDesktop={'32px'}
                      />
                    )}
                    <StCalendarClubName>{match.teamName}</StCalendarClubName>
                    <StMobileCalendarClubName>
                      {match.teamNameShort}
                    </StMobileCalendarClubName>
                  </StCalendarItem>
                  <StCalendarItem>
                    <StCalendarDate>
                      {timestampToShortLocaleDatestring(
                        match.matchDate?.replace(' ', 'T') ??
                          Date.now().toString(),
                        locale ?? 'nl'
                      )}
                    </StCalendarDate>
                    <StCalendarHome>
                      <StCalendarHomeLetter>
                        {match.playingHome ? 'H' : 'A'}
                      </StCalendarHomeLetter>
                    </StCalendarHome>
                  </StCalendarItem>
                </StCalendarMatch>
              )
            })}
        </StCalendarMatchContainer>
        {allMatchesLink?.href && isValidLink(allMatchesLink) && (
          <StCalendarButtonContainer>
            <ButtonLink
              variant={'primary'}
              href={allMatchesLink.href}
              size="small"
            >
              {allMatchesText}
            </ButtonLink>
          </StCalendarButtonContainer>
        )}
      </StCalendarContainer>
    </StContainer>
  )
}
