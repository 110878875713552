import { useMemo } from 'react'
import { isTeamCercle } from 'utils'

import { Loader } from '../../../shared/components/loader'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { StandingsLegend } from '../standings-legend'

import {
  StCell,
  StHeaderCell,
  StHeaderRow,
  StRankNumber,
  StRow,
  StTable,
  StTeamName,
  StTeamImage,
  StTeamIdentityContainer,
  StTeamRankContainer,
  StContentText,
  StHeaderTextHighlightLarge,
  StHeaderText,
  StContentHighlightText,
  StUpIcon,
  StStayIcon,
  StDownIcon,
  StLastFiveGamesContainer,
  StPerformanceIndicator,
  StTeamNameShort,
  StRankMoveContainer,
  StMobileCell,
  StMobileHeaderCell,
  StLoadingContainer,
  StFormHeaderText,
  StHeaderTextHighlight,
} from './StandingsTable.styled'

import type { StandingRow } from './types/row'
import type { MatchResult } from 'types'

export interface Props {
  className?: string
  rows: StandingRow[]
  isLoading?: boolean
}

export const StandingsTable = ({
  className,
  rows,
  isLoading = false,
}: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const hasRankingMove = useMemo(() => {
    return rows.some((row) => typeof row.rankMove === 'number')
  }, [rows])

  const hasLastSixResult = useMemo(() => {
    return rows.some((row) => typeof row.lastSixResults === 'string')
  }, [rows])

  return (
    <>
      {isLoading && (
        <StLoadingContainer>
          <Loader size="default" />
        </StLoadingContainer>
      )}
      {!isLoading && (
        <>
          <StTable className={className}>
            <thead>
              <StHeaderRow>
                <StMobileHeaderCell>
                  <StHeaderTextHighlightLarge>#</StHeaderTextHighlightLarge>
                </StMobileHeaderCell>
                <StMobileHeaderCell>
                  <StHeaderTextHighlightLarge>
                    {t('team')}
                  </StHeaderTextHighlightLarge>
                </StMobileHeaderCell>
                <StMobileHeaderCell>
                  <StHeaderText>
                    {t('standings_matches_played_short')}
                  </StHeaderText>
                </StMobileHeaderCell>
                <StMobileHeaderCell>
                  <StHeaderText>
                    {t('standings_matches_won_short')}
                  </StHeaderText>
                </StMobileHeaderCell>
                <StHeaderCell>
                  <StHeaderText>
                    {t('standings_matches_lost_short')}
                  </StHeaderText>
                </StHeaderCell>
                <StHeaderCell>
                  <StHeaderText>
                    {t('standings_matches_draw_short')}
                  </StHeaderText>
                </StHeaderCell>
                <StHeaderCell>
                  <StHeaderText>{t('standings_goals_for_short')}</StHeaderText>
                </StHeaderCell>
                <StHeaderCell>
                  <StHeaderText>
                    {t('standings_goals_against_short')}
                  </StHeaderText>
                </StHeaderCell>
                <StHeaderCell>
                  <StHeaderText>
                    {t('standings_goals_difference_short')}
                  </StHeaderText>
                </StHeaderCell>

                <StMobileHeaderCell>
                  <StHeaderTextHighlight>
                    {t('standings_points_short')}
                  </StHeaderTextHighlight>
                </StMobileHeaderCell>
                {hasLastSixResult && (
                  <StHeaderCell>
                    <StFormHeaderText>
                      {t('standings_form_short')}
                    </StFormHeaderText>
                  </StHeaderCell>
                )}
              </StHeaderRow>
            </thead>
            <tbody>
              {rows
                .sort((a, b) => a.rank - b.rank)
                .map((row) => {
                  const isCercle = isTeamCercle(
                    row.teamName || '',
                    row.teamNameShort
                  )

                  return (
                    <StRow key={row.teamId} isActive={isCercle}>
                      <StMobileCell>
                        <StTeamRankContainer>
                          <StRankNumber isActive={isCercle}>
                            {row.rank}
                          </StRankNumber>
                          {hasRankingMove && (
                            <StRankMoveContainer>
                              {typeof row.rankMove === 'number' &&
                                row.rankMove > 0 && <StUpIcon />}
                              {typeof row.rankMove === 'number' &&
                                row.rankMove < 0 && <StDownIcon />}
                              {row.rankMove === undefined ||
                                (row.rankMove === 0 && <StStayIcon />)}
                            </StRankMoveContainer>
                          )}
                        </StTeamRankContainer>
                      </StMobileCell>

                      <StMobileCell>
                        <StTeamIdentityContainer>
                          {row.teamLogo && (
                            <StTeamImage
                              image={row.teamLogo}
                              width={64}
                              height={64}
                            />
                          )}
                          <StTeamName isActive={isCercle}>
                            {row.teamName}
                          </StTeamName>
                          <StTeamNameShort isActive={isCercle}>
                            {row.teamNameShort}
                          </StTeamNameShort>
                        </StTeamIdentityContainer>
                      </StMobileCell>

                      <StMobileCell>
                        <StContentText isActive={isCercle}>
                          {row.matchesPlayed}
                        </StContentText>
                      </StMobileCell>
                      <StMobileCell>
                        <StContentText isActive={isCercle}>
                          {row.matchesWon}
                        </StContentText>
                      </StMobileCell>
                      <StCell>
                        <StContentText isActive={isCercle}>
                          {row.matchesLost}
                        </StContentText>
                      </StCell>
                      <StCell>
                        <StContentText isActive={isCercle}>
                          {row.matchesDrawn}
                        </StContentText>
                      </StCell>
                      <StCell>
                        <StContentText isActive={isCercle}>
                          {row.goalsFor}
                        </StContentText>
                      </StCell>
                      <StCell>
                        <StContentText isActive={isCercle}>
                          {row.goalsAgainst}
                        </StContentText>
                      </StCell>
                      <StCell>
                        <StContentText isActive={isCercle}>
                          {row.goalsDifference}
                        </StContentText>
                      </StCell>
                      <StMobileCell>
                        <StContentHighlightText isActive={isCercle}>
                          {row.points}
                        </StContentHighlightText>
                      </StMobileCell>
                      {hasLastSixResult && (
                        <StCell>
                          <StLastFiveGamesContainer>
                            {row.lastSixResults && (
                              <>
                                <StPerformanceIndicator
                                  performance={
                                    row.lastSixResults[4] as MatchResult
                                  }
                                />
                                <StPerformanceIndicator
                                  performance={
                                    row.lastSixResults[3] as MatchResult
                                  }
                                />
                                <StPerformanceIndicator
                                  performance={
                                    row.lastSixResults[2] as MatchResult
                                  }
                                />
                                <StPerformanceIndicator
                                  performance={
                                    row.lastSixResults[1] as MatchResult
                                  }
                                />
                                <StPerformanceIndicator
                                  performance={
                                    row.lastSixResults[0] as MatchResult
                                  }
                                />
                              </>
                            )}
                          </StLastFiveGamesContainer>
                        </StCell>
                      )}
                    </StRow>
                  )
                })}
            </tbody>
          </StTable>

          <StandingsLegend />
        </>
      )}
    </>
  )
}
