import {
  StCard,
  StCardContainer,
  StContainer,
  StPartiallyHiddenCard,
  StPhatLine,
  StStatCardNumber,
  StStatCardLabel,
} from './CardVisual.styled'

import type { ReactNode } from 'react'

interface Props {
  className?: string

  cardType: 'yellow' | 'red'
  amount?: number

  children: ReactNode
}

export const CardVisual = ({
  cardType,
  className,
  amount = 0,
  children,
}: Props) => {
  return (
    <StContainer className={className}>
      <StCardContainer>
        <StCard cardType={cardType}>
          <StStatCardNumber>{amount}</StStatCardNumber>
        </StCard>
        <StPhatLine />
        <StPartiallyHiddenCard cardType={cardType} />
      </StCardContainer>

      <StStatCardLabel>{children}</StStatCardLabel>
    </StContainer>
  )
}
