import { faArrowRight } from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { Button } from '../../../shared/components/button'
import { Input } from '../../../shared/components/input'
import { Heading3 } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StButtonContainer,
  StError,
  StForm,
  StInputInfo,
  StLabel,
} from './AccountAddCard.styled'

import type { NewCercleCardFormValues } from '../../types/newCercleCardFormValues'

interface AccountAddCardProps {
  onSubmit: (values: NewCercleCardFormValues) => void
  onBack: () => void
}

export const AccountAddCard = ({ onSubmit, onBack }: AccountAddCardProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const NewCardFormValidation = Yup.object().shape({
    number: Yup.string().length(14, t('account_card_add_number_error')),
  })

  return (
    <>
      <Heading3>{t('account_card_add_title')}</Heading3>
      <Formik<NewCercleCardFormValues>
        initialValues={{
          number: '',
          name: '',
        }}
        validationSchema={NewCardFormValidation}
        onSubmit={onSubmit}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          isSubmitting,
          errors,
          touched,
        }) => (
          <StForm onSubmit={handleSubmit}>
            <div>
              <StLabel>{t('account_card_add_number')}</StLabel>
              <Input
                name="number"
                value={values.number}
                onChange={handleChange}
                error={!!errors.number && touched.number}
              />
              {errors.number && touched.number ? (
                <StError>{errors.number}</StError>
              ) : (
                <StInputInfo>{t('account_card_add_nummer_info')}</StInputInfo>
              )}
            </div>
            <div>
              <StLabel>{t('account_card_add_name')}</StLabel>
              <Input name="name" value={values.name} onChange={handleChange} />
              <StInputInfo>{t('account_card_add_name_info')}</StInputInfo>
            </div>
            <StButtonContainer>
              <Button
                type="submit"
                disabled={!values.number || !values.name}
                loading={isSubmitting}
                gaEventLabel="press_confirm_add_my_cercle_card_modal"
                suffixIcon={faArrowRight}
              >
                {t('account_card_add_confirm')}
              </Button>
              <Button
                variant="tertiary"
                gaEventLabel="press_back_add_my_cercle_card_modal"
                onClick={onBack}
              >
                {t('account_card_add_back')}
              </Button>
            </StButtonContainer>
          </StForm>
        )}
      </Formik>
    </>
  )
}
