import { useRouter } from 'next/router'
import { useState } from 'react'
import { toast } from 'react-hot-toast'

import { useUser } from '../../../authentication/hooks/useUser'
import { Loader } from '../../../shared/components/loader'
import { useAxios } from '../../../shared/hooks/useAxios'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { useCards } from '../../hooks/useCards'
import { AccountAddBalance } from '../account-add-balance'
import { AccountAddCard } from '../account-add-card'
import { AccountCardSelection } from '../account-card-selection'

import { StCardsBlock, StLoadingContainer } from './AccountCardSettings.styled'

import type { NewCercleCardFormValues } from '../../types/newCercleCardFormValues'
import type { AxiosError } from 'axios'

export type CardSelectionSteps = 'select' | 'addCard' | 'addBalance'

export const AccountCardSettings = () => {
  const { user } = useUser()
  const axios = useAxios()
  const router = useRouter()
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { cards, loading, fetchCards } = useCards()

  const [selectedCard, setSelectedCard] = useState(0)
  const [step, setStep] = useState<CardSelectionSteps>('select')

  // Handlers
  const handleCardSelected = () => {
    setStep(selectedCard === cards.length ? 'addCard' : 'addBalance')
  }

  const handleAddNewCard = async ({
    number,
    name,
  }: NewCercleCardFormValues) => {
    try {
      if (!axios) throw new Error('axios not set')
      await axios.post(
        `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}/api/users/${
          user?.id
        }/cards`,
        {
          name: name,
          number: number,
        }
      )

      toast.success(t('account_card_add_confirmed'))
      await fetchCards()
      setStep('select')
    } catch (error) {
      const exception = error as AxiosError
      if (exception.response?.status === 404) {
        toast.error(t('account_card_add_card_exists_error'))
      } else if (exception.response?.status === 409) {
        toast.error(t('account_card_add_card_already_added_error'))
      } else {
        toast.error(t('account_card_add_error'))
      }
    }
  }

  const handleAddBalance = async (amount: number) => {
    try {
      if (!axios) throw new Error('axios not set')
      const { data: redirectUrl } = await axios.post(
        `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}/api/users/${
          user?.id
        }/cards/add-balance`,
        {
          amount: amount,
          number: cards[selectedCard].number,
        }
      )

      router.push(redirectUrl)
    } catch {
      toast.error(t('account_card_add_error'))
    }
  }

  // Render
  const renderCardsSection = () => {
    switch (step) {
      case 'select': {
        return (
          <AccountCardSelection
            cards={cards}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            handleCardSelected={handleCardSelected}
          />
        )
      }
      case 'addCard': {
        return (
          <AccountAddCard
            onSubmit={handleAddNewCard}
            onBack={() => setStep('select')}
          />
        )
      }
      case 'addBalance': {
        return (
          <AccountAddBalance
            onSubmit={handleAddBalance}
            onBack={() => setStep('select')}
          />
        )
      }
    }
  }

  return (
    <>
      {/* Removed due to spacing issues on smaller desktop screens */}
      {/* <AccountCardHeader
        backgroundImage={backgroundImage}
        step={step}
        selectedCard={cards[selectedCard]}
      /> */}
      {loading ? (
        <StLoadingContainer>
          <Loader />
        </StLoadingContainer>
      ) : (
        <StCardsBlock>{renderCardsSection()}</StCardsBlock>
      )}
    </>
  )
}
