import { isObject, isStoryblokImage, isStoryblokLink } from 'types'

import { isStoryblokTextLink } from '../text-link/TextLink.types'

import type { SbButtonProps } from '../button/Button.types'
import type { StoryblokImageLink } from '../image-link/ImageLink.types'
import type { StoryblokTextLinksWithTitle } from '../text-links-with-title/TextLinksWithTitle.types'
import type { StoryblokImage, StoryblokLink } from 'types'

export interface StoryblokNavigation {
  home_link: StoryblokLink
  home_logo: StoryblokImage
  primary_actions: SbButtonProps[]
  header_teams_links: StoryblokTextLinksWithTitle[]
  header_links: (StoryblokTextLinksWithTitle & { show: boolean })[]
  social_links: StoryblokImageLink[]
}

export function isStoryblokNavigation(
  value: unknown
): value is StoryblokNavigation {
  if (isObject(value)) {
    // Home link
    const { home_link } = value
    if (!isStoryblokLink(home_link)) {
      return false
    }
    // Home logo
    const { home_logo } = value
    if (!isStoryblokImage(home_logo)) {
      return false
    }
    // Teams links
    const { header_teams_links } = value
    if (
      Array.isArray(header_teams_links) &&
      header_teams_links.length > 0 &&
      !header_teams_links.every((link) => !isStoryblokTextLink(link))
    ) {
      return false
    }
    // Otherlinks
    const { header_links } = value
    if (
      Array.isArray(header_links) &&
      header_links.length > 0 &&
      !header_links.every((link) => !isStoryblokTextLink(link)) &&
      !header_links.every((link) => typeof link.show === 'boolean')
    ) {
      return false
    }
  } else {
    return false
  }
  return true
}
