import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

import type { SbBlokData, ISbStoryData } from '@storyblok/react'

type PageProps = {
  blok: {
    body: SbBlokData[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const Page = ({ blok, locale, relations }: PageProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          locale={locale}
          relations={relations}
        />
      ))}
    </div>
  )
}
