import styled from 'styled-components'

export const StContainer = styled.div``

export const StNavigationMobile = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StNavigationDesktop = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`
