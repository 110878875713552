import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { StContainer } from './ButtonContainer.styled'

type Props = {
  blok: {
    alignment: 'flex-start' | 'center' | 'flex-end'
    buttons: {
      _uid: number
    }[]
  }
}

export const ButtonContainer = ({ blok }: Props) => {
  return (
    <StContainer {...storyblokEditable(blok)} alignment={blok.alignment}>
      {blok.buttons.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </StContainer>
  )
}
