import { useMemo } from 'react'

import { BulletPointHexagon } from '../../../mycercle/components/account-card-header/BulletPointHexagon'

import {
  StEventIcon,
  StMinuteLabel,
  StTimeLineEventContainer,
} from './MatchTimeLine.styled'
import { TimeLineEventTooltip } from './TimeLineEventTooltip'

import type { MatchEvent } from './MatchTimeLine'

interface Props {
  minute: number
  showMinute?: boolean
  events: MatchEvent[]
  half: 'first' | 'second' | 'extraTimeFirst' | 'extraTimeSecond'
}

export const TimeLineEvent = ({
  minute,
  events,
  half,
  showMinute = true,
}: Props) => {
  const position = useMemo(() => {
    if (half === 'first') {
      return (minute / 45) * 100
    }

    if (half === 'second') {
      return ((minute - 45) / 45) * 100
    }

    if (half === 'extraTimeFirst') {
      return ((minute - 90) / 15) * 100
    }

    return ((minute - 105) / 15) * 100
  }, [minute, half])

  const isBothTeams =
    events.some((event) => event.team === 'home') &&
    events.some((event) => event.team === 'away')

  const isAwayTeam =
    events.some((event) => event.team === 'away') && !isBothTeams

  return (
    <StTimeLineEventContainer $position={position}>
      {showMinute && (
        <StMinuteLabel>
          {isBothTeams || !isAwayTeam ? `${minute}’` : ''}
        </StMinuteLabel>
      )}
      <TimeLineEventTooltip
        minute={minute}
        events={events}
        isBothTeams={isBothTeams}
        isAwayTeam={isAwayTeam}
        showTeam={'home'}
      />
      {isBothTeams ? (
        <BulletPointHexagon />
      ) : (
        <StEventIcon $flip={!isAwayTeam} />
      )}
      <TimeLineEventTooltip
        minute={minute}
        events={events}
        isBothTeams={isBothTeams}
        isAwayTeam={isAwayTeam}
        showTeam={'away'}
      />
      {showMinute && (
        <StMinuteLabel>
          {isBothTeams || isAwayTeam ? `${minute}’` : ''}
        </StMinuteLabel>
      )}
    </StTimeLineEventContainer>
  )
}
