import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const StContainer = styled.div<{
  backgroundColor: string | undefined
  textColor: string | undefined
  backgroundImage?: string
}>`
  color: ${({ theme, textColor }) => textColor ?? theme.Colors.Neutrals.Black};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor ?? theme.Colors.Neutrals.Lightest};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space10} 0;

  background-position: 12em bottom;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
  }
`

export const StHeaderContainer = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    max-width: 50%;
  }
`

export const StTitleIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.Colors.Primary.Base};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  font-size: 16px;
`
