import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { format } from 'date-fns'
import { useTheme } from 'styled-components'

import { AccountDataBlock } from '../account-data-block'
import { AccountDataCard } from '../account-data-card'

import { StDataContainer } from './AccountData.styled'

interface AccountDataProps {
  title: string
  firstnameLabel: string
  firstnameValue: string | null | undefined
  lastnameLabel: string
  lastnameValue: string | null | undefined
  emailLabel: string
  emailValue: string | null | undefined
  birthdateLabel: string
  birthdateValue: string | null | undefined
}

export const AccountData = ({
  title,
  firstnameLabel,
  firstnameValue,
  lastnameLabel,
  lastnameValue,
  emailLabel,
  emailValue,
  birthdateLabel,
  birthdateValue,
}: AccountDataProps) => {
  const theme = useTheme()

  return (
    <AccountDataCard
      title={title}
      icon={faUser}
      textColor={theme.Colors.Neutrals.White}
      backrgoundColor={theme.Colors.Neutrals.Darker}
    >
      <StDataContainer>
        {firstnameValue ? (
          <AccountDataBlock label={firstnameLabel} value={firstnameValue} />
        ) : null}
        {lastnameValue ? (
          <AccountDataBlock label={lastnameLabel} value={lastnameValue} />
        ) : null}
        {emailValue ? (
          <AccountDataBlock label={emailLabel} value={emailValue} />
        ) : null}
        {birthdateValue ? (
          <AccountDataBlock
            label={birthdateLabel}
            value={format(new Date(birthdateValue), 'dd/MM/yyyy')}
          />
        ) : null}
      </StDataContainer>
    </AccountDataCard>
  )
}
