import {
  StLabelContent,
  StLabelText,
  StRadioButton,
  StRadioButtonLabel,
} from './RadioButton.styled'

import type { InputHTMLAttributes, ReactNode } from 'react'

type RadioButtonProps = {
  label: string
  isSelected: boolean
  children?: ReactNode
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'checked'>

export const RadioButton = ({
  label,
  isSelected,
  children,
  ...props
}: RadioButtonProps) => {
  return (
    <StRadioButtonLabel>
      <StRadioButton type="radio" checked={isSelected} {...props} />
      <StLabelContent>
        <StLabelText selected={isSelected}>{label}</StLabelText>
        {children}
      </StLabelContent>
    </StRadioButtonLabel>
  )
}
