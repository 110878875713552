import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import {
  BodyLargeBold,
  Heading1,
  Heading3,
  Tag,
} from '../../../shared/components/typography'

export const StArticleContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.Article};

  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

export const StArticleTitle = styled(Heading1)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

export const StArticleContent = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;
  }

  &:not(:has(+ div)) {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space20};
  }
`

export const StArticleMetaContainer = styled.section`
  display: flex;

  margin: ${({ theme }) => theme.UI.SpacingPx.Space6} auto;

  > *:not(:first-child) {
    margin-left: -${({ theme }) => theme.UI.SpacingPx.Space1};
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space20};
  }
`

export const StArticleTagContainer = styled.div`
  margin: 0px ${({ theme }) => theme.UI.SpacingPx.Space1};
  transform: skew(-10deg);
`

export const StArticleTag = styled(Tag)`
  background-color: ${({ theme }) => theme.Colors.Primary.Base};
  text-transform: uppercase;
  font-style: italic;
  width: fit-content;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space1} ${theme.UI.SpacingPx.Space3}`};
`

export const StArticleDate = styled(StArticleTag)`
  color: ${({ theme }) => theme.Colors.Neutrals.Black};
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
`

export const StPremiumPreview = styled.div`
  min-height: ${({ theme }) => theme.UI.SpacingPx.Space23};
  max-height: ${({ theme }) => theme.UI.SpacingPx.Space46};
  overflow: hidden;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    max-height: ${({ theme }) => theme.UI.SpacingPx.Space36};
  }
`

export const StPremiumOverlay = styled.div`
  height: ${({ theme }) => theme.UI.SpacingPx.Space51};
  width: 100%;
  margin-top: -${({ theme }) => theme.UI.SpacingPx.Space43};
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 66.67%, #000 100%);
  position: relative;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    height: ${({ theme }) => theme.UI.SpacingPx.Space32};
    margin-top: -${({ theme }) => theme.UI.SpacingPx.Space32};
  }
`

export const StPremiumCardContainer = styled.div`
  max-width: calc(740px + ${({ theme }) => theme.UI.SpacingPx.Space8});
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin: -${({ theme }) => theme.UI.SpacingPx.Space9} auto ${({ theme }) =>
      theme.UI.SpacingPx.Space9} auto;
  position: relative;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space45};
  }
`

export const StPremiumCard = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

export const StPremiumCardTitle = styled(Heading3)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

export const StPremiumCardBody = styled(BodyLargeBold)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space3};
  font-weight: 400;
`

export const StPremiumCardBodyAccent = styled(StPremiumCardBody)`
  color: ${({ theme }) => theme.Colors.Primary.Base};
  display: inline;
  font-weight: 500;
`

export const StLoadingIcon = styled(FontAwesomeIcon)`
  width: 100%;
  font-size: ${({ theme }) => theme.UI.SpacingPx.Space6};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space6} 0;
`
