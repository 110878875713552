import styled from 'styled-components'

import { StoryblokServiceImage } from '../storyblok-service-image'

export const StPrimarySponsors = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  gap: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space4}`};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    padding: 0;
    justify-content: center;
    gap: ${({ theme }) =>
      `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space28}`};
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`
export const StPrimarySponsor = styled.div`
  width: calc(
    calc(100% - calc(${({ theme }) => theme.UI.SpacingPx.Space4}) * 2) / 3
  );
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    width: unset;
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

export const StPrimarySponsorClickable = styled.a`
  width: calc(
    calc(100% - calc(${({ theme }) => theme.UI.SpacingPx.Space4}) * 2) / 3
  );
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    width: unset;
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

export const StSecondarySponsors = styled.section`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  gap: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space4}`};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex-wrap: nowrap;
    padding: 0;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

export const StSecondarySponsor = styled.div`
  width: calc(
    calc(100% - calc(${({ theme }) => theme.UI.SpacingPx.Space4}) * 2) / 3
  );
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    width: unset;
    flex: 1;
    padding: 0;
  }
`

export const StSecondarySponsorClickable = styled.a`
  width: calc(
    calc(100% - calc(${({ theme }) => theme.UI.SpacingPx.Space4}) * 2) / 3
  );
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    width: unset;
    flex: 1;
    padding: 0;
  }
`

export const StImage = styled(StoryblokServiceImage)`
  margin: 0 auto;
  max-height: calc(
    55px - calc(${({ theme }) => theme.UI.SpacingPx.Space4} * 2)
  );
  object-fit: contain;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    max-height: 55px;
  }
`

export const StImagePrimary = styled(StImage)`
  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    max-height: calc(
      96px - calc(${({ theme }) => theme.UI.SpacingPx.Space4} * 2)
    );
  }
`

export const StImageSecondary = styled(StImage)`
  opacity: 0.5;
  filter: grayscale(1);
`
