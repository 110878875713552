import styled from 'styled-components'

import { Section } from '../../../shared/components/section'

export const StContainer = styled(Section)`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.Page};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`
