import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { Heading4 } from '../../../shared/components/typography'

export const StContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  z-index: 100;
`

export const StDropdownItem = styled.div`
  display: flex;
  align-items: center;
`

export const StDropdownTitle = styled(Heading4)``

export const StDropdownIcon = styled(FontAwesomeIcon)<{ $isActive: boolean }>`
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space4};
  transform: ${({ $isActive }) =>
    $isActive ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition: 0.2s ease;
`

export const StDropdownMenu = styled.div<{ $isActive: boolean }>`
  position: fixed;
  top: ${({ theme }) => theme.UI.SpacingPx.Space20};
  transform: ${({ $isActive }) =>
    $isActive ? 'translateY(0)' : 'translateY(-100%)'};
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  transition: transform 0.2s;
  left: 0;
  width: 100%;
  z-index: 99;

  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    top: ${({ theme }) => theme.UI.SpacingPx.Space28};
  }
`

export const StDropdownMenuBackground = styled.div<{ $isActive: boolean }>`
  visibility: ${({ $isActive }) => ($isActive ? 'visible' : 'hidden')};
  opacity: ${({ $isActive }) => ($isActive ? 0.5 : 0)};
  transition: opacity 0.2s;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.Colors.Neutrals.Black};
  z-index: 98;
`

export const StDropdownMenuContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
`

export const StDropdownColumn = styled.div<{ isFirst: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  border-left: ${({ theme, isFirst }) =>
    isFirst ? 'none' : `1px solid ${theme.Colors.Neutrals.Dark}`};
  padding-left: ${({ isFirst, theme }) =>
    isFirst ? 0 : theme.UI.SpacingPx.Space10};
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space10};
  width: 240px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StDropdownLine = styled.div`
  width: 1px;
  height: 100%;
`

export const StLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  &&:visited,
  &&:link,
  &&:active {
    text-decoration: none;
    color: ${({ theme }) => theme.Colors.Neutrals.White};
  }
`
