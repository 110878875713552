interface CercleCircleVariationProps {
  className?: string
  fill?: string
}

const CercleCircle1 = ({ className, fill }: CercleCircleVariationProps) => {
  return (
    <svg
      width="665"
      height="665"
      viewBox="0 0 665 665"
      fill={fill ?? '#60B22C'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1260_8520)">
        <path d="M3.2 332.4C3.2 513.9 150.9 661.6 332.4 661.6C513.9 661.6 661.6 513.9 661.6 332.4C661.6 150.9 513.9 3.2 332.4 3.2C150.9 3.2 3.2 150.8 3.2 332.4ZM664.7 332.4C664.7 515.7 515.6 664.8 332.3 664.8C149 664.8 0 515.6 0 332.4C0 149.1 149.1 0 332.4 0C515.7 0 664.7 149.1 664.7 332.4Z" />
        <path d="M32.6 332.4C32.6 497.7 167.1 632.1 332.3 632.1C497.6 632.1 632 497.6 632 332.4C632 167.1 497.5 32.7 332.3 32.7C167.1 32.6 32.6 167.1 32.6 332.4ZM637.2 332.4C637.2 500.5 500.4 637.3 332.3 637.3C164.2 637.3 27.5 500.5 27.5 332.4C27.5 164.3 164.3 27.5 332.4 27.5C500.5 27.5 637.2 164.3 637.2 332.4Z" />
        <path d="M62.1 332.4C62.1 481.4 183.3 602.7 332.4 602.7C481.4 602.7 602.7 481.5 602.7 332.4C602.7 183.4 481.5 62.1 332.4 62.1C183.3 62.1 62.1 183.3 62.1 332.4ZM609.8 332.4C609.8 485.3 485.4 609.8 332.4 609.8C179.4 609.7 55 485.3 55 332.4C55 179.4 179.4 55 332.4 55C485.3 55 609.8 179.4 609.8 332.4Z" />
        <path d="M91.6 332.4C91.6 465.2 199.6 573.2 332.4 573.2C465.2 573.2 573.2 465.2 573.2 332.4C573.2 199.6 465.2 91.6 332.4 91.6C199.6 91.6 91.6 199.6 91.6 332.4ZM582.3 332.4C582.3 470.2 470.2 582.3 332.4 582.3C194.6 582.3 82.5 470.2 82.5 332.4C82.5 194.6 194.6 82.5 332.4 82.5C470.2 82.5 582.3 194.6 582.3 332.4Z" />
        <path d="M121 332.4C121 448.9 215.8 543.7 332.3 543.7C448.8 543.7 543.6 448.9 543.6 332.4C543.6 215.9 448.8 121.1 332.3 121.1C215.8 121 121 215.8 121 332.4ZM554.8 332.4C554.8 455 455 554.8 332.4 554.8C209.7 554.8 110 455 110 332.4C110 209.7 209.7 110 332.4 110C455 110 554.8 209.7 554.8 332.4Z" />
        <path d="M150.5 332.4C150.5 432.7 232.1 514.3 332.3 514.3C432.6 514.3 514.2 432.7 514.2 332.4C514.2 232.1 432.6 150.6 332.3 150.6C232.1 150.5 150.5 232.1 150.5 332.4ZM527.3 332.4C527.3 439.9 439.9 527.3 332.4 527.3C224.9 527.3 137.5 439.9 137.5 332.4C137.5 224.9 224.9 137.5 332.4 137.5C439.8 137.5 527.3 224.9 527.3 332.4Z" />
        <path d="M180 332.4C180 416.4 248.4 484.8 332.4 484.8C416.4 484.8 484.8 416.4 484.8 332.4C484.8 248.4 416.4 180 332.4 180C248.3 180 180 248.3 180 332.4ZM499.8 332.4C499.8 424.7 424.7 499.8 332.4 499.8C240.1 499.8 165 424.7 165 332.4C165 240.1 240.1 165 332.4 165C424.7 165 499.8 240.1 499.8 332.4Z" />
        <path d="M209.5 332.4C209.5 400.2 264.6 455.3 332.4 455.3C400.2 455.3 455.3 400.2 455.3 332.4C455.3 264.6 400.2 209.5 332.4 209.5C264.6 209.5 209.5 264.6 209.5 332.4ZM472.3 332.4C472.3 409.5 409.5 472.3 332.4 472.3C255.3 472.3 192.5 409.5 192.5 332.4C192.5 255.3 255.3 192.5 332.4 192.5C409.5 192.4 472.3 255.2 472.3 332.4Z" />
        <path d="M238.9 332.4C238.9 383.9 280.8 425.8 332.3 425.8C383.8 425.8 425.7 383.9 425.7 332.4C425.7 280.9 383.8 239 332.3 239C280.8 239 238.9 280.8 238.9 332.4ZM444.8 332.4C444.8 394.4 394.4 444.8 332.4 444.8C270.4 444.8 220 394.4 220 332.4C220 270.4 270.4 220 332.4 220C394.4 220 444.8 270.4 444.8 332.4Z" />
      </g>
      <defs>
        <clipPath id="clip0_1260_8520">
          <rect width="664.7" height="664.7" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const CercleCircle2 = ({ className, fill }: CercleCircleVariationProps) => {
  return (
    <svg
      width="355"
      height="664"
      viewBox="0 0 355 664"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill ?? '#60B22C'}
    >
      <g clipPath="url(#clip0_1260_8531)">
        <path d="M1.3 489.5C-1.3 524.6 -3.33786e-06 555.2 4.7 580.6C9.6 607.1 18.3 628.1 30.4 642.5C43.1 657.6 59.6 665.2 79.4 663.9C100.2 662.5 124.5 651.2 151.4 628.2C179.6 604.1 207.4 569.8 233.3 528.5C259.8 486.2 283.7 437.4 303.2 386C322.7 334.6 337.4 281.8 346 231.6C354.4 182.6 356.6 137.3 352 99.1002C347.6 62.6002 337.3 36.5002 323.1 20.3002C309.5 4.90018 292.4 -1.49983 273.2 0.300175C254.9 2.00017 234.7 11.1002 213.7 27.1002C193.6 42.3002 172.6 63.9002 151.4 91.5002C131 118.2 112 148.5 95 180.5C78.3 212 63.2 245.7 50.3 280C37.4 314.3 26.4 349.7 18.1 384.5C9.7 420 3.8 455.6 1.3 489.5ZM349.8 101.5C354.3 139.3 352 184.2 343.7 232.6C335.2 282.2 320.7 334.3 301.4 385.1C282.1 435.9 258.6 484.1 232.4 525.9C206.8 566.8 179.3 600.7 151.5 624.6C124.9 647.5 100.8 658.8 80.2 660.2C60.6 661.6 44.3 654.1 31.6 639.2C19.6 625.1 10.9 604.3 6 578.1C1.3 553 -3.33786e-06 522.7 2.6 487.9C5 454.3 10.9 419 19.2 383.9C27.4 349.3 38.3 314.2 51.1 280.2C63.9 246.2 78.9 212.7 95.5 181.5C112.4 149.7 131.2 119.7 151.4 93.3002C172.4 65.9002 193.2 44.6002 213.1 29.6002C233.9 14.0002 253.9 5.00018 272 3.40018C291 1.70018 307.9 8.10017 321.3 23.4002C335.4 39.5002 345.5 65.3002 349.8 101.5Z" />
        <path d="M12.3 477.3C10.1 509.6 11.5 537.6 16.1 560.7C20.9 584.7 29.1 603.5 40.5 616.1C52.4 629.3 67.7 635.6 85.9 633.7C105 631.7 127.1 620.7 151.5 599.2C176.9 576.8 202 545.5 225.2 508C249 469.7 270.3 425.8 287.9 379.6C305.4 333.4 318.8 285.9 326.7 240.8C334.4 196.6 336.8 155.4 333 120.5C329.4 87.0004 320.5 62.7004 307.9 47.4004C295.9 32.7004 280.5 26.2004 263.2 27.2004C246.7 28.2004 228.2 36.0004 209 50.2004C190.5 63.8004 171.1 83.4004 151.6 108.7C132.7 133.2 115 161.1 99.2 190.8C83.6 220 69.6 251.4 57.5 283.2C45.5 315.1 35.3 348 27.7 380.4C19.8 413.1 14.4 446 12.3 477.3ZM329.4 124.3C333 158.6 330.6 199 323 242.4C315.2 286.7 302.2 333.1 285 378.4C267.8 423.6 246.9 466.7 223.6 504.2C200.8 541 176.3 571.7 151.4 593.8C127.5 615 105.7 625.9 87 628C69.1 630 54 623.9 42.3 611.1C31.1 598.9 22.9 580.5 18.2 556.9C13.7 534.3 12.2 506.8 14.4 475C16.5 444.2 21.7 411.8 29.4 379.4C36.9 347.5 46.9 315 58.8 283.6C70.6 252.2 84.5 221.3 99.9 192.5C115.5 163.3 132.9 135.8 151.5 111.7C170.8 86.8004 189.9 67.7004 208 54.3004C226.9 40.4004 245 32.9004 261.2 32.0004C278.2 31.1004 293.2 37.7004 305 52.2004C317.2 67.4004 325.9 91.3004 329.4 124.3Z" />
        <path d="M23.5 464.8C21.6 494.3 23.1 519.7 27.5 540.5C32.1 562.1 39.8 578.7 50.4 589.6C61.4 601 75.5 606.1 92.1 603.7C109.4 601.2 129.5 590.7 151.4 570.8C174.2 550.2 196.6 521.8 217.3 488.1C238.4 453.7 257.4 414.5 273.1 373.3C288.7 332.1 300.7 289.8 307.9 249.4C315 209.8 317.4 172.7 314.4 141.1C311.5 110.6 303.8 88.2 292.8 73.8C282.2 60 268.6 53.5 253.1 53.8C238.2 54.1 221.6 60.7 204.1 73.2C187.3 85.2 169.5 102.8 151.6 125.8C134.2 148.1 117.9 173.7 103.3 200.9C88.9 227.8 75.9 256.7 64.8 286.1C53.7 315.5 44.4 345.9 37.4 375.7C30.1 406 25.3 436.2 23.5 464.8ZM309.6 146.3C312.5 177.1 310.1 213.1 303.1 251.6C296.1 290.8 284.5 331.8 269.3 371.8C254.2 411.7 235.8 449.8 215.3 483.2C195.2 515.9 173.5 543.6 151.4 563.7C130.1 583.1 110.6 593.5 93.7 596.1C77.5 598.5 63.8 593.7 53 582.8C42.7 572.3 35 556.2 30.5 535.2C26.1 515 24.7 490.3 26.4 461.5C28.1 433.5 32.8 404 39.7 374.4C46.5 345.2 55.7 315.5 66.5 286.8C77.3 258 90.1 229.8 104.1 203.5C118.4 176.9 134.3 151.9 151.3 130.2C168.8 107.8 186.2 90.8 202.6 79.1C219.6 67 235.8 60.7 250.3 60.6C265.4 60.4 278.6 66.9 288.8 80.5C299.4 94.7 306.8 116.6 309.6 146.3Z" />
        <path d="M34.9 452.1C33.4 478.7 34.9 501.6 39.1 520.1C43.4 539.3 50.7 553.9 60.4 563.2C70.5 572.9 83.3 576.9 98.3 574.2C113.9 571.4 131.8 561.5 151.4 543.3C171.6 524.5 191.4 499 209.7 469C228.3 438.4 245.1 403.7 258.8 367.3C272.6 330.9 283.2 293.5 289.8 257.7C296.2 222.5 298.6 189.5 296.2 161.1C293.9 133.6 287.4 113.2 277.8 99.8002C268.6 86.9002 256.6 80.5002 242.9 80.3002C229.7 80.1002 214.8 85.6002 199 96.5002C183.8 107 167.7 122.7 151.4 143.3C135.5 163.4 120.7 186.6 107.3 211.4C94.1 235.9 82.2 262.2 72.1 289.1C62 316 53.5 343.8 47.1 371C40.7 398.6 36.4 426.1 34.9 452.1ZM290.3 167.7C292.5 195.1 290.2 226.7 284 260.5C277.7 294.8 267.5 330.6 254.3 365.4C241.1 400.2 225.1 433.5 207.3 462.9C189.8 491.7 170.8 516.3 151.5 534.4C132.7 552 115.5 561.7 100.4 564.5C85.9 567.2 73.5 563.6 63.7 554.4C54.3 545.6 47.2 531.6 43 513.2C38.9 495.4 37.4 473.4 38.8 447.7C40.2 422.6 44.3 396.1 50.5 369.4C56.6 343 64.8 316.2 74.7 290.1C84.5 264.1 96 238.5 108.8 214.9C121.7 190.9 136.1 168.5 151.5 149.2C167.3 129.3 182.8 114.3 197.5 104.3C212.7 93.9002 227 88.8002 239.7 89.2002C252.9 89.6002 264.3 95.9002 273.1 108.5C282 121.5 288.2 141.2 290.3 167.7Z" />
        <path d="M46.6 439.1C45.4 462.9 46.9 483.1 50.8 499.4C54.9 516.2 61.5 528.9 70.3 536.8C79.4 545 91 548 104.4 545.2C118.3 542.3 134.1 533 151.4 516.6C169.1 499.7 186.4 477.1 202.4 450.7C218.6 423.9 233.2 393.5 245.2 361.7C257.2 329.9 266.6 297.3 272.4 266C278.1 235.2 280.4 206.1 278.6 180.9C276.8 156.4 271.3 138.1 263.2 125.8C255.3 114 244.9 107.8 232.9 107.1C221.3 106.5 208.1 110.9 194.1 120.3C180.5 129.4 166.2 143.1 151.5 161.4C137.2 179.3 123.8 200 111.7 222.3C99.8 244.3 89 268.1 79.9 292.4C70.7 316.7 63.1 341.7 57.4 366.3C51.6 391.1 47.8 415.8 46.6 439.1ZM271.6 188.6C273.2 212.5 271 240 265.6 269.1C260.1 298.6 251.2 329.4 239.9 359.4C228.6 389.3 214.8 418 199.6 443.4C184.6 468.4 168.2 489.8 151.5 505.9C135.2 521.5 120.2 530.5 107 533.5C94.2 536.4 83.3 533.7 74.5 526.1C66 518.8 59.6 506.9 55.7 491C51.9 475.6 50.4 456.4 51.5 433.7C52.6 411.6 56.2 388 61.6 364.3C67 340.8 74.3 316.9 83.1 293.7C91.9 270.5 102.1 247.8 113.5 226.7C125 205.4 137.8 185.6 151.5 168.7C165.5 151.3 179.2 138.4 192.1 129.8C205.4 121 217.8 117 228.8 117.8C240.2 118.6 249.9 124.6 257.3 136C264.9 147.8 270 165.3 271.6 188.6Z" />
        <path d="M58.5 425.8C57.5 446.7 59 464.4 62.7 478.5C66.4 493 72.4 503.8 80.4 510.3C88.6 517 98.7 519.3 110.5 516.4C122.6 513.4 136.5 505 151.4 490.4C166.7 475.5 181.6 455.8 195.3 432.9C209.2 409.7 221.7 383.5 232 356.2C242.4 328.9 250.4 300.8 255.5 273.8C260.6 247.2 262.7 222 261.3 200C260 178.5 255.4 162.3 248.6 151.3C241.9 140.6 233 134.8 222.7 133.8C212.6 132.8 201.2 136.4 189 144.3C177.1 152 164.4 163.9 151.4 179.8C138.7 195.4 126.8 213.8 116.1 233.5C105.5 253 95.9 274.2 87.7 295.7C79.6 317.3 72.8 339.5 67.8 361.4C62.8 383.5 59.5 405.3 58.5 425.8ZM253.4 208.8C254.6 229.3 252.6 252.7 247.8 277.4C243 302.4 235.5 328.4 226 353.6C216.4 378.9 204.9 403.1 192.1 424.6C179.4 445.9 165.7 464.2 151.5 478.2C137.6 491.8 124.8 499.8 113.5 502.8C102.5 505.7 92.9 503.8 85.3 497.7C77.8 491.8 72.2 481.8 68.6 468.4C65.1 455.3 63.7 438.8 64.5 419.3C65.3 400.1 68.4 379.7 73.1 359C77.8 338.5 84.1 317.6 91.8 297.3C99.5 277 108.5 257.2 118.5 238.9C128.6 220.4 139.8 203.3 151.7 188.7C163.9 173.8 175.7 162.8 186.8 155.8C198.2 148.6 208.9 145.4 218.2 146.5C227.8 147.6 236 153.2 242.1 163.3C248.1 173.6 252.2 188.8 253.4 208.8Z" />
        <path d="M70.8 412.2C70.1 430.2 71.5 445.4 74.8 457.3C78.2 469.6 83.5 478.5 90.4 483.8C97.5 489.2 106.4 490.8 116.5 487.9C126.9 485 138.7 477.5 151.4 464.9C164.4 452 176.9 435.1 188.5 415.7C200.2 396 210.7 373.9 219.4 350.9C228.1 327.9 235 304.2 239.4 281.4C243.8 258.9 245.7 237.4 244.7 218.6C243.7 200.2 240.1 186.1 234.4 176.4C228.9 166.9 221.4 161.6 212.7 160.4C204.2 159.2 194.4 161.9 183.9 168.5C173.7 174.9 162.7 184.9 151.4 198.6C140.4 212 130 227.9 120.6 245C111.3 262 103 280.4 95.8 299.2C88.7 318 82.8 337.4 78.5 356.4C74.2 375.5 71.5 394.5 70.8 412.2ZM235.7 228.5C236.5 245.6 234.7 265 230.7 285.3C226.7 305.9 220.5 327.2 212.6 348C204.7 368.8 195.3 388.7 184.8 406.5C174.4 424.1 163.1 439.4 151.4 451.2C139.9 462.7 129.2 469.7 119.7 472.5C110.4 475.3 102.4 474 95.8 469.3C89.4 464.6 84.5 456.6 81.3 445.5C78.2 434.7 76.8 420.9 77.5 404.5C78.1 388.4 80.6 371 84.5 353.5C88.4 336.1 93.8 318.3 100.3 301C106.8 283.7 114.5 266.9 123 251.3C131.6 235.6 141.1 221.2 151.2 209C161.5 196.6 171.5 187.5 180.8 181.8C190.3 176 199.2 173.7 206.9 174.9C214.8 176.2 221.5 181.2 226.4 189.9C231.7 198.9 234.9 211.8 235.7 228.5Z" />
        <path d="M83.2 398.3C82.7 413.4 84 426 86.9 435.9C89.8 446 94.4 453.3 100.3 457.4C106.4 461.6 113.8 462.6 122.3 460C131 457.3 140.8 450.8 151.3 440.1C162 429.3 172.3 415.2 181.8 399.1C191.4 382.9 200 364.7 207.1 345.8C214.3 326.9 219.9 307.4 223.6 288.7C227.2 270.1 228.9 252.4 228.3 236.8C227.6 221.4 224.8 209.6 220.2 201.3C215.7 193.2 209.7 188.5 202.5 187.2C195.5 185.9 187.4 187.9 178.6 193.2C170 198.3 160.8 206.6 151.3 218C142 229.2 133.2 242.5 125.2 257C117.3 271.3 110.2 286.9 104.2 302.9C98.2 318.9 93.2 335.3 89.6 351.4C86 367.4 83.8 383.4 83.2 398.3ZM218.5 247.7C219 261.4 217.5 276.9 214.3 293C211.1 309.3 206.1 326.2 199.9 342.6C193.7 359 186.2 374.8 177.9 389C169.7 403 160.7 415.3 151.4 424.9C142.2 434.3 133.7 440.1 126 442.7C118.5 445.2 111.9 444.5 106.6 440.9C101.3 437.4 97.2 431.1 94.6 422.4C92 413.8 90.8 402.7 91.2 389.5C91.6 376.4 93.6 362.3 96.8 347.9C100 333.6 104.4 319 109.7 304.9C115 290.7 121.4 276.9 128.4 264.2C135.5 251.4 143.2 239.7 151.5 229.9C159.9 219.9 168 212.7 175.6 208.3C183.3 203.8 190.4 202.2 196.5 203.5C202.7 204.8 208 209.1 211.8 216.3C215.6 223.7 218 234.2 218.5 247.7Z" />
        <path d="M96 384C95.7 396.2 96.8 406.2 99.3 414.1C101.8 422.1 105.6 427.7 110.4 430.8C115.3 434 121.4 434.5 128.2 432.1C135.2 429.7 143 424.3 151.4 415.7C159.9 406.9 168 395.7 175.5 382.9C183 370 189.8 355.6 195.4 340.7C201.1 325.8 205.5 310.4 208.5 295.6C211.4 280.9 212.9 266.8 212.4 254.3C212 242 209.8 232.3 206.3 225.5C202.8 218.8 198.1 214.8 192.4 213.5C186.8 212.3 180.4 213.6 173.3 217.6C166.4 221.6 159 228.1 151.3 237.2C143.7 246.2 136.5 256.9 130 268.7C123.6 280.4 117.7 293.1 112.8 306.1C107.9 319.1 103.8 332.6 100.9 345.7C98.2 359.1 96.4 372 96 384ZM201.7 266.4C202 276.8 200.8 288.4 198.3 300.5C195.8 312.7 192.1 325.3 187.5 337.5C182.9 349.8 177.3 361.6 171.1 372.2C165 382.8 158.3 392.1 151.3 399.4C144.4 406.6 137.9 411.2 132.1 413.3C126.4 415.4 121.3 415.1 117.2 412.6C113.1 410.1 109.9 405.5 107.8 399C105.7 392.5 104.7 384.2 104.9 374.1C105.1 364.1 106.6 353.2 109 342.2C111.4 331.2 114.8 319.9 119 309C123.1 298 128 287.4 133.5 277.6C139 267.8 145 258.8 151.3 251.3C157.7 243.7 164 238.4 169.7 235.2C175.5 232 180.9 230.9 185.5 232.1C190.2 233.3 194 236.7 196.8 242.4C199.7 248.1 201.4 256.1 201.7 266.4Z" />
      </g>
      <defs>
        <clipPath id="clip0_1260_8531">
          <rect width="354.4" height="664" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const CercleCircle3 = ({ className, fill }: CercleCircleVariationProps) => {
  return (
    <svg
      width="617"
      height="576"
      viewBox="0 0 617 576"
      fill={fill ?? '#60B22C'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1260_8542)">
        <path d="M0.399995 203.8C-1.60001 240 4.39999 276.5 16.7 311.7C28.9 346.4 47.3 380 70.5 410.8C93.6 441.4 121.6 469.5 153.3 493.5C185 517.5 220.7 537.5 259.3 551.9C298.2 566.4 336.7 573.9 373.2 575.1C410.2 576.3 445.4 570.8 477 559.3C509.2 547.5 537.6 529.4 560.3 505.4C583.5 480.8 600.7 450.1 609.6 413.8C618.7 376.6 618.2 337.3 609.3 298C600.3 258 582.6 218.4 557.7 181.6C532.6 144.4 500.4 110.3 462.7 81.9002C425 53.5002 382.3 30.9002 336.3 16.6002C290.7 2.20021 246.7 -2.39979 206.3 1.20021C166.6 4.80021 130.7 16.3002 100.4 34.4002C70.7 52.1002 46.5 76.2002 29.3 105.2C12.5 133.6 2.39999 166.8 0.399995 203.8ZM606.4 412.6C597.6 448.6 580.6 479 557.7 503.4C535.3 527.2 507.1 545.2 475.2 556.9C444 568.3 409.1 573.8 372.4 572.6C336.2 571.5 298.1 564 259.6 549.7C221.4 535.4 186 515.6 154.6 491.8C123.1 468 95.4 440.2 72.5 409.9C49.5 379.3 31.2 346.1 19.2 311.7C7 276.8 1.09999 240.5 3.09999 204.7C5.09999 168.1 15.2 135.2 31.9 107.1C49 78.3002 73 54.6002 102.4 37.0002C132.4 19.1002 168 7.70021 207.2 4.20021C247.1 0.600214 290.7 5.20021 335.7 19.3002C381.2 33.5002 423.5 55.8002 460.8 84.0002C498.1 112.2 530 145.9 554.9 182.7C579.5 219.2 597.1 258.4 606 297.9C614.9 336.8 615.4 375.8 606.4 412.6Z" />
        <path d="M24 211.9C22 245.1 27.3 278.7 38.5 311.2C49.6 343.2 66.4 374.2 87.8 402.6C109 430.9 134.9 456.8 164.2 479C193.5 501.1 226.5 519.6 262.1 532.8C298 546.1 333.5 552.9 367.1 553.8C401.2 554.7 433.5 549.5 462.4 538.7C491.8 527.7 517.8 510.9 538.4 488.6C559.4 465.9 574.9 437.6 582.8 404.3C590.8 370.3 590.1 334.3 581.8 298.4C573.3 262 557.1 225.9 534.3 192.3C511.3 158.5 482 127.5 447.7 101.6C413.4 75.7 374.5 55.1 332.7 42C291.3 29 251.1 24.6 214.3 27.7C178 30.8 145.1 41 117.3 57.4C90 73.4 67.6 95.2 51.6 121.7C35.6 147.7 26.1 178 24 211.9ZM577.6 402.7C569.9 435.5 554.7 463.3 534.1 485.7C513.9 507.6 488.4 524.2 459.5 535C431.1 545.6 399.3 550.8 365.8 549.9C332.7 549 297.8 542.3 262.5 529.2C227.5 516.2 195 498 166.2 476.3C137.4 454.5 111.9 429 91 401.1C70 373.1 53.4 342.6 42.5 311.1C31.5 279.2 26.3 246.1 28.4 213.5C30.5 180.2 39.9 150.3 55.4 124.9C71.2 98.9 93.2 77.5 120.1 61.8C147.5 45.8 179.9 35.7 215.5 32.8C251.7 29.8 291.1 34.1 331.8 46.9C372.9 59.8 411.1 80.1 444.7 105.5C478.4 130.9 507.2 161.3 529.8 194.5C552.2 227.4 568.2 262.9 576.5 298.6C584.7 333.8 585.5 369.2 577.6 402.7Z" />
        <path d="M47.7 220.1C45.6 250.4 50.3 281.1 60.4 310.7C70.4 340 85.7 368.3 105.2 394.4C124.6 420.3 148.2 444.1 175.1 464.4C201.9 484.7 232.1 501.5 264.7 513.6C297.5 525.7 330 531.9 360.7 532.5C391.8 533.2 421.2 528.3 447.5 518.3C474.2 508.1 497.7 492.6 516.2 472.2C535.1 451.4 549 425.6 555.8 395.2C562.8 364.3 562 331.6 554.2 299.1C546.4 266.2 531.5 233.6 510.9 203.3C490.1 172.8 463.5 144.9 432.5 121.5C401.5 98.1004 366.4 79.5004 328.7 67.5004C291.3 55.6004 255 51.5004 221.6 54.1004C188.7 56.7004 158.8 65.8004 133.4 80.4004C108.4 94.8004 87.9 114.4 73.2 138.3C58.8 161.7 49.8 189.3 47.7 220.1ZM549 392.8C542.3 422.4 528.9 447.6 510.6 467.9C492.6 487.8 469.7 503 443.7 513C418.1 522.8 389.5 527.6 359.1 527C329.2 526.4 297.5 520.4 265.4 508.6C233.6 496.9 204.1 480.4 177.9 460.6C151.7 440.8 128.7 417.6 109.7 392.3C90.7 366.8 75.7 339.1 66 310.6C56.2 281.7 51.7 251.8 53.7 222.3C55.8 192.3 64.6 165.5 78.7 142.6C93.1 119.4 113.2 100.3 137.5 86.4004C162.3 72.2004 191.4 63.4004 223.4 60.9004C255.9 58.4004 291.2 62.4004 327.6 74.0004C364.3 85.7004 398.5 103.8 428.6 126.6C458.7 149.3 484.6 176.5 504.8 206.2C524.9 235.6 539.4 267.3 547.1 299.4C554.9 330.9 555.8 362.7 549 392.8Z" />
        <path d="M71.6 228.3C69.5 255.6 73.6 283.3 82.6 310.1C91.5 336.6 105.3 362.3 123 386C140.5 409.5 162 431.1 186.3 449.5C210.6 467.9 238 483.2 267.6 494C297.4 504.9 326.8 510.4 354.5 510.9C382.6 511.4 409.1 506.8 432.7 497.6C456.7 488.2 477.7 474.1 494.3 455.6C511.1 436.8 523.4 413.4 529.4 386C535.5 358.2 534.5 328.8 527.4 299.7C520.2 270.2 506.8 241.1 488.2 214C469.5 186.8 445.7 161.8 418 140.9C390.3 120 358.9 103.3 325.2 92.5003C291.7 81.8003 259.2 77.9003 229.3 80.1003C199.7 82.2003 172.8 90.2003 149.9 103.2C127.3 115.9 108.7 133.4 95.2 154.8C82 175.8 73.7 200.6 71.6 228.3ZM520.6 383C514.9 409.4 503.2 431.9 487 450.1C471.1 468 450.9 481.7 427.8 490.8C405.1 499.7 379.5 504.2 352.4 503.7C325.6 503.3 297.2 498 268.5 487.5C239.9 477.1 213.5 462.3 190 444.6C166.5 426.8 145.8 406 128.9 383.3C111.9 360.5 98.6 335.6 90 310C81.3 284.1 77.5 257.4 79.5 231.1C81.6 204.4 89.6 180.5 102.4 160.3C115.4 139.8 133.4 123 155.2 110.8C177.3 98.4003 203.3 90.8003 231.7 88.8003C260.5 86.8003 291.8 90.5003 324 100.8C356.4 111.2 386.6 127.3 413.2 147.4C439.8 167.5 462.7 191.5 480.7 217.7C498.6 243.7 511.5 271.7 518.5 300.1C525.4 328 526.4 356.2 520.6 383Z" />
        <path d="M95.6 236.6C93.6 260.9 97.1 285.7 105 309.6C112.9 333.3 125.2 356.4 140.9 377.6C156.6 398.7 175.7 418.1 197.5 434.5C219.3 451 243.9 464.6 270.4 474.3C297.1 484 323.4 488.8 348.2 489.1C373.3 489.4 396.9 485.2 417.9 476.9C439.2 468.4 457.8 455.7 472.4 439C487.2 422.1 497.9 401.3 503 376.9C508.2 352.1 507.2 326.1 500.7 300.3C494.2 274.2 482.2 248.5 465.8 224.6C449.2 200.6 428.2 178.5 403.7 160.1C379.2 141.6 351.5 126.8 321.7 117.2C292.1 107.6 263.3 104.1 236.8 105.9C210.6 107.7 186.6 114.6 166.2 125.9C146 137.1 129.3 152.4 117.2 171.3C105.2 189.9 97.7 211.9 95.6 236.6ZM492.4 373.3C487.6 396.5 477.5 416.3 463.5 432.4C449.7 448.2 432.1 460.4 411.9 468.5C392 476.5 369.5 480.6 345.7 480.3C322.1 480 297.1 475.5 271.7 466.3C246.4 457.1 223 444.1 202.3 428.4C181.5 412.7 163.3 394.3 148.4 374.2C133.4 354 121.8 332 114.3 309.4C106.8 286.6 103.5 263 105.5 239.9C107.5 216.5 114.7 195.6 126.2 178C137.8 160.1 153.7 145.6 172.9 135C192.3 124.3 215.1 117.8 240 116.2C265.2 114.6 292.4 117.9 320.5 127C348.7 136.1 375 150.2 398.1 167.7C421.3 185.2 441.2 206 456.9 228.8C472.5 251.4 483.9 275.8 490.1 300.6C496.2 325.1 497.2 349.8 492.4 373.3Z" />
        <path d="M119.8 244.9C117.9 266.2 120.8 288 127.7 309.1C134.5 330 145.3 350.3 159.1 369.1C172.9 387.7 189.8 404.8 209 419.4C228.2 433.9 249.9 446 273.3 454.4C296.8 462.9 320 467.1 341.8 467.3C363.8 467.5 384.6 463.7 402.9 456.2C421.5 448.6 437.7 437.3 450.3 422.6C463.1 407.7 472.3 389.4 476.6 368C481 346.3 479.9 323.6 474.2 301.1C468.4 278.4 457.9 256 443.5 235.2C429 214.3 410.7 195.2 389.4 179.1C368.1 163 344 150.1 318.1 141.7C292.3 133.3 267.3 130.1 244.1 131.6C221.2 133 200.2 138.9 182.2 148.7C164.5 158.3 149.7 171.7 138.9 188.1C128.5 204.1 121.7 223.3 119.8 244.9ZM464.3 363.6C460.4 383.6 451.9 400.7 440 414.6C428.3 428.4 413.2 439 395.9 446.1C378.8 453.1 359.4 456.8 338.8 456.6C318.4 456.5 296.7 452.6 274.7 444.7C252.8 436.8 232.5 425.6 214.5 412C196.5 398.4 180.7 382.4 167.8 364.9C154.9 347.4 144.8 328.3 138.5 308.8C132.1 289.1 129.4 268.7 131.3 248.8C133.2 228.6 139.6 210.8 149.5 195.7C159.6 180.4 173.4 168.1 190 159.1C206.8 150.1 226.3 144.6 247.7 143.4C269.3 142.1 292.6 145.1 316.5 153C340.6 160.9 363 172.9 382.8 187.8C402.6 202.7 419.6 220.5 433.1 239.9C446.5 259.2 456.3 280 461.8 301.2C467.2 322.2 468.3 343.4 464.3 363.6Z" />
        <path d="M144 253.3C142.2 271.6 144.6 290.4 150.5 308.6C156.3 326.7 165.6 344.2 177.5 360.4C189.4 376.5 204 391.3 220.6 403.9C237.2 416.5 256 426.9 276.2 434.1C296.5 441.4 316.5 444.9 335.4 445C354.4 445.1 372.2 441.7 388 435.1C403.9 428.5 417.8 418.6 428.5 405.9C439.4 393 447.1 377.2 450.7 358.8C454.3 340.2 453.3 320.7 448.2 301.5C443.2 282.1 434.1 263 421.8 245.3C409.4 227.5 393.8 211.2 375.6 197.5C357.5 183.8 336.9 172.8 314.8 165.5C292.8 158.3 271.4 155.5 251.6 156.6C232 157.7 214 162.6 198.5 170.9C183.2 179 170.4 190.4 161.1 204.4C151.8 218.3 145.8 234.7 144 253.3ZM436.4 354C433.2 370.7 426.3 385.2 416.4 396.9C406.7 408.5 394.1 417.5 379.7 423.6C365.4 429.6 349.1 432.8 331.8 432.7C314.6 432.7 296.4 429.5 277.8 422.8C259.3 416.2 242.2 406.7 227 395.2C211.8 383.7 198.5 370.2 187.7 355.5C176.8 340.7 168.4 324.6 163.2 308.2C157.9 291.6 155.7 274.5 157.4 257.8C159.1 240.9 164.6 226 173.1 213.4C181.7 200.7 193.3 190.5 207.3 183.1C221.4 175.7 237.8 171.3 255.6 170.3C273.6 169.3 293 171.9 312.9 178.5C332.9 185.1 351.5 195.1 368 207.5C384.4 219.9 398.6 234.7 409.9 250.8C421.1 266.9 429.4 284.2 434 301.8C438.6 319.4 439.6 337.1 436.4 354Z" />
        <path d="M168.4 261.7C166.8 277.1 168.7 292.8 173.6 308C178.4 323.2 186.1 338 196.1 351.6C206.1 365.2 218.3 377.6 232.3 388.2C246.3 398.8 262.1 407.5 279.1 413.6C296.2 419.7 313 422.6 328.8 422.6C344.7 422.6 359.6 419.7 372.8 414.1C386.1 408.5 397.6 400.2 406.5 389.4C415.5 378.6 421.8 365.3 424.7 349.9C427.6 334.4 426.6 318.2 422.3 302.2C418 286.1 410.4 270.2 400.2 255.5C389.9 240.8 376.9 227.2 361.8 215.9C346.8 204.5 329.7 195.4 311.4 189.3C293.1 183.3 275.4 180.9 258.9 181.7C242.5 182.5 227.5 186.5 214.6 193.3C201.8 200 191 209.4 183.1 221C175.1 232.5 170.1 246.2 168.4 261.7ZM408.6 344.4C406.2 357.9 400.7 369.6 392.8 379.1C385 388.6 375 395.9 363.4 400.9C351.9 405.9 338.7 408.5 324.8 408.5C310.9 408.5 296.1 406 281 400.7C266 395.4 252.1 387.7 239.7 378.3C227.4 369 216.5 358 207.8 346C199 334 192.2 320.9 188 307.6C183.8 294.1 182.1 280.3 183.6 266.8C185.1 253.2 189.6 241.2 196.6 231.1C203.6 221 213.1 212.8 224.4 207C235.8 201.1 249 197.7 263.3 197C277.7 196.3 293.3 198.4 309.3 203.8C325.3 209.1 340.2 217.2 353.4 227.1C366.6 237 377.9 248.8 387 261.7C396 274.5 402.7 288.4 406.5 302.5C410.2 316.6 411.1 330.8 408.6 344.4Z" />
        <path d="M193 270.1C191.6 282.4 193.1 295.1 197 307.4C200.8 319.7 207 331.6 215.1 342.7C223.1 353.7 233 363.8 244.4 372.3C255.7 380.9 268.5 387.9 282.3 392.8C296.1 397.7 309.7 400 322.4 399.9C335.2 399.8 347.2 397.4 357.8 392.8C368.4 388.2 377.6 381.4 384.7 372.7C391.8 364 396.8 353.3 399 340.9C401.2 328.4 400.3 315.5 396.9 302.6C393.4 289.7 387.3 277.1 379.1 265.4C370.8 253.7 360.4 242.9 348.4 233.8C336.4 224.7 322.8 217.4 308.2 212.5C293.6 207.6 279.4 205.7 266.2 206.3C253.1 206.9 241 210 230.6 215.4C220.3 220.7 211.6 228.1 205.1 237.4C198.5 246.7 194.4 257.7 193 270.1ZM381.1 334.9C379.3 345.2 375.3 354.1 369.4 361.4C363.6 368.7 356 374.3 347.2 378.2C338.4 382 328.5 384.1 317.8 384.2C307.2 384.3 295.8 382.4 284.3 378.3C272.8 374.2 262.1 368.4 252.7 361.2C243.2 354.1 235 345.6 228.3 336.4C221.6 327.2 216.5 317.2 213.3 307C210.1 296.7 208.9 286.2 210.1 275.9C211.3 265.6 214.8 256.5 220.2 248.9C225.6 241.2 232.9 235.1 241.5 230.8C250.2 226.4 260.2 223.9 271.1 223.4C282 222.9 293.8 224.6 305.8 228.6C317.9 232.7 329.1 238.7 339 246.2C348.9 253.7 357.5 262.6 364.4 272.3C371.2 282 376.3 292.5 379.2 303.1C382.1 313.8 382.8 324.6 381.1 334.9Z" />
      </g>
    </svg>
  )
}

const CercleCircle4 = ({ className, fill }: CercleCircleVariationProps) => {
  return (
    <svg
      width="449"
      height="667"
      viewBox="0 0 449 667"
      fill={fill ?? '#60B22C'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_1260_8553)">
        <path d="M4.70001 289.2C-0.999987 330.4 -1.39999 370.8 2.70001 409C6.90001 447.4 15.7 483.9 28.5 516.6C41.6 549.8 58.9 579.4 79.8 603.3C101.2 627.8 126.6 646.5 155.2 657.1C184.6 668 214.8 669.4 244.3 662.3C274.5 655 303.8 638.7 330.4 614.6C357.6 590 381.9 557.4 401.3 518.4C421 478.9 435.6 433 443.1 382.9C450.6 332.6 450.3 283.3 443.2 237.5C436.2 192.1 422.8 150.7 404.1 115.6C385.8 81.1002 362.7 53.0002 336 33.1002C310 13.7002 280.8 2.20021 249.5 0.400214C219.1 -1.39979 189.6 6.00021 162.3 20.7002C135.6 35.0002 110.9 56.3002 89.3 82.8002C68.1 108.8 49.7 140.1 35.2 175C21 209.4 10.5 248 4.70001 289.2ZM440.6 382.3C433.2 431.9 418.8 477.3 399.3 516.4C380 555 356 587.3 329.1 611.6C302.7 635.5 273.8 651.7 243.9 659C214.8 666.1 184.9 664.8 155.7 654C127.3 643.5 102.2 625 81 600.8C60.2 577.1 43.1 547.8 30.1 514.9C17.4 482.5 8.60001 446.4 4.50001 408.3C0.400013 370.5 0.800014 330.4 6.50001 289.6C12.2 248.7 22.6 210.5 36.8 176.2C51.1 141.5 69.3 110.6 90.4 84.8002C111.8 58.6002 136.4 37.5002 162.8 23.4002C189.9 8.90021 219.1 1.60021 249.2 3.50021C280.1 5.40021 309.1 16.8002 334.8 36.0002C361.2 55.7002 384 83.6002 402.1 117.8C420.5 152.6 433.8 193.5 440.7 238.4C447.7 283.7 448 332.5 440.6 382.3Z" />
        <path d="M19.7 292.4C14.4 330.4 14 367.8 17.9 403.1C21.8 438.6 30 472.2 42 502.3C54.1 532.8 70.2 559.9 89.6 581.8C109.4 604.1 132.8 621 159.2 630.4C186.2 640.1 213.9 641 240.8 634.1C268.3 627.1 295 611.9 319.2 589.6C343.9 566.9 365.9 537.1 383.5 501.5C401.3 465.4 414.5 423.7 421.3 378.1C428.1 332.4 427.8 287.5 421.5 245.9C415.2 204.6 403.1 166.8 386.3 134.7C369.8 103.1 348.8 77.2004 324.6 58.8004C300.9 40.8004 274.3 29.9004 245.7 27.9004C217.9 25.9004 190.8 32.4004 165.6 45.6004C141 58.4004 118.1 77.8004 98.1 102C78.4 125.8 61.4 154.6 48 186.8C34.8 218.7 25 254.3 19.7 292.4ZM417.1 377.3C410.4 422.1 397.5 463 380 498.4C362.7 533.4 341.2 562.7 317 585C293.2 606.9 267.1 621.9 240 628.8C213.6 635.6 186.4 634.8 159.8 625.3C133.9 616.1 110.8 599.5 91.4 577.6C72.3 556.2 56.5 529.5 44.5 499.5C32.7 470 24.6 436.9 20.8 402C17 367.3 17.3 330.5 22.6 293C27.8 255.5 37.5 220.5 50.5 189.1C63.7 157.4 80.5 129.2 99.8 105.7C119.5 81.9004 142 62.9004 166.2 50.2004C190.9 37.3004 217.6 31.0004 244.9 33.0004C273 35.1004 299.1 45.8004 322.4 63.6004C346.1 81.8004 366.7 107.3 382.9 138.3C399.4 169.9 411.3 206.9 417.4 247.5C423.6 288.4 423.8 332.4 417.1 377.3Z" />
        <path d="M34.9 295.6C30 330.5 29.7 364.7 33.3 397C36.9 429.5 44.5 460.2 55.6 487.7C66.8 515.5 81.6 540.2 99.4 559.9C117.6 580 138.9 595.2 163 603.5C187.6 612 212.8 612.5 237.2 606C262.1 599.3 286.2 585.3 308 565C330.2 544.3 350 517.2 365.8 484.9C381.8 452.3 393.6 414.6 399.7 373.5C405.8 332.3 405.6 291.8 400 254.3C394.4 217 383.6 182.8 368.6 153.7C353.8 125 334.9 101.3 313.2 84.4004C291.9 67.8004 267.7 57.7004 241.9 55.5004C216.6 53.3004 191.9 59.0004 169 70.7004C146.5 82.2004 125.6 99.7004 107.2 121.7C89 143.3 73.3 169.5 61 198.9C48.8 228.1 39.7 260.7 34.9 295.6ZM394.1 372.3C388.2 412.3 376.7 448.9 361.2 480.6C345.9 512 326.7 538.4 305.1 558.6C283.9 578.4 260.5 592.2 236.2 598.7C212.4 605.1 187.9 604.7 163.9 596.5C140.4 588.5 119.5 573.8 101.8 554.2C84.5 535.1 70 511.1 59.1 484C48.3 457.2 40.9 427.2 37.3 395.5C33.8 364 34.1 330.5 38.8 296.5C43.6 262.4 52.4 230.6 64.3 202.1C76.3 173.4 91.7 147.9 109.3 126.7C127.2 105.3 147.6 88.3004 169.6 77.1004C192 65.8004 216 60.4004 240.7 62.6004C265.9 64.8004 289.4 74.8004 310.1 91.0004C331.3 107.6 349.6 130.6 363.9 158.6C378.5 187 389 220.2 394.4 256.5C399.9 293 400.1 332.3 394.1 372.3Z" />
        <path d="M50.3 298.9C45.9 330.6 45.6 361.7 48.9 391C52.2 420.5 59.2 448.3 69.3 473.1C79.5 498.2 93 520.4 109.2 538.1C125.7 556.1 145.1 569.6 166.8 576.8C189 584.2 211.7 584.4 233.6 578.2C255.9 571.9 277.5 559.1 297 540.7C316.8 522 334.4 497.6 348.4 468.6C362.6 439.4 373.1 405.7 378.5 369C383.9 332.2 383.8 296.1 378.9 262.5C374 229.1 364.4 198.5 351.1 172.3C337.9 146.5 321.2 125.1 301.8 109.7C282.7 94.5 261.1 85.2 237.9 82.9C215.1 80.7 192.9 85.5 172.2 95.8C151.8 106 132.9 121.6 116.2 141.4C99.8 160.9 85.5 184.5 74.3 211.2C63 237.7 54.7 267.2 50.3 298.9ZM371.6 367.5C366.4 402.8 356.3 435.1 342.7 463.2C329.2 491 312.3 514.5 293.3 532.6C274.6 550.4 253.9 562.8 232.4 568.9C211.3 574.9 189.5 574.8 168.1 567.8C147.1 560.9 128.4 548 112.5 530.7C96.8 513.7 83.8 492.3 73.9 468.1C64.1 444.2 57.4 417.3 54.2 388.9C51 360.6 51.2 330.6 55.5 300C59.8 269.4 67.7 240.8 78.5 215.3C89.4 189.6 103.2 166.8 119 148C135.1 129 153.4 114 173.1 104.2C193.1 94.3 214.5 89.7 236.5 92C258.9 94.3 279.6 103.4 298 118C316.7 132.9 332.7 153.5 345.4 178.4C358.2 203.6 367.4 233 372 265.1C376.6 297.5 376.8 332.2 371.6 367.5Z" />
        <path d="M66 302.3C62 330.7 61.8 358.6 64.8 384.9C67.8 411.3 74.1 436.2 83.2 458.3C92.4 480.7 104.5 500.4 119.1 516C133.9 531.9 151.2 543.7 170.7 549.9C190.5 556.2 210.6 556.1 230.1 550.4C249.9 544.6 268.9 533 286.1 516.6C303.5 499.9 319 478.2 331.3 452.6C343.7 426.7 352.9 397 357.7 364.6C362.5 332.1 362.4 300.3 358.1 270.6C353.8 241.1 345.5 214 333.8 190.8C322.3 167.8 307.5 148.8 290.4 134.9C273.6 121.3 254.5 112.7 233.8 110.4C213.6 108.2 193.8 112.2 175.2 121.2C157 130.1 140 143.9 125 161.4C110.2 178.7 97.4 199.8 87.3 223.6C77.3 247.4 69.9 273.8 66 302.3ZM349.4 362.8C344.9 393.5 336.2 421.6 324.4 446.2C312.7 470.5 298.1 491.1 281.6 507C265.3 522.7 247.3 533.8 228.6 539.3C210.1 544.8 191 545 172.2 539.1C153.7 533.3 137.2 522.2 123.1 507.1C109.2 492.3 97.6 473.5 88.8 452.2C80.1 431.1 74.1 407.4 71.2 382.3C68.3 357.3 68.5 330.7 72.3 303.6C76.1 276.5 83.2 251.3 92.8 228.8C102.5 206.1 114.7 186.1 128.8 169.6C143.1 152.9 159.2 139.9 176.6 131.5C194.2 123 213.1 119.2 232.3 121.5C251.9 123.8 270 132 285.9 145C302.1 158.2 316 176.4 326.9 198.2C337.9 220.3 345.8 245.9 349.8 273.9C353.8 301.9 353.9 332.1 349.4 362.8Z" />
        <path d="M81.8001 305.7C78.3001 330.8 78.1001 355.4 80.8001 378.6C83.5001 401.9 89.1001 423.8 97.2001 443.3C105.4 463 116.1 480.2 129 493.8C142.1 507.6 157.4 517.8 174.5 523C191.9 528.3 209.5 528 226.5 522.8C243.7 517.5 260.3 507.3 275.2 492.7C290.3 478 303.7 459 314.4 436.7C325.2 414.2 333.1 388.3 337.2 360.2C341.3 332 341.3 304.3 337.6 278.6C333.9 253 326.7 229.3 316.7 209C306.7 188.9 294 172.1 279.1 159.9C264.5 147.8 247.8 140.1 229.8 137.9C212.1 135.7 194.7 139 178.4 146.8C162.4 154.4 147.4 166.4 134.2 181.8C121.1 197 109.8 215.5 100.8 236.4C91.9001 257.2 85.4001 280.6 81.8001 305.7ZM327.7 358.2C323.9 384.4 316.5 408.4 306.5 429.4C296.6 450.2 284.2 467.9 270.2 481.6C256.3 495.2 240.9 504.8 224.9 509.8C209.1 514.7 192.6 515.1 176.4 510.2C160.4 505.4 146.1 496 133.9 483.2C121.8 470.5 111.7 454.4 104.1 436.1C96.5001 417.9 91.2001 397.4 88.6001 375.6C86.1001 353.9 86.2001 330.8 89.5001 307.3C92.8001 283.8 99.0001 261.9 107.3 242.4C115.7 222.8 126.4 205.5 138.6 191.4C151 177.1 165 166 180 158.9C195.2 151.7 211.4 148.7 227.9 150.9C244.7 153.1 260.1 160.3 273.7 171.7C287.5 183.2 299.3 198.9 308.5 217.6C317.8 236.5 324.5 258.5 327.9 282.4C331.4 306.2 331.5 332 327.7 358.2Z" />
        <path d="M98 309.1C94.9 330.8 94.8 352.1 97.2 372.2C99.6 392.3 104.5 411.3 111.6 428C118.7 444.9 128.1 459.7 139.2 471.4C150.5 483.2 163.7 491.8 178.5 496.1C193.4 500.5 208.6 500 223.1 495.4C237.8 490.7 251.9 481.8 264.7 469.2C277.5 456.5 288.9 440.3 297.9 421.1C307 401.9 313.7 379.8 317.2 355.8C320.7 331.8 320.7 308.2 317.6 286.3C314.5 264.4 308.4 244.2 299.9 226.9C291.5 209.7 280.7 195.2 268 184.6C255.5 174.1 241.3 167.3 225.9 165.2C210.7 163.1 195.8 165.8 181.8 172.3C168 178.7 155 188.9 143.5 202.1C132.2 215.1 122.3 231 114.5 249.1C106.8 267.2 101.1 287.4 98 309.1ZM306.3 353.6C303.1 375.3 297 395.3 288.8 412.8C280.6 430.2 270.4 445 258.7 456.5C247.2 467.9 234.4 476.1 221 480.5C207.8 484.8 194 485.3 180.4 481.4C167 477.6 154.9 469.8 144.6 459.1C134.4 448.5 125.8 435.1 119.3 419.7C112.8 404.4 108.3 387.1 106.1 368.7C103.9 350.4 104.1 330.9 106.9 311C109.7 291.1 114.9 272.7 122 256.3C129.1 239.8 138.2 225.3 148.5 213.4C159 201.5 170.8 192.2 183.5 186.4C196.3 180.5 209.9 178.2 223.7 180.2C237.7 182.2 250.6 188.4 261.9 198C273.3 207.7 283.1 220.9 290.7 236.6C298.4 252.4 303.9 270.7 306.6 290.5C309.5 310.5 309.5 331.9 306.3 353.6Z" />
        <path d="M114.4 312.6C111.8 330.9 111.7 348.9 113.7 365.7C115.7 382.6 119.9 398.5 125.9 412.6C131.9 426.8 139.8 439.1 149.3 448.8C158.8 458.6 169.9 465.6 182.3 469.1C194.8 472.6 207.4 472.1 219.6 468.1C231.8 464.1 243.6 456.5 254.1 446C264.7 435.4 274.1 421.8 281.6 405.9C289.1 389.9 294.6 371.6 297.6 351.7C300.5 331.8 300.5 312.3 298 294.1C295.5 275.9 290.5 259.2 283.5 244.7C276.5 230.3 267.6 218.2 257.2 209.2C246.9 200.3 235.1 194.5 222.2 192.6C209.5 190.7 197 192.8 185.3 198.1C173.7 203.4 162.8 211.8 153.1 222.8C143.5 233.7 135.2 247 128.6 262.2C121.8 277.3 117 294.3 114.4 312.6ZM285.4 349.1C282.9 366.5 278 382.5 271.5 396.5C265 410.4 256.8 422.4 247.5 431.7C238.3 441 228.1 447.7 217.3 451.3C206.7 454.9 195.6 455.4 184.6 452.4C173.7 449.4 163.9 443.3 155.5 434.8C147.2 426.3 140.2 415.5 134.8 403.1C129.5 390.7 125.8 376.7 124 361.8C122.2 346.9 122.3 331 124.6 314.9C126.9 298.7 131.2 283.7 137 270.4C142.8 257 150.2 245.3 158.7 235.8C167.2 226.2 176.8 218.8 187.1 214.3C197.4 209.7 208.4 207.9 219.6 209.7C230.8 211.5 241.2 216.6 250.2 224.5C259.3 232.4 267.1 243.1 273.2 255.7C279.3 268.4 283.6 283.1 285.8 299C287.9 314.7 287.9 331.8 285.4 349.1Z" />
        <path d="M131.1 316.2C129 331 128.9 345.6 130.6 359.2C132.3 372.9 135.7 385.7 140.6 397.1C145.5 408.5 151.9 418.4 159.6 426.1C167.3 433.9 176.3 439.5 186.3 442.1C196.4 444.8 206.5 444.2 216.2 440.9C226 437.6 235.3 431.4 243.7 422.9C252.1 414.3 259.6 403.4 265.5 390.7C271.4 377.9 275.8 363.3 278.1 347.5C280.4 331.7 280.4 316.1 278.4 301.6C276.4 287.1 272.5 273.7 266.9 262.1C261.4 250.6 254.3 240.8 246 233.5C237.8 226.3 228.3 221.5 218.1 219.9C207.9 218.3 197.9 219.8 188.4 224C179 228.1 170.2 234.9 162.4 243.6C154.6 252.3 147.9 263 142.5 275.3C137.1 287.6 133.2 301.4 131.1 316.2ZM264.8 344.7C262.9 357.8 259.3 369.8 254.4 380.4C249.5 390.9 243.4 400 236.4 407.1C229.5 414.2 221.7 419.3 213.6 422.2C205.5 425 197.1 425.5 188.8 423.4C180.5 421.3 173 416.7 166.6 410.3C160.2 403.9 154.8 395.7 150.7 386.2C146.6 376.8 143.7 366 142.3 354.6C140.9 343.2 141 331 142.7 318.6C144.5 306.2 147.8 294.7 152.2 284.5C156.7 274.3 162.4 265.3 168.9 258.1C175.4 250.8 182.8 245.3 190.6 241.9C198.5 238.5 206.8 237.3 215.3 238.7C223.8 240.1 231.6 244.2 238.4 250.2C245.3 256.3 251.1 264.4 255.6 274C260.2 283.6 263.4 294.7 265 306.7C266.8 318.8 266.8 331.6 264.8 344.7Z" />
      </g>
    </svg>
  )
}

interface CercleCircleProps {
  className?: string
  variation: 1 | 2 | 3 | 4
}

export const CercleCircle = ({ variation, className }: CercleCircleProps) => {
  switch (variation) {
    case 1: {
      return <CercleCircle1 className={className} />
    }
    case 2: {
      return <CercleCircle2 className={className} />
    }
    case 3: {
      return <CercleCircle3 className={className} />
    }
    case 4: {
      return <CercleCircle4 className={className} />
    }
  }
}
