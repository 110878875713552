import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodySmallBold,
  BodySmallRegular,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`

export const StLeagueLogo = styled(StoryblokServiceImage)`
  width: 40;
  height: 40;
  object-fit: contain;
`

export const StLeagueInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const StLeagueName = styled(BodySmallBold)``

export const StLeagueInfo = styled(BodySmallRegular)``
