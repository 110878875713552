import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  ArticleTitle,
  BodySmallBold,
  BodySmallLinkStyles,
} from '../../../shared/components/typography'

export const StArticleTitle = styled(ArticleTitle)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`

export const StContainer = styled.a`
  min-height: 100px;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  text-decoration: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex-direction: column;
    height: 430px;
  }
`

export const StSlantedEdge = styled.div`
  display: hidden;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    position: absolute;
    display: block;
    width: 5000px;
    height: 100px;
    background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
    transform: rotate(-2deg);
    transform-origin: 0% 0%;
    z-index: 20;
    top: -8px;
    left: ${({ theme }) => '-' + theme.UI.SpacingPx.Space6};
  }
`

export const StBackgroundContainer = styled.div`
  flex: 1;

  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex: none;
    position: relative;
    height: 140px;
  }
`

export const StContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: ${({ theme }) =>
    theme.UI.SpacingPx.Space4 + ' ' + theme.UI.SpacingPx.Space6};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex: none;
    position: relative;
    height: 140px;
    z-index: 21;
  }
`

export const StImage = styled(StoryblokServiceImage)`
  position: relative;
  width: 100px;
  height: 100px;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    width: 100%;

    height: calc(100% - 140px);
  }
`

export const StExtraInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StLinkContainer = styled.div`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: flex;
    flex-direction: row;
  }
`

export const StLinkCta = styled.span`
  ${BodySmallLinkStyles}
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
`

export const StIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
  position: relative;
  font: 16px;
  top: 1px;
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StTimestamp = styled(BodySmallBold)`
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
`
