import { useEffect, useMemo, useState } from 'react'

import {
  StContainer,
  StClubLogo,
  StTimeLineColumn,
  StTimeLineLabel,
  StTimeLinePart,
  StTimeLinePartBackground,
  StTimeLinePartForeground,
  StWrapper,
  StTimeLineGradient,
} from './MatchTimeLine.styled'
import { TimeLineEvent } from './TimeLineEvent'

import type { StoryblokMatchLiveUpdateType } from '../../../storyblok/content-types/match'
import type { StoryblokImage } from 'types'

export type MatchEvent = {
  type: StoryblokMatchLiveUpdateType | 'player_in' | 'player_out'
  minute: number
  text: string
  team: 'home' | 'away'
}

interface Props {
  homeTeamLogo?: StoryblokImage
  awayTeamLogo?: StoryblokImage
  events: Map<number, MatchEvent[]>
  matchProgressTime?: number
  showExtraTime?: boolean
}

export const MatchTimeLine = ({
  homeTeamLogo,
  awayTeamLogo,
  events,
  matchProgressTime,
  showExtraTime,
}: Props) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  const firstHalfEvents = useMemo(() => {
    return new Map([...events].reverse().filter(([minute]) => minute <= 45))
  }, [events])

  const secondHalfEvents = useMemo(() => {
    return new Map(
      [...events].reverse().filter(([minute]) => minute > 45 && minute <= 90)
    )
  }, [events])

  const extraTimeFirstHalfEvents = useMemo(() => {
    return new Map(
      [...events].reverse().filter(([minute]) => minute > 90 && minute <= 105)
    )
  }, [events])

  const extraTimeSecondHalfEvents = useMemo(() => {
    return new Map([...events].reverse().filter(([minute]) => minute > 105))
  }, [events])

  const shouldShowMinute = (
    minute: number,
    events: Map<number, MatchEvent[]>
  ) => {
    // If at least one event happened 2 minutes before or after the current minute, don't show the minute to prevent overlap
    return !events.has(minute - 2) && !events.has(minute - 1)
  }

  if (!isMounted) {
    return null
  }

  return (
    <StWrapper>
      <StContainer>
        <StTimeLineColumn>
          {homeTeamLogo && (
            <StClubLogo
              image={homeTeamLogo}
              width={48}
              height={52}
              sizeMobile={'150px'}
              sizeTablet={'150px'}
              sizeDesktop={'48px'}
            />
          )}
          <StTimeLineLabel>KO</StTimeLineLabel>
          {awayTeamLogo && (
            <StClubLogo
              image={awayTeamLogo}
              width={48}
              height={52}
              sizeMobile={'150px'}
              sizeTablet={'150px'}
              sizeDesktop={'48px'}
            />
          )}
        </StTimeLineColumn>
        <StTimeLinePart>
          <StTimeLinePartBackground />
          <StTimeLinePartForeground
            $fill={Math.min(((matchProgressTime ?? 0) / 45) * 100, 100)}
          />
          {[...firstHalfEvents].map(([minute, events]) => (
            <TimeLineEvent
              key={minute}
              minute={minute}
              showMinute={shouldShowMinute(minute, firstHalfEvents)}
              events={events}
              half="first"
            />
          ))}
        </StTimeLinePart>
        <StTimeLineColumn>
          <StTimeLineLabel>HT</StTimeLineLabel>
        </StTimeLineColumn>
        <StTimeLinePart>
          <StTimeLinePartBackground />
          <StTimeLinePartForeground
            $fill={Math.min((((matchProgressTime ?? 0) - 45) / 45) * 100, 100)}
          />
          {[...secondHalfEvents].map(([minute, events]) => (
            <TimeLineEvent
              key={minute}
              minute={minute}
              showMinute={shouldShowMinute(minute, secondHalfEvents)}
              events={events}
              half="second"
            />
          ))}
        </StTimeLinePart>
        <StTimeLineColumn>
          <StTimeLineLabel>FT</StTimeLineLabel>
        </StTimeLineColumn>
        {showExtraTime && (
          <>
            <StTimeLinePart $extraTime>
              <StTimeLinePartBackground />
              <StTimeLinePartForeground
                $fill={Math.min(
                  (((matchProgressTime ?? 0) - 90) / 15) * 100,
                  100
                )}
              />
              {[...extraTimeFirstHalfEvents].map(([minute, events]) => (
                <TimeLineEvent
                  key={minute}
                  minute={minute}
                  showMinute={shouldShowMinute(
                    minute,
                    extraTimeFirstHalfEvents
                  )}
                  events={events}
                  half="extraTimeFirst"
                />
              ))}
            </StTimeLinePart>
            <StTimeLineColumn>
              <StTimeLineLabel>ET 1</StTimeLineLabel>
            </StTimeLineColumn>
            <StTimeLinePart $extraTime>
              <StTimeLinePartBackground />
              <StTimeLinePartForeground
                $fill={Math.min(
                  (((matchProgressTime ?? 0) - 105) / 15) * 100,
                  100
                )}
              />
              {[...extraTimeSecondHalfEvents].map(([minute, events]) => (
                <TimeLineEvent
                  key={minute}
                  minute={minute}
                  showMinute={shouldShowMinute(
                    minute,
                    extraTimeSecondHalfEvents
                  )}
                  events={events}
                  half="extraTimeSecond"
                />
              ))}
            </StTimeLinePart>
            <StTimeLineColumn>
              <StTimeLineLabel>ET 2</StTimeLineLabel>
            </StTimeLineColumn>
          </>
        )}
      </StContainer>
      <StTimeLineGradient />
    </StWrapper>
  )
}
