import type { HtmlHTMLAttributes } from 'react'

type SvgProps = HtmlHTMLAttributes<SVGElement> & {
  width?: number
  height?: number
}

export const TimeLineMarkerIcon = (props: SvgProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      {...props}
    >
      <path
        d="M18.7097 21.9951L20.1311 21.9951L20.5985 20.6528L23.8224 11.3934L24.3268 9.94476L23.0584 9.08205L14.6252 3.34601L13.5004 2.58094L12.3756 3.34601L3.9424 9.08205L2.67404 9.94476L3.17843 11.3934L6.40235 20.6528L6.86974 21.9951L8.29114 21.9951L18.7097 21.9951Z"
        fill="#60B22C"
        stroke="black"
        strokeWidth="4"
      />
    </svg>
  )
}
