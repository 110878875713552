import { ButtonLink } from '../../../shared/components/button'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { isValidLink } from '../match-live-card'

import {
  StClubLogo,
  StContainer,
  StTitle,
  StTitleContainer,
  StTeam,
  StLink,
  StContentContainer,
  StTeamContainer,
  StContent,
  StScore,
  StScoreAmount,
  StResultContainer,
  StButtonContainer,
} from './MatchHighlight.styled'

import type { Hyperlink, StoryblokImage } from 'types'

type MatchHighlightProps = {
  className?: string
  title?: string
  teamName?: string
  link?: Hyperlink
  linkText?: string
  background?: StoryblokImage
  clubLogo?: StoryblokImage
  homeTeamIsCercle?: boolean
  homeTeamGoals?: string
  awayTeamIsCercle?: boolean
  awayTeamGoals?: string
}

export const MatchHighlight = ({
  className,
  title,
  link,
  linkText,
  teamName,
  background,
  clubLogo,
  homeTeamIsCercle,
  homeTeamGoals,
  awayTeamIsCercle,
  awayTeamGoals,
}: MatchHighlightProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  return (
    <StContainer className={className}>
      <StTitleContainer>
        <StTitle>{title ?? 'Match highlight'}</StTitle>
      </StTitleContainer>
      <StContentContainer background={`${background?.filename}/m/1280x0` ?? ''}>
        <StTeamContainer>
          {clubLogo && <StClubLogo image={clubLogo} width={40} height={40} />}
          <StContent>
            <StTeam>{teamName}</StTeam>
            {link && isValidLink(link) && (
              <StLink href={link.href}>
                {linkText ?? t('match_card_report')}
              </StLink>
            )}
          </StContent>
        </StTeamContainer>
        <StResultContainer>
          <StScore>
            <StScoreAmount isCercle={homeTeamIsCercle ?? false}>
              {homeTeamGoals}
            </StScoreAmount>{' '}
            -{' '}
            <StScoreAmount isCercle={awayTeamIsCercle ?? false}>
              {awayTeamGoals}
            </StScoreAmount>
          </StScore>
        </StResultContainer>
        <StButtonContainer>
          {link && isValidLink(link) && (
            <ButtonLink variant={'secondary'} size="small" href={link.href}>
              {linkText ?? t('match_card_report')}
            </ButtonLink>
          )}
        </StButtonContainer>
      </StContentContainer>
    </StContainer>
  )
}
