import { StContainer } from './PageContainer.styled'

import type { ReactNode } from 'react'

type Props = {
  className?: string
  children: ReactNode
}

export function PageContainer({ children, className }: Props) {
  return <StContainer className={className}>{children}</StContainer>
}
