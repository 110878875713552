import styled from 'styled-components'

export const StDataContainer = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};
  display: grid;
  grid-template-columns: 1fr;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`
