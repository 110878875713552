import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'
import { ButtonLink } from '../button'

import {
  StOuterCard,
  StCard,
  StCardTitle,
  StCardSubtitle,
  StBackgroundImage,
} from './PromoCard.styled'

import type { SbButtonProps } from '../../../storyblok/components/button'
import type { ReactNode } from 'react'
import type { StoryblokImage } from 'types'

type PromoCardOverlayProps = {
  useOverlay: boolean
  children?: ReactNode
}

type PromoCardProps = PromoCardOverlayProps & {
  title: string
  subtitle?: string
  button?: SbButtonProps
  backgroundImage?: StoryblokImage
  isSquare: boolean
  useColor: boolean
  firstColor?: string
  useGradient: boolean
  secondColor?: string
  gradientAngle?: number
}

export const PromoCard = ({
  title,
  subtitle,
  button,
  backgroundImage,
  useColor,
  isSquare,
  firstColor,
  useGradient,
  secondColor,
  gradientAngle,
  useOverlay,
}: PromoCardProps) => {
  const href = getHrefFromStoryblokLink(button?.link)

  return (
    <StOuterCard
      isSquare={isSquare}
      useColor={useColor}
      firstColor={firstColor}
      useGradient={useGradient}
      secondColor={secondColor}
      gradientAngle={gradientAngle}
    >
      {backgroundImage && (
        <StBackgroundImage
          sizeMobile="100vw"
          sizeTablet="100vw"
          sizeDesktop="50vw"
          image={backgroundImage}
        />
      )}

      <StCard hasOverlay={useOverlay}>
        <StCardTitle>{title}</StCardTitle>
        {subtitle && <StCardSubtitle>{subtitle}</StCardSubtitle>}
        {button && (
          <ButtonLink
            variant={button.type}
            size={button.size}
            disabled={!button.enabled}
            prefixIcon={button.prefix_icon}
            suffixIcon={button.suffix_icon}
            href={href}
            target={button.link.target}
            gaEventLabel={button.ga_event_label}
          >
            {button.label}
          </ButtonLink>
        )}
      </StCard>
    </StOuterCard>
  )
}
