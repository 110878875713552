import { storyblokEditable } from '@storyblok/react'
import { formatInTimeZone } from 'date-fns-tz'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { isEmptyStoryblokImage } from 'utils'

import { ArticleCard } from '../../../content/components/article-card'
import { Loader } from '../../../shared/components/loader'
import { Pagination } from '../../../shared/components/pagination'
import { useStoryblokCercleGeneralSettings } from '../../hooks/useStoryblokCercleGeneralSettings'
import { useStoryblokStoriesPaginated } from '../../hooks/useStoryblokStoriesPaginated'

import {
  StContainer,
  StItem,
  StLoadingContainer,
} from './ArticlesOverview.styled'

import type { SbArticlesOverviewData } from './ArticlesOverview.types'
import type { SbArticlePageData } from '../article-page/ArticlePage.types'

type Props = {
  blok: SbArticlesOverviewData
}

export const ArticlesOverview = ({ blok }: Props) => {
  const { locale } = useRouter()
  const { config, isLoading: areAppSettingsLoading } =
    useStoryblokCercleGeneralSettings()

  const [page, setPage] = useState(1)

  const {
    stories: articles,
    isLoading: areArticlesLoading,
    totalStories,
    getPage,
  } = useStoryblokStoriesPaginated<SbArticlePageData>({
    content_type: 'article_page',
    per_page: blok.articles_per_page,
    sort_by: 'first_published_at:desc',
    page: 1,
    language: locale,
    filter_query: {
      __or: [
        {
          release_date: {
            lt_date: formatInTimeZone(new Date(), 'UTC', 'yyyy-MM-dd HH:mm'),
          },
        },
        {
          release_date: {
            is: 'empty',
          },
        },
      ],
      languages: {
        any_in_array: locale,
      },
    },
  })

  useEffect(() => {
    getPage(page)
    getPage(page + 1)
  }, [page])

  if (areAppSettingsLoading || areArticlesLoading) {
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )
  }

  return (
    <div {...storyblokEditable(blok)}>
      <StContainer>
        {articles[page]?.map((article) => {
          return (
            <StItem key={article.id}>
              <ArticleCard
                image={
                  isEmptyStoryblokImage(article.content.thumbnail)
                    ? config?.content.default_article_thumbnail
                    : article.content.thumbnail
                }
                title={article.content.title}
                href={`/${article.full_slug}`}
                publishDate={
                  article.first_published_at
                    ? new Date(article.first_published_at)
                    : new Date()
                }
              />
            </StItem>
          )
        })}
      </StContainer>
      <Pagination
        totalItems={totalStories}
        itemsPerPage={blok.articles_per_page}
        page={page}
        setPage={setPage}
      />
    </div>
  )
}
