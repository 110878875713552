import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { StContainer, StSelect } from './LanguageSelector.styled'

const LanguageIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0, flexGrow: 0 }}
    >
      <path
        d="M5.8125 0.84375C3.71875 1.46875 2 3.03125 1.125 5H4.25C4.53125 3.28125 5.09375 1.8125 5.8125 0.84375ZM5 8C5 8.71875 5.03125 9.375 5.09375 10H10.875C10.9375 9.375 11 8.71875 11 8C11 7.3125 10.9375 6.65625 10.875 6H5.09375C5.03125 6.65625 5 7.3125 5 8ZM10.7188 5C10.2188 2.25 9.0625 0.5 8 0.5C6.90625 0.5 5.75 2.25 5.25 5H10.7188ZM14.8438 5C13.9688 3.03125 12.25 1.46875 10.1562 0.84375C10.875 1.8125 11.4375 3.28125 11.7188 5H14.8438ZM10.1562 15.1875C12.25 14.5625 13.9688 13 14.8438 11H11.7188C11.4375 12.75 10.875 14.2188 10.1562 15.1875ZM15.2188 6H11.875C11.9375 6.65625 11.9688 7.3125 11.9688 8C11.9688 8.71875 11.9375 9.375 11.875 10H15.2188C15.375 9.375 15.4688 8.71875 15.4688 8C15.4688 7.3125 15.375 6.65625 15.2188 6ZM5.25 11C5.75 13.7812 6.90625 15.5 7.96875 15.5C9.0625 15.5 10.2188 13.7812 10.7188 11H5.25ZM4 8C4 7.3125 4.03125 6.65625 4.09375 6H0.75C0.59375 6.65625 0.46875 7.3125 0.46875 8C0.46875 8.71875 0.59375 9.375 0.75 10H4.09375C4.03125 9.375 4 8.71875 4 8ZM1.125 11C2 13 3.6875 14.5625 5.8125 15.1875C5.09375 14.2188 4.53125 12.75 4.25 11H1.125Z"
        fill="#529926"
      />
    </svg>
  )
}

const getFullLanguage = (languageCode: string) => {
  if (languageCode === 'nl') {
    return 'Nederlands'
  }
  if (languageCode === 'fr') {
    return 'Français'
  }
  if (languageCode === 'en') {
    return 'English'
  }
  return languageCode
}

type LanguageSelectorProps = {
  currentLanguage: string
  options: string[]
}

export const LanguageSelector = ({
  currentLanguage,
  options,
}: LanguageSelectorProps) => {
  const router = useRouter()

  const changeLocale = useCallback(
    (event: { target: { value: string } }) => {
      router.push(router.asPath, router.asPath, { locale: event.target.value })
    },
    [router]
  )

  return (
    <StContainer>
      <LanguageIcon />
      <StSelect defaultValue={currentLanguage} onChange={changeLocale}>
        {options.map((language) => {
          return (
            <option key={language} value={language}>
              {getFullLanguage(language)}
            </option>
          )
        })}
      </StSelect>
    </StContainer>
  )
}
