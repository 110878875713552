import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodySmallBoldStyles,
  BodySmallLink,
} from '../../../shared/components/typography'

export const Footer = styled.footer`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
`

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex-direction: row;
    justify-content: space-between;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

export const Socials = styled.div`
  display: flex;
  gap: 24px;
  height: 36px;
  margin: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  align-items: center;
`

export const Divider = styled.hr`
  border-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  border-style: solid;
  border-width: 1px;
  margin: 0;
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space8} ${theme.UI.SpacingPx.Space2}`};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

export const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const FooterLogo = styled(StoryblokServiceImage)`
  margin-right: 0;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  width: 48px;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
    margin-bottom: 0;
  }
`

export const StLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    align-items: flex-end;
    gap: 0px;
  }
`

export const StCreatorLink = styled(BodySmallLink)`
  ${BodySmallBoldStyles}
  text-decoration: none;
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`
