import type { ISbStoryData } from '@storyblok/react'

export const findInRelations = <T>(
  uuid: string,
  relations: ISbStoryData<T>[]
): ISbStoryData<T> | undefined => {
  if (uuid) {
    return relations.find((relation) => {
      return relation?.uuid === uuid
    })
  }
  return undefined
}
