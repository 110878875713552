import { Divider } from '../../../shared/components/divider'
import { PlayerCard } from '../../../teams/components/player-card'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'

import { StContainer } from './PersonCards.styled'

import type { StoryblokPersonCard } from '../person-card/PersonCard.types'

interface StoryblokPersonCardsProps {
  blok: {
    title?: string
    people?: StoryblokPersonCard[]
  }
}

export const PersonCards = ({ blok }: StoryblokPersonCardsProps) => {
  return (
    <>
      {blok.title && blok.title !== '' && (
        <Divider title={blok.title}></Divider>
      )}
      {blok.people && blok.people.length > 0 ? (
        <StContainer>
          {blok.people.map((person) => (
            <PlayerCard
              key={person._uid}
              first_name={person.first_name}
              last_name={person.last_name}
              image={person.image}
              position={person.job_title}
              country_code={person.country_code}
              playerPage={getHrefFromStoryblokLink(person.detail_page)}
            />
          ))}
        </StContainer>
      ) : null}
    </>
  )
}
