import { StoryblokComponent } from '@storyblok/react'

import { StContainer } from './ArticleWidthSectionContainer.styled'

import type { ISbStoryData } from '@storyblok/react'

type ArticleWidthSectionContainerProps = {
  blok: {
    content: {
      _uid: number
    }[]
  }
  locale?: string
  relations?: ISbStoryData<unknown>[]
}

export const ArticleWidthSectionContainer = ({
  blok,
  relations,
  locale,
}: ArticleWidthSectionContainerProps) => {
  return (
    <StContainer>
      {blok &&
        blok.content &&
        blok.content.length > 0 &&
        blok.content.map((nestedBlok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            locale={locale}
            relations={relations}
          />
        ))}
    </StContainer>
  )
}
