import styled from 'styled-components'

import { Heading3 } from '../../../shared/components/typography'

export const StDeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

export const StCardNumber = styled(Heading3)`
  color: ${({ theme }) => theme.Colors.Primary.Base};
`

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
