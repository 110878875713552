import styled from 'styled-components'

import { BodySmallBold, Heading3 } from '../../../shared/components/typography'

export const StContainer = styled.div`
  overflow: hidden;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space6}`};
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid ${({ theme }) => theme.Colors.Neutrals.Darker};
  align-items: center;
  justify-content: center;

  &&:last-child {
    border-bottom: none;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const StLeftContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;

  @media (min-width: 1200px) {
    width: 300px;
  }
`

export const StCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StMatchday = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
  align-items: center;
  text-align: center;
`
export const StDate = styled(BodySmallBold)`
  opacity: 0.5;

  @media (min-width: 1200px) {
    opacity: 1;
  }
`

export const StTimeContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space2} ${theme.UI.SpacingPx.Space4}`};
`

export const StTime = styled(Heading3)``

export const StScore = styled.span<{ isCercle: boolean }>`
  color: ${({ isCercle, theme }) =>
    isCercle ? theme.Colors.Primary.Base : theme.Colors.Neutrals.White};
`

export const StRightContainer = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};

  @media (min-width: 1200px) {
    margin-top: 0;
    width: 300px;
  }
`
