import { storyblokEditable } from '@storyblok/react'

import { AccountBalance as AccountBalanceComponent } from '../../../mycercle/components/account-balance'

export type AccountBalanceProps = {
  blok: {
    _uid: string
    title: string
    background_image?: {
      filename: string
    }
  }
}

export const AccountBalance = ({ blok }: AccountBalanceProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <AccountBalanceComponent
        title={blok.title}
        backgroundImage={blok.background_image?.filename}
      />
    </div>
  )
}
