import styled from 'styled-components'

import { LoaderGif } from './LoaderGif'

export const StLoaderGif = styled(LoaderGif)<{
  size: 'small' | 'default' | 'large'
}>`
  width: ${({ size }) =>
    size === 'small' ? '100px' : size === 'default' ? '150px' : '200px'};
  height: ${({ size }) =>
    size === 'small' ? '100px' : size === 'default' ? '150px' : '200px'};
`
