import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'

import {
  FooterSitemapContainer,
  FooterSitemapLinksContainer,
  FooterSitemapTitle,
  FooterSitemapColumn,
  FooterSitemapLink,
  FooterSitemapLinkText,
} from './Sitemap.styled'

import type { StoryblokTextLink } from '../../../storyblok/components/text-link/TextLink.types'
import type { StoryblokTextLinksWithTitle } from '../../../storyblok/components/text-links-with-title/TextLinksWithTitle.types'

type FooterSitemapProps = {
  className?: string
  content?: StoryblokTextLinksWithTitle[]
}

export const Sitemap = ({ className, content }: FooterSitemapProps) => {
  return (
    <FooterSitemapContainer className={className}>
      {content &&
        content.length > 0 &&
        content.map((column) => (
          <FooterSitemapColumn key={column._uid}>
            <FooterSitemapTitle>{column.title}</FooterSitemapTitle>
            <FooterSitemapLinksContainer>
              {column.links &&
                column.links.length > 0 &&
                column.links.map((item: StoryblokTextLink) => (
                  <FooterSitemapLink
                    key={item._uid}
                    href={getHrefFromStoryblokLink(item.link)}
                    title={item.link.title}
                  >
                    <FooterSitemapLinkText>{item.name}</FooterSitemapLinkText>
                  </FooterSitemapLink>
                ))}
            </FooterSitemapLinksContainer>
          </FooterSitemapColumn>
        ))}
    </FooterSitemapContainer>
  )
}
