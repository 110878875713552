import { Content, List, Root, Trigger } from '@radix-ui/react-tabs'
import styled from 'styled-components'

import { ButtonLabelStyles } from '../typography'

export const TabsRoot = styled(Root)`
  max-width: 1032px;
  margin: 0 auto;
  margin-top: 40px;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-top: 120px;
  }
`

export const TabsList = styled(List)`
  width: max-content;
  margin-left: 5px;
`
export const TabsTrigger = styled(Trigger)`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Black};
  color: ${({ theme }) => theme.Colors.Components.Button.Primary.Text};
  user-select: none;
  border: 0;

  border-bottom: 2px solid
    ${({ theme }) => theme.Colors.Components.Button.Primary.Fill};

  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  transform: skew(-10deg);
  text-decoration: none;
  cursor: pointer;

  ${ButtonLabelStyles}

  &[data-state='active'] {
    background-color: ${({ theme }) =>
      theme.Colors.Components.Button.Primary.Fill};
  }
`

export const StTabContent = styled.div`
  transform: skew(10deg);
`

export const TabsContent = styled(Content)``
