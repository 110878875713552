import styled from 'styled-components'

import {
  BodyMediumBoldStyles,
  BodyMediumRegular,
  Heading4,
} from '../../../shared/components/typography'

export const StLegendHeading = styled(Heading4)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

export const StLegendTable = styled.table``

export const StLegendRow = styled.tr`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};

  & > td:first-child {
    padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }
`

export const StLegendContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    flex-direction: row;

    gap: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

export const StLegendColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const StLegendItem = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`

export const StPointCell = styled.td`
  width: 40px;
`

export const StLegendPoint = styled.span`
  ${BodyMediumBoldStyles}
  color: ${({ theme }) => theme.Colors.Primary.Base};
`
