import { PageContainer } from '../../../shared/components/page-container'

import {
  StContainer,
  Container,
  Title,
  Subtitle,
  Background,
  Content,
  BackgroundLeftFill,
  StBackgroundGradient,
  StGradientContent,
  StImage,
} from './PageHeader.styled'

import type { StoryblokImage } from 'types'

type PageHeaderProps = {
  className?: string
  title?: string
  subtitle?: string
  image?: StoryblokImage
  marginBottom?: boolean
}

export const PageHeader = ({
  title,
  subtitle,
  image,
  className,
  marginBottom = true,
}: PageHeaderProps) => {
  if (image && image.filename) {
    return (
      <>
        <StContainer $marginBottom={marginBottom} className={className}>
          <StImage sizeDesktop="100vw" image={image} priority />
          {(subtitle || title) && (
            <StBackgroundGradient>
              <PageContainer>
                <StGradientContent>
                  {subtitle && <Subtitle>{subtitle}</Subtitle>}
                  <Title>{title}</Title>
                </StGradientContent>
              </PageContainer>
            </StBackgroundGradient>
          )}
        </StContainer>
      </>
    )
  }
  return (
    <Container $marginBottom={marginBottom} className={className}>
      <BackgroundLeftFill />
      <Background>
        <Content>
          <PageContainer>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            <Title>{title}</Title>
          </PageContainer>
        </Content>
      </Background>
    </Container>
  )
}
