import { SpacingNumeric, SpacingString } from './design-tokens/spacing'
import { theme as generatedTheme } from './design-tokens/theme-generated'

const theme = {
  UI: {
    Breakpoint: {
      Tablet: 680,
      Desktop: 1024,
    },
    BreakpointPx: {
      Tablet: '680px',
      Desktop: '1024px',
    },
    BorderRadius: {
      Small: 4,
      Large: 8,
    },
    BorderRadiusPx: {
      Small: '4px',
      Large: '8px',
    },
    MaxWidth: {
      Article: 1200,
      ArticleImage: 800,
      Page: 1500,
      HighlightBanner: 2000,
    },
    MaxWidthPx: {
      Article: '1200px',
      ArticleImage: '800px',
      Page: '1500px',
      HighlightBanner: '2000px',
    },
    SpacingPx: SpacingString,
    Spacing: SpacingNumeric,
  },

  ...generatedTheme,
}

type Theme = typeof theme

export type { Theme }
export { theme }
