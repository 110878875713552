import type { StoryblokLink } from 'types'

export const getHrefFromStoryblokLink = (link: StoryblokLink | undefined) => {
  if (!link) {
    return ''
  }

  let selectedLink: string

  if (link?.url) {
    selectedLink = link.url
  } else if (link?.cached_url) {
    selectedLink = link.cached_url
  } else {
    return '#'
  }

  if (link.linktype === 'email') {
    return `mailto:${selectedLink}`
  }

  if (selectedLink.startsWith('http') || selectedLink.startsWith('/')) {
    return selectedLink
  }

  return `/${selectedLink}`
}
