import {
  ContactContainer,
  ContactOptionsContainer,
  ContactOption,
  ContactSpacerIcon,
  ContactLink,
} from './Contact.styled'

type FooterContactProps = {
  className?: string
  company_name?: string
  company_address?: string
  contact_email?: string
  contact_phone?: string
}

export const Contact = ({
  className,
  company_name,
  company_address,
  contact_email,
  contact_phone,
}: FooterContactProps) => {
  return (
    <ContactContainer className={className}>
      <ContactOption>{company_name}</ContactOption>
      <ContactOptionsContainer>
        <ContactOption>{company_address}</ContactOption>
        {contact_email && (
          <>
            <ContactSpacerIcon />
            <ContactLink href={`mailto:${contact_email}`} title={'email'}>
              <ContactOption>{contact_email}</ContactOption>
            </ContactLink>
          </>
        )}
        {contact_phone && (
          <>
            <ContactSpacerIcon />
            <ContactLink href={`tel:${contact_phone}`} title={'phone'}>
              <ContactOption>{contact_phone}</ContactOption>
            </ContactLink>
          </>
        )}
      </ContactOptionsContainer>
    </ContactContainer>
  )
}
