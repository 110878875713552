import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

import { StColumn } from './Column.styled'

import type { SbBlokData, ISbStoryData } from '@storyblok/react'

type ColumnStoryblokProps = {
  blok: {
    blocks: SbBlokData[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const Column = ({ blok, locale, relations }: ColumnStoryblokProps) => {
  if (!blok.blocks || blok.blocks.length <= 0) {
    return null
  }

  return (
    <StColumn {...storyblokEditable(blok)}>
      {blok.blocks.map((blok) => (
        <StoryblokComponent
          blok={blok}
          key={blok._uid}
          locale={locale}
          relations={relations}
        />
      ))}
    </StColumn>
  )
}
