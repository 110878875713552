import styled from 'styled-components'

import { Heading2 } from '../typography'

export const StContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const StActionsContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: flex;
    flex-direction: row;
  }
`

export const StHeading2 = styled(Heading2)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`
