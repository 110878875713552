import axiosStatic from 'axios'
import { useContext, useEffect, useState } from 'react'

import { authenticatedUserContext } from '../../authentication/components/authenticated-user-provider'

import type { AxiosInstance } from 'axios'

export const useAxios = () => {
  const { accessToken } = useContext(authenticatedUserContext)
  const [axios, setAxios] = useState<AxiosInstance>()

  useEffect(() => {
    const axiosInstance = axiosStatic.create({
      headers: accessToken ? { authorization: `Bearer ${accessToken}` } : {},
      baseURL: `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}/api`,
    })

    setAxios(() => axiosInstance)
  }, [accessToken])

  return axios
}
