import styled, { css } from 'styled-components'

import { generateTextStyles } from '../../utils/typography-styles'

/* Heading 1 */
export const Heading1Styles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.Heading1)}
`

export const Heading1 = styled.h1`
  ${Heading1Styles}
`

/* Heading 2 */
export const Heading2Styles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.Heading2)}
`

export const Heading2 = styled.h2`
  ${Heading2Styles}
`

/* Heading 3 */
export const Heading3Styles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.Heading3)}
`

export const Heading3 = styled.h3`
  ${Heading3Styles}
`

/* Heading 4 */
export const Heading4Styles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.Heading4)}
`

export const Heading4 = styled.h4`
  ${Heading4Styles}
`

/* Heading 5 */
export const Heading5Styles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.Heading5)}
`

export const Heading5 = styled.h5`
  ${Heading5Styles}
`

/* Body Large Regular */
export const BodyLargeRegularStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodyLargeRegular)}
`

export const BodyLargeRegular = styled.p`
  ${BodyLargeRegularStyles}
`

/* Body Large Bold */
export const BodyLargeBoldStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodyLargeBold)}
`

export const BodyLargeBold = styled.p`
  ${BodyLargeBoldStyles}
`

/* Body Large Link */
export const BodyLargeLinkStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodyLargeLink)}
`

export const BodyLargeLink = styled.a`
  ${BodyLargeLinkStyles}
`

/* Body Medium Regular */
export const BodyMediumRegularStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodyMediumRegular)}
`

export const BodyMediumRegular = styled.p`
  ${BodyMediumRegularStyles}
`

/* Body Medium Bold */
export const BodyMediumBoldStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodyMediumBold)}
`

export const BodyMediumBold = styled.p`
  ${BodyMediumBoldStyles}
`

/* Body Medium Link */
export const BodyMediumLinkStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodyMediumLink)}
`

export const BodyMediumLink = styled.a`
  ${BodyMediumLinkStyles}
`

/* Body Small Regular */
export const BodySmallRegularStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodySmallRegular)}
`

export const BodySmallRegular = styled.p`
  ${BodySmallRegularStyles}
`

/* Body Small Bold */
export const BodySmallBoldStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodySmallBold)}
`

export const BodySmallBold = styled.p`
  ${BodySmallBoldStyles}
`

/* Body Small Link */
export const BodySmallLinkStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.BodySmallLink)}
`

export const BodySmallLink = styled.a`
  ${BodySmallLinkStyles}
`

/* Article Title */
export const ArticleTitleStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.ArticleTitle)}
`

export const ArticleTitle = styled.h3`
  ${ArticleTitleStyles}
`

/* Button Label */
export const ButtonLabelStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.ButtonLabel)}
`

export const ButtonLabel = styled.p`
  ${ButtonLabelStyles}
`

/* Button Label Tertiary */
export const ButtonLabelTertiaryStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.ButtonLabelTertiary)}
`

export const ButtonLabelTertiary = styled.p`
  ${ButtonLabelTertiaryStyles}
`

/* Tag */
export const TagStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.Tag)}
`

export const Tag = styled.p`
  ${TagStyles}
`

/* StatHeading */
export const StatHeadingStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.StatHeading)}
`

export const StatHeading = styled.p`
  ${StatHeadingStyles}
`

/* StatLabel */
export const StatLabelStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.StatLabel)}
`

export const StatLabel = styled.p`
  ${StatLabelStyles}
`

/* StatSubLabel */
export const StatSubLabelStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.StatSubLabel)}
`

export const StatSubLabel = styled.p`
  ${StatSubLabelStyles}
`

/* StatCardNumber */
export const StatCardNumberStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.StatCardNumber)}
`

export const StatCardNumber = styled.p`
  ${StatCardNumberStyles}
`

/* StatCardLabel */
export const StatCardLabelStyles = css`
  ${({ theme }) => generateTextStyles(theme.Typography.StatCardLabel)}
`

export const StatCardLabel = styled.p`
  ${StatCardLabelStyles}
`
