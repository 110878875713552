import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { useState, useCallback, useEffect, Fragment } from 'react'
import { Portal } from 'react-portal'

import { PageContainer } from '../../../shared/components/page-container'

import {
  StContainer,
  StDropdownTitle,
  StDropdownIcon,
  StDropdownItem,
  StDropdownMenu,
  StDropdownColumn,
  StDropdownMenuContainer,
  StDropdownLine,
  StLink,
  StDropdownMenuBackground,
} from './HeaderDropdown.styled'

import type { DropdownItem } from '../../types/DropdownItem.types'

interface HeaderDropdownProps {
  className?: string
  title?: string
  items?: DropdownItem[]
}

export const HeaderDropdown = ({
  className,
  title,
  items,
}: HeaderDropdownProps) => {
  const [isActive, setIsActive] = useState(false)
  const [domLoaded, setDomLoaded] = useState(false)

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  const handleMouseEnter = useCallback(() => {
    if (!isActive) {
      setIsActive(true)
    }
  }, [isActive])

  const handleMouseLeave = useCallback(() => {
    if (isActive) {
      setIsActive(false)
    }
  }, [isActive])

  if (!items || items.length <= 0) {
    return null
  }

  if (items.length === 1 && items[0].links && items[0].links.length === 1) {
    return (
      <StContainer
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StDropdownItem>
          <StLink href={items[0].links[0].link.href}>
            <StDropdownTitle>{title}</StDropdownTitle>
          </StLink>
        </StDropdownItem>
      </StContainer>
    )
  }

  return (
    <>
      <StContainer
        className={className}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StDropdownItem>
          <StDropdownTitle>{title}</StDropdownTitle>
          <StDropdownIcon
            icon={faAngleDown}
            $isActive={isActive}
          ></StDropdownIcon>
        </StDropdownItem>
        {domLoaded && (
          <Portal>
            <StDropdownMenu $isActive={isActive}>
              <PageContainer>
                <StDropdownMenuContainer>
                  {items.map((item, index) => {
                    return (
                      <Fragment key={item.id}>
                        {index === 0 ? null : <StDropdownLine />}
                        <StDropdownColumn isFirst={index === 0}>
                          {item.title && item.title !== '' ? (
                            <StDropdownTitle>{item.title}</StDropdownTitle>
                          ) : null}
                          {item.links &&
                            item.links.length > 0 &&
                            item.links.map((link) => {
                              return (
                                <StLink key={link.id} href={link.link.href}>
                                  {link.title}
                                </StLink>
                              )
                            })}
                        </StDropdownColumn>
                      </Fragment>
                    )
                  })}
                </StDropdownMenuContainer>
              </PageContainer>
            </StDropdownMenu>
          </Portal>
        )}
      </StContainer>
      {domLoaded && (
        <Portal>
          <StDropdownMenuBackground $isActive={isActive} />
        </Portal>
      )}
    </>
  )
}
