import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { CercleCircle } from '../../../shared/components/cercle-circle'
import { Heading4 } from '../../../shared/components/typography'

export const StBackground = styled.div<{ isActive: boolean }>`
  visibility: ${({ isActive }) => (isActive ? 'visible' : 'hidden')};
  opacity: ${({ isActive }) => (isActive ? 0.5 : 0)};
  transition: opacity 0.2s;
  width: 100%;
  min-height: 100vh;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 101;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Black};
`

export const StSidebarHeader = styled.div`
  cursor: pointer;
  height: ${({ theme }) => theme.UI.SpacingPx.Space20};
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  width: calc(100% + ${({ theme }) => theme.UI.SpacingPx.Space10});
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space10};
  margin-left: -${({ theme }) => theme.UI.SpacingPx.Space10};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    height: ${({ theme }) => theme.UI.SpacingPx.Space28};
  }
`

export const StSidebarFooter = styled.div`
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space8} ${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space8} 0`};
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  a {
    img {
      width: ${({ theme }) => theme.UI.SpacingPx.Space6};
      height: ${({ theme }) => theme.UI.SpacingPx.Space6};
    }
  }
`

export const StSidebarTitle = styled(Heading4)``

export const StIcon = styled(FontAwesomeIcon)`
  font-size: 24px;
  color: ${({ theme }) => theme.Colors.Primary.Base};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StSidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space10} 0 ${theme.UI.SpacingPx.Space6} 0`};
  height: 100%;
  max-height: calc(100vh - ${({ theme }) => theme.UI.SpacingPx.Space20});
  overflow-y: auto;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    max-height: calc(100vh - ${({ theme }) => theme.UI.SpacingPx.Space28});
  }
`

export const StContainer = styled.div<{ isActive: boolean }>`
  transform: ${({ isActive }) =>
    isActive ? 'translateX(0)' : 'translateX(100%)'};
  transition: transform 0.2s ease-in-out;
  position: fixed;
  left: auto;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 375px;
  height: 100%;
  max-height: 100vh;

  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  z-index: 102;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    left: 0;
    right: auto;
    transform: ${({ isActive }) =>
      isActive ? 'translateX(0)' : 'translateX(-100%)'};
  }

  &:has(${StSidebarFooter}) {
    ${StSidebarContent} {
      padding-bottom: 0;
      max-height: calc(
        100vh - ${({ theme }) => theme.UI.SpacingPx.Space20} -
          ${({ theme }) => theme.UI.SpacingPx.Space22}
      );

      @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
        max-height: calc(
          100vh - ${({ theme }) => theme.UI.SpacingPx.Space28} -
            ${({ theme }) => theme.UI.SpacingPx.Space22}
        );
      }
    }
  }
`

export const StInnerContainer = styled.div`
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space10};
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const StSidebarItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid ${({ theme }) => theme.Colors.Neutrals.Dark};
`

export const StSidebarItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space5} ${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space5} 0`};

  cursor: pointer;
`

export const StSidebarItemLink = styled.a`
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space5} ${theme.UI.SpacingPx.Space10} ${theme.UI.SpacingPx.Space5} 0`};

  &&:visited,
  &&:link,
  &&:hover,
  &&:active {
    color: ${({ theme }) => theme.Colors.Neutrals.White};
    text-decoration: none;
  }
`

export const StSidebarItemIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
  color: ${({ theme }) => theme.Colors.Neutrals.Medium};
`

export const StSubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

export const StSubMenuItem = styled.a`
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  &&:visited,
  &&:link,
  &&:hover,
  &&:active {
    color: ${({ theme }) => theme.Colors.Neutrals.White};
    text-decoration: none;
  }
`

export const StCercleCircle = styled(CercleCircle)`
  position: absolute;
  width: 1200px;
  height: 1200px;
  right: -780px;
  bottom: -910px;
  z-index: -1;
  opacity: 0.4;
  filter: blur(10px);
`
