import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck'
import { faTrophy } from '@fortawesome/pro-solid-svg-icons/faTrophy'
import { useMemo } from 'react'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { findStat } from '../../utils/findStat'
import { StatsCard } from '../stats-card'
import { StatsDivider } from '../stats-divider'
import { StatsItem } from '../stats-item'

import type { PlayerStat } from 'types'

interface Props {
  className?: string
  stats: PlayerStat[]
}

export const DefensiveStats = ({ stats, className }: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const { tackles, tacklesWon, interceptions, recoveries, duels, duelsWon } =
    useMemo(() => {
      return {
        tackles: findStat('Total Tackles', stats),
        tacklesWon: findStat('Tackles Won', stats),
        interceptions: findStat('Interceptions', stats),
        recoveries: findStat('Recoveries', stats),
        duels: findStat('Duels', stats),
        duelsWon: findStat('Duels won', stats),
      }
    }, [stats])

  return (
    <StatsCard title={t('stats_title_defensive')} className={className}>
      <StatsItem
        stat={tackles}
        statSub={tacklesWon}
        statSubLabel={t('stat_tackles')}
        statLabel={t('stat_tackles_success')}
        icon={faCircleCheck}
      />
      <StatsDivider />
      <StatsItem
        stat={interceptions}
        statSub={recoveries}
        statLabel={t('stat_interceptions')}
        statSubLabel={t('stat_recoveries')}
        icon={faShieldCheck}
      />
      <StatsDivider />
      <StatsItem
        stat={duels}
        statSub={
          duels && duelsWon
            ? `${Math.round((Number(duelsWon) / Number(duels)) * 100)}%`
            : undefined
        }
        statLabel={t('stat_duels')}
        statSubLabel={t('stat_duels_won')}
        icon={faTrophy}
      />
    </StatsCard>
  )
}
