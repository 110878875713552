import {
  StButtonLinkPrimary,
  StButtonLabel,
  StButtonLinkPrimaryDisabled,
  StButtonLinkSecondary,
  StButtonLinkSecondaryDisabled,
  StButtonLinkTertiaryDisabled,
  StButtonLinkTertiary,
  StButtonLabelTeriary,
  StButtonIcon,
  StButtonIconTertiary,
  StButtonLinkContainer,
} from './Button.styled'

import type { ButtonSize, ButtonVariant } from './Button.types'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { AnchorHTMLAttributes, ReactNode } from 'react'

export type ButtonLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant?: ButtonVariant
  size?: ButtonSize
  disabled?: boolean
  prefixIcon?: IconProp
  suffixIcon?: IconProp
  children?: ReactNode | string
  gaEventLabel?: string
}

export const ButtonLink = ({
  variant = 'primary',
  size = 'normal',
  disabled = false,
  prefixIcon,
  suffixIcon,
  href,
  target = '_self',
  children,
  gaEventLabel,
  ...props
}: ButtonLinkProps) => {
  const renderButtonContent = () => {
    if (variant == 'tertiary') {
      return (
        <StButtonLinkContainer size={size}>
          {prefixIcon && <StButtonIconTertiary icon={prefixIcon} />}
          {children && <StButtonLabelTeriary>{children}</StButtonLabelTeriary>}
          {suffixIcon && <StButtonIconTertiary icon={suffixIcon} />}
        </StButtonLinkContainer>
      )
    }
    return (
      <StButtonLinkContainer size={size}>
        {prefixIcon && <StButtonIcon icon={prefixIcon} />}
        {children && <StButtonLabel>{children}</StButtonLabel>}
        {suffixIcon && <StButtonIcon icon={suffixIcon} />}
      </StButtonLinkContainer>
    )
  }

  switch (variant) {
    case 'secondary': {
      return disabled ? (
        <StButtonLinkSecondaryDisabled>
          {renderButtonContent()}
        </StButtonLinkSecondaryDisabled>
      ) : (
        <StButtonLinkSecondary
          href={href ?? '#'}
          target={target}
          data-event-label={gaEventLabel}
          {...props}
        >
          {renderButtonContent()}
        </StButtonLinkSecondary>
      )
    }
    case 'tertiary': {
      return disabled ? (
        <StButtonLinkTertiaryDisabled>
          {renderButtonContent()}
        </StButtonLinkTertiaryDisabled>
      ) : (
        <StButtonLinkTertiary
          href={href ?? '#'}
          target={target}
          data-event-label={gaEventLabel}
          {...props}
        >
          {renderButtonContent()}
        </StButtonLinkTertiary>
      )
    }
    default: {
      return disabled ? (
        <StButtonLinkPrimaryDisabled>
          {renderButtonContent()}
        </StButtonLinkPrimaryDisabled>
      ) : (
        <>
          <StButtonLinkPrimary
            href={href ?? '#'}
            target={target}
            data-event-label={gaEventLabel}
            {...props}
          >
            {renderButtonContent()}
          </StButtonLinkPrimary>
        </>
      )
    }
  }
}
