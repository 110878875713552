import { Heading3 } from '../../../shared/components/typography'

import {
  StContainer,
  StHeaderContainer,
  StTitleIcon,
} from './AccountDataCard.styled'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import type { ReactNode } from 'react'

interface Props {
  className?: string
  children?: ReactNode
  title: string
  icon: IconProp
  textColor?: string
  backrgoundColor?: string
  backgroundImage?: string
}

export const AccountDataCard = ({
  children,
  className,
  title,
  icon,
  textColor,
  backrgoundColor,
  backgroundImage,
}: Props) => {
  return (
    <StContainer
      className={className}
      textColor={textColor}
      backgroundColor={backrgoundColor}
      backgroundImage={backgroundImage}
    >
      <StHeaderContainer>
        <StTitleIcon icon={icon} />
        <Heading3>{title}</Heading3>
      </StHeaderContainer>
      {children}
    </StContainer>
  )
}
