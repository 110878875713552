import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import { Heading1 } from '../../../shared/components/typography'

const SKEW_AMOUNT = 10
const HEIGHT = 510
const TRANSLATE_AMOUNT = Math.round(
  (Math.tan((SKEW_AMOUNT * Math.PI) / 180) * HEIGHT) / 2
)

export const StContainer = styled.div`
  height: ${HEIGHT}px;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space12};
  overflow: hidden;
  width: 100%;
`

export const StHighlightsContainer = styled.div`
  position: relative;
  display: flex;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
  margin-left: -${TRANSLATE_AMOUNT}px;
  margin-right: -${TRANSLATE_AMOUNT}px;
`

export const StHighlight = styled.div`
  cursor: pointer;
  transform: ${() => `skew(-${SKEW_AMOUNT}deg)`};
  overflow: hidden;
  flex: 1;
`

export const StHighlightLink = styled.a<{
  $isActive: boolean
}>`
  cursor: pointer;
  transform: ${() => `skew(-${SKEW_AMOUNT}deg)`};
  overflow: hidden;
  flex: ${({ $isActive }) => ($isActive ? 2 : 1)};

  transition: flex ease 0.4s;

  @media (min-width: 1400px) {
    flex: ${({ $isActive }) => ($isActive ? 3 : 1)};
  }

  &&:visited,
  &&:link,
  &&:hover,
  &&:active {
    color: ${({ theme }) => theme.Colors.Neutrals.White};
    text-decoration: none;
  }
`

export const StImage = styled(StoryblokServiceImage)`
  height: 510px;

  transform: ${() => `skew(${SKEW_AMOUNT}deg) scale(1.4)`};

  @media (min-width: 1500px) {
    transform: ${() => `skew(${SKEW_AMOUNT}deg) scale(1.3)`};
  }

  @media (min-width: 1800px) {
    transform: ${() => `skew(${SKEW_AMOUNT}deg) scale(1.2)`};
  }
`

export const StHighlightOverlay = styled.div<{ $isActive: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Black};
  opacity: ${({ $isActive }) => ($isActive ? 0 : 0.4)};
  transition: opacity 0.1s ease-in-out;
`

export const StHighlightGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 50%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(
      180.04deg,
      rgba(0, 0, 0, 0.2) -66.4%,
      rgba(0, 0, 0, 0) 99.97%
    );
`

export const StHighlightTitle = styled(Heading1)<{
  isFirst: boolean
  isShowing: boolean
}>`
  visibility: ${({ isShowing }) => (isShowing ? 'visible' : 'hidden')};
  opacity: ${({ isShowing }) => (isShowing ? '1' : '0')};
  transition: opacity ease 0.4s 0.4s;
  position: absolute;
  transform: ${() => `skew(${SKEW_AMOUNT}deg)`};
  padding-left: ${({ isFirst }) =>
    isFirst ? 4 * TRANSLATE_AMOUNT : 2 * TRANSLATE_AMOUNT}px;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space20};
`

export const StHighlightTitleShort = styled(Heading1)<{
  isShowing: boolean
}>`
  visibility: ${({ isShowing }) => (isShowing ? 'visible' : 'hidden')};
  opacity: ${({ isShowing }) => (isShowing ? '1' : '0')};
  transition: opacity ease 0.4s 0.4s;
  transform: ${() => `skew(${SKEW_AMOUNT}deg) translate(-50%, 0)`};
  font-size: 24px;
  line-height: 24px;
  position: absolute;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space20};
  left: 50%;
  text-align: center;
`

export const StStaticContainer = styled.div`
  position: relative;
  height: 100%;
`

export const StStaticBanner = styled(StoryblokServiceImage)`
  width: 100%;
  object-fit: cover;
`

export const StStaticTitle = styled(Heading1)`
  position: absolute;
  padding-left: ${2 * TRANSLATE_AMOUNT}px;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space20};
`
