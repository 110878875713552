import { StHamburgerContainer, StHamburgerBar } from './HamburgerIcon.styled'

interface Props {
  className?: string
  handleClick?: () => void
}

export const HamburgerIcon = ({ className, handleClick }: Props) => {
  return (
    <StHamburgerContainer className={className} onClick={handleClick}>
      <StHamburgerBar />
      <StHamburgerBar />
      <StHamburgerBar />
    </StHamburgerContainer>
  )
}
