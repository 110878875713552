import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

export const StDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    max-width: 50%;
  }
`

export const StCardsContainer = styled.div``

export const StCard = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  border-bottom: 1px solid ${({ theme }) => theme.Colors.Neutrals.Lighter};
`

export const StBalanceContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

export const StDeleteIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.Colors.Semantic.Error};
`

export const StDeleteModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

export const StCardNumber = styled.span`
  color: ${({ theme }) => theme.Colors.Primary.Base};
`

export const StButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
