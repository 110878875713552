import { createContext, useContext } from 'react'

import type { ReactNode } from 'react'
import type { StoryblokDatasourceEntry } from 'types'

type TranslationsContextProps = {
  locale: string
  translations: StoryblokDatasourceEntry[]
}

type TranslationsProviderProps = TranslationsContextProps & {
  children: ReactNode
}

export const TranslationsContext = createContext<TranslationsContextProps>({
  locale: 'nl',
  translations: [],
})

export const TranslationsProvider = ({
  children,
  locale,
  translations,
}: TranslationsProviderProps) => {
  return (
    <TranslationsContext.Provider
      value={{
        locale: locale,
        translations: translations,
      }}
    >
      {children}
    </TranslationsContext.Provider>
  )
}

export const useStoryblokTranslationsContext = () => {
  return useContext(TranslationsContext)
}
