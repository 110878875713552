import { StoryblokComponent, storyblokEditable } from '@storyblok/react'

import { SectionHeader as SectionHeaderComponent } from '../../../shared/components/section-header'

import { isSbSectionHeaderData } from './SectionHeader.types'

import type { SbSectionHeaderData } from './SectionHeader.types'
import type { ISbStoryData } from '@storyblok/react'

interface Props {
  blok: SbSectionHeaderData
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const SectionHeader = ({ blok, locale, relations }: Props) => {
  return (
    <div {...storyblokEditable(blok)}>
      {isSbSectionHeaderData(blok) && (
        <SectionHeaderComponent
          title={blok.title}
          actions={
            blok.action[0] ? (
              <StoryblokComponent
                blok={blok.action[0]}
                locale={locale}
                relations={relations}
              />
            ) : undefined
          }
        />
      )}
    </div>
  )
}
