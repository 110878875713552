import { useCallback, useMemo } from 'react'
import { theme } from 'theme'

import { StImage } from './StoryblokServiceImage.styled'

import type { PlaceholderValue } from 'next/dist/shared/lib/get-img-props'
import type { ImageLoader } from 'next/image'
import type { StoryblokImage } from 'types'

interface Props {
  image: StoryblokImage
  className?: string
  width?: number
  height?: number
  priority?: boolean
  sizeMobile?: string
  sizeTablet?: string
  sizeDesktop?: string
  quality?: number
  placeholder?: PlaceholderValue
}

export const StoryblokServiceImage = ({
  image,
  className,
  width,
  height,
  priority,
  quality = 75,
  sizeMobile = '100vw',
  sizeTablet = '100vw',
  sizeDesktop = '55vw',
  placeholder,
}: Props) => {
  const isImageSvg = useMemo(() => {
    return image.filename?.endsWith('.svg') || false
  }, [image.filename])

  const isStoryblokImage = useMemo(() => {
    return image.filename?.includes('https://a.storyblok.com') || false
  }, [image.filename])

  const imageLoader = useCallback<ImageLoader>(
    ({ src, width: loaderWidth, quality }) => {
      if (isImageSvg) {
        return src
      }

      if (isStoryblokImage) {
        return `${src}/m/${loaderWidth}x0/filters:quality(${quality})`
      }
      return src
    },
    [isImageSvg, isStoryblokImage]
  )

  const dimensions = useMemo(() => {
    if (image.filename) {
      const match = image.filename.match(/\/(\d+)x(\d+)\//i)

      const matchWidth = match?.[1]
      const matchHeight = match?.[2]

      if (matchWidth && matchHeight) {
        return { width: Number(matchWidth), height: Number(matchHeight) }
      }
    }

    return { width: 1000, height: 1000 }
  }, [image.filename])

  const focus = useMemo(() => {
    if (image.focus) {
      const match = image.focus.match(/(\d+)x(\d+):/i)

      const matchLeft = match?.[1]
      const matchTop = match?.[2]

      if (matchLeft && matchTop) {
        return { top: Number(matchTop), left: Number(matchLeft) }
      }
    }

    return undefined
  }, [image.focus])

  return image.filename ? (
    <StImage
      priority={priority}
      loader={imageLoader}
      unoptimized={isImageSvg || !isStoryblokImage}
      src={image.filename || ''}
      alt={image.alt || ''}
      height={height ?? dimensions.height}
      width={width ?? dimensions.width}
      dimensions={dimensions}
      quality={quality}
      focus={focus}
      className={className}
      placeholder={placeholder}
      sizes={`(max-width: ${theme.UI.BreakpointPx.Tablet}) ${sizeMobile},
      (max-width: ${theme.UI.BreakpointPx.Desktop}) ${sizeTablet},
      ${sizeDesktop}`}
    />
  ) : null
}
