import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'

export const StContainer = styled.div`
  position: fixed;
  z-index: 100;
  bottom: 0;
  width: 100%;
  height: ${({ theme }) => theme.UI.SpacingPx.Space18};
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};

  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 -8px 30px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 -8px 30px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 -8px 30px;
`

export const StContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet};
  margin: 0 auto;
`

export const StItem = styled.a<{
  $isActive?: boolean
}>`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1} 0;
  color: ${({ $isActive, theme }) =>
    $isActive ? theme.Colors.Primary.Base : theme.Colors.Neutrals.Light};
  filter: ${({ $isActive }) => ($isActive ? 'grayscale(0)' : 'grayscale(1)')};

  &&:visited,
  &&:active,
  &&:link {
    text-decoration: none;
  }
`

export const StHomeLogo = styled(StoryblokServiceImage)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: ${({ theme }) => theme.UI.SpacingPx.Space6};
  object-fit: contain;
`

export const StLabel = styled.p`
  font-size: 11px;
  line-height: 16px;
  text-align: center;
`

export const StIcon = styled(FontAwesomeIcon)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: ${({ theme }) => theme.UI.SpacingPx.Space6};
`

export const StSkewedIcon = styled(FontAwesomeIcon)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: ${({ theme }) => theme.UI.SpacingPx.Space6};
  transform: skew(-10deg);
`

export const StHamburgerContainer = styled.div`
  cursor: pointer;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1} 0;
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`
