import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

import type { ISbStoryData } from '@storyblok/react'

type GridProps = {
  blok: {
    columns: {
      _uid: number
    }[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const Grid = ({ blok, locale, relations }: GridProps) => {
  if (!blok.columns || blok.columns.length <= 0) {
    return null
  }

  return (
    <div className="grid" {...storyblokEditable(blok)}>
      {blok.columns.map((nestedBlok) => (
        <StoryblokComponent
          blok={nestedBlok}
          key={nestedBlok._uid}
          locale={locale}
          relations={relations}
        />
      ))}
    </div>
  )
}
