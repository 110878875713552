import { StBackdrop } from './Modal.styled'

import type { ReactNode } from 'react'

type BackdropProps = {
  onClick: () => void
  children: ReactNode
}

export const Backdrop = ({ onClick, children }: BackdropProps) => {
  return (
    <StBackdrop
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {children}
    </StBackdrop>
  )
}
