import { HighlightBannerCarousel } from '../../../banners/components/highlight-banner-carousel'
import { HighlightBannerSkewed } from '../../../banners/components/highlight-banner-skewed'
import { Loader } from '../../../shared/components/loader'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useStoryblokStories } from '../../hooks/useStoryblokStories'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'

import {
  StContainer,
  StDesktopContainer,
  StLoadingContainer,
  StMobileContainer,
} from './HighlightBanner.styled'

import type { HighlightBannerItem } from '../../../banners/types/HighlightBanner.types'
import type { SbBlokData } from '@storyblok/react'
import type { StoryblokImage, StoryblokLink } from 'types'

interface SbArticleData extends SbBlokData {
  _uid: string
  title: string
  banner?: StoryblokImage
  thumbnail?: StoryblokImage
  languages: string[]
  // Should be extended
}

type StoryblokHighlightBannerItem = {
  _uid: string
  link?: StoryblokLink
  image: StoryblokImage
  full_title?: string
  small_title?: string
}

type StoryblokHighlightBannerProps = {
  blok: {
    _uid: string
    items?: StoryblokHighlightBannerItem[]
  }
}

const normalizeItems = (
  items: StoryblokHighlightBannerItem[]
): HighlightBannerItem[] => {
  return items.map((item) => {
    const itemHref = getHrefFromStoryblokLink(item.link)

    return {
      id: item._uid,
      image: item.image,
      link: {
        href: itemHref,
      },
      full_title: item.full_title,
      small_title: item.small_title,
    }
  })
}

export const HighlightBanner = ({ blok }: StoryblokHighlightBannerProps) => {
  // Get user's locale
  const { locale } = useStoryblokTranslationsContext()

  // Find the storie Id's of every bannerItem
  const itemIds = blok.items?.map((item) => item.link?.id).toString()

  // Get the relevant stories
  const { isLoading, stories } = useStoryblokStories<SbArticleData>({
    by_uuids: itemIds,
  })

  // Loader
  if (isLoading)
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )

  // Filter out articles that are not available in the right laguage
  const items = blok.items?.filter((item) => {
    const story = stories.find((story) => story.uuid === item.link?.id)

    return !!(
      story?.content.component !== 'article_page' ||
      story?.content.languages.includes(locale)
    )
  })

  return (
    <StContainer>
      <StMobileContainer>
        <HighlightBannerCarousel items={normalizeItems(items ?? [])} />
      </StMobileContainer>
      <StDesktopContainer>
        <HighlightBannerSkewed items={normalizeItems(items ?? [])} />
      </StDesktopContainer>
    </StContainer>
  )
}
