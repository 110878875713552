import styled from 'styled-components'

export const StContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
`

export const StName = styled.span`
  color: ${({ theme }) => theme.Colors.Primary.Base};
`
