import { useCallback } from 'react'
import useDefaultSWR from 'swr'

import { useAxios } from './useAxios'

export const useSWR = <T>(path: string) => {
  const axios = useAxios()

  const fetcher = useCallback(
    async (url: string) => axios?.get(url).then((response) => response.data),
    [axios]
  )

  const { ...swrData } = useDefaultSWR<T>(axios ? path : '', fetcher)

  return swrData
}
