import { StContainer } from './Card.styled'

import type { ReactNode } from 'react'

interface Props {
  className?: string
  children?: ReactNode
}

export const Card = ({ children, className }: Props) => {
  return <StContainer className={className}>{children}</StContainer>
}
