import styled from 'styled-components'

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

export const StItem = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex: 0 1
      calc(calc(100% - ${({ theme }) => theme.UI.SpacingPx.Space20}) / 3);
  }
`

export const StLoadingContainer = styled.div`
  height: 800px;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`
