// eslint-disable-next-line no-restricted-imports
import { faLeft, faRight } from '@fortawesome/pro-solid-svg-icons'

import {
  StCurrentPage,
  StPagination,
  StPaginationButton,
  StPaginationButtonTablet,
  StPaginationButtonDesktop,
  StPaginationDivider,
  StPaginationDividerTablet,
  StPaginationDividerDesktop,
  StPaginationIcon,
  StPaginationIconButtonMarginLeft,
  StPaginationIconButtonMarginRight,
  StPaginationDividerMobile,
  StPaginationButtonContainer,
  StPaginationButtonContainerTablet,
  StPaginationButtonContainerDesktop,
  StPaginationButtonContainerMobile,
  StPaginationDividerContainerTablet,
} from './Pagination.styled'

import type { Dispatch, SetStateAction } from 'react'

type PaginationProps = {
  totalItems: number
  itemsPerPage: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
}

export const Pagination = ({
  totalItems,
  itemsPerPage,
  page,
  setPage,
}: PaginationProps) => {
  const handlePaginationClick = (pageNumber: number) => {
    setPage(pageNumber)
    window.scrollTo({
      top: 0,
    })
  }

  return (
    <StPagination>
      {/* Previous page button and first page number */}
      {page > 1 && (
        <>
          <StPaginationIconButtonMarginRight
            onClick={() => handlePaginationClick(page - 1)}
          >
            <StPaginationIcon icon={faLeft} />
          </StPaginationIconButtonMarginRight>
          <StPaginationButtonContainer>
            <StPaginationButton onClick={() => handlePaginationClick(1)}>
              1
            </StPaginationButton>
          </StPaginationButtonContainer>
        </>
      )}
      {/* Triple dots between first page number and current page number, unless on second or third page */}
      {page > 3 && (
        <StPaginationButtonContainer>
          <StPaginationDivider>...</StPaginationDivider>
        </StPaginationButtonContainer>
      )}
      {/* Second page number instead of triple dots, only on third page */}
      {page == 3 && (
        <StPaginationButtonContainer>
          <StPaginationButton onClick={() => handlePaginationClick(2)}>
            2
          </StPaginationButton>
        </StPaginationButtonContainer>
      )}
      {/* Current page number */}
      <StPaginationButtonContainer>
        <StCurrentPage>{page}</StCurrentPage>
      </StPaginationButtonContainer>
      {/* Next page number */}
      {page < Math.ceil(totalItems / itemsPerPage) - 1 && (
        <StPaginationButtonContainer>
          <StPaginationButton onClick={() => handlePaginationClick(page + 1)}>
            {page + 1}
          </StPaginationButton>
        </StPaginationButtonContainer>
      )}
      {/* Next + 1 page number on tablet or desktop */}
      {page < Math.ceil(totalItems / itemsPerPage) - 2 && (
        <StPaginationButtonContainerTablet>
          <StPaginationButtonTablet
            onClick={() => handlePaginationClick(page + 2)}
          >
            {page + 2}
          </StPaginationButtonTablet>
        </StPaginationButtonContainerTablet>
      )}
      {/* Next + 2 page number on desktop */}
      {page < Math.ceil(totalItems / itemsPerPage) - 3 && (
        <StPaginationButtonContainerDesktop>
          <StPaginationButtonDesktop
            onClick={() => handlePaginationClick(page + 3)}
          >
            {page + 3}
          </StPaginationButtonDesktop>
        </StPaginationButtonContainerDesktop>
      )}
      {/* Triple dots between next page number and last page number on mobile, unless next number is last but one */}
      {page < Math.ceil(totalItems / itemsPerPage) - 2 && (
        <StPaginationButtonContainerMobile>
          <StPaginationDividerMobile>...</StPaginationDividerMobile>
        </StPaginationButtonContainerMobile>
      )}
      {/* Triple dots between next + 1 page number and last page number on tablet, unless next number is last but two */}
      {page < Math.ceil(totalItems / itemsPerPage) - 3 && (
        <StPaginationDividerContainerTablet>
          <StPaginationDividerTablet>...</StPaginationDividerTablet>
        </StPaginationDividerContainerTablet>
      )}
      {/* Triple dots between next + 2 page number and last page number on desktop, unless next number is last but three */}
      {page < Math.ceil(totalItems / itemsPerPage) - 4 && (
        <StPaginationButtonContainerDesktop>
          <StPaginationDividerDesktop>...</StPaginationDividerDesktop>
        </StPaginationButtonContainerDesktop>
      )}
      {/* Last page number and next page button*/}
      {page < Math.ceil(totalItems / itemsPerPage) && (
        <>
          <StPaginationButtonContainer>
            <StPaginationButton
              onClick={() =>
                handlePaginationClick(Math.ceil(totalItems / itemsPerPage))
              }
            >
              {Math.ceil(totalItems / itemsPerPage)}
            </StPaginationButton>
          </StPaginationButtonContainer>
          <StPaginationIconButtonMarginLeft
            onClick={() => handlePaginationClick(page + 1)}
          >
            <StPaginationIcon icon={faRight} />
          </StPaginationIconButtonMarginLeft>
        </>
      )}
    </StPagination>
  )
}
