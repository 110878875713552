import styled from 'styled-components'

import {
  BodyMediumBoldStyles,
  BodySmallRegular,
} from '../../../shared/components/typography'

export const StForm = styled.form`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StLabel = styled.label`
  ${BodyMediumBoldStyles}

  display: inline-block;
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StButtonContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space8};
`

export const StInputInfo = styled(BodySmallRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: ${({ theme }) => theme.Colors.Components.Input.Text};
`

export const StError = styled(BodySmallRegular)`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space2};
  color: ${({ theme }) => theme.Colors.Components.Input.ErrorText};
`
