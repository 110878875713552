import { useState, useCallback } from 'react'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { transformDateToAge } from '../../utils/transformDateToAge'

import {
  StContainer,
  StPlayerImageContainer,
  StPlayerImageHover,
  StPlayerImageOverlay,
  StPlayerStat,
  StPlayerSubStat,
  StNameContainer,
  StLastName,
  StFirstName,
  StPlayerCountry,
} from './PlayerCard.styled'

import type { StoryblokImage } from 'types'

type PlayerCardProps = {
  className?: string
  first_name?: string
  last_name?: string
  image?: StoryblokImage
  image_hover?: StoryblokImage
  country_code?: string
  position?: string
  birth_date?: string
  playerPage: string
}

export const PlayerCard = ({
  className,
  first_name,
  last_name,
  image,
  image_hover,
  country_code,
  position,
  birth_date,
  playerPage,
}: PlayerCardProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false)
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const handleMouseEnter = useCallback(() => {
    setIsMouseOver(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsMouseOver(false)
  }, [])

  return (
    <StContainer className={className}>
      <StPlayerImageContainer
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        href={playerPage}
      >
        {image?.filename && (
          <>
            <StoryblokServiceImage
              image={image}
              sizeMobile="50vw"
              sizeTablet="30vw"
              sizeDesktop="25vw"
            />
            {image_hover?.filename && (
              <StPlayerImageHover
                image={image_hover}
                $is_mouse_over={isMouseOver}
                sizeMobile="50vw"
                sizeTablet="30vw"
                sizeDesktop="25vw"
              />
            )}
            {image?.filename && (
              <StPlayerImageOverlay $is_mouse_over={isMouseOver}>
                {country_code && (
                  <StPlayerCountry
                    className={`fi fi-${country_code.toLowerCase()}`}
                  ></StPlayerCountry>
                )}
                {position &&
                (position === 'keeper' ||
                  position === 'defender' ||
                  position === 'midfielder' ||
                  position === 'attacker') ? (
                  <StPlayerStat>{t(position)}</StPlayerStat>
                ) : (
                  <StPlayerStat>{position}</StPlayerStat>
                )}
                {birth_date && (
                  <StPlayerSubStat>{`${transformDateToAge(birth_date)} ${t(
                    'years_old'
                  )}`}</StPlayerSubStat>
                )}
              </StPlayerImageOverlay>
            )}
          </>
        )}
      </StPlayerImageContainer>
      <StNameContainer href={playerPage}>
        <StFirstName>{first_name}</StFirstName>
        <StLastName>{last_name}</StLastName>
      </StNameContainer>
    </StContainer>
  )
}
