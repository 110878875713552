import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck'
import { faGauge } from '@fortawesome/pro-solid-svg-icons/faGauge'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { useMemo } from 'react'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { findStat } from '../../utils/findStat'
import { CardVisual } from '../card-visual'
import { StatsCard } from '../stats-card'
import { StatsDivider } from '../stats-divider'
import { StatsItem } from '../stats-item'

import { StMobileStatsDivider } from './GeneralStats.styled'

import type { ReactNode } from 'react'
import type { PlayerStat } from 'types'

interface Props {
  className?: string
  children?: ReactNode
  stats: PlayerStat[]
  isKeeper?: boolean
}

export const GeneralStats = ({
  className,
  stats = [],
  isKeeper = false,
}: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const {
    gamesPlayed,
    gamesStarted,
    timePlayed,
    yellowCards,
    redCards,
    passes,
    successfulPasses,
    saves,
  } = useMemo(() => {
    return {
      gamesPlayed: findStat('Games Played', stats),
      gamesStarted: findStat('Starts', stats),
      timePlayed: findStat('Time Played', stats),
      yellowCards: findStat('Yellow Cards', stats),
      redCards: findStat('Total Red Cards', stats),
      passes: findStat('Total Passes', stats),
      successfulPasses: findStat(
        'Total Successful Passes ( Excl Crosses & Corners )',
        stats
      ),
      saves: findStat('Saves Made', stats),
    }
  }, [stats])

  return (
    <StatsCard className={className} title={t('stats_title_general')}>
      <StatsItem
        stat={gamesPlayed}
        statSub={gamesStarted}
        statSubLabel={t('stat_games_played')}
        statLabel={t('stat_games_started')}
        icon={faStar}
      />
      <StatsDivider />
      <StatsItem
        stat={timePlayed}
        statSub={
          timePlayed && gamesPlayed
            ? `${Math.floor(Number(timePlayed) / Number(gamesPlayed))}`
            : undefined
        }
        statLabel={t('stat_time_played')}
        statSubLabel={t('stat_time_per_game_played')}
        icon={faGauge}
      />
      <StatsDivider />
      {!isKeeper && (
        <StatsItem
          stat={passes}
          statSub={
            passes && successfulPasses
              ? `${Math.floor(
                  (Number(successfulPasses) / Number(passes)) * 100
                )}%`
              : undefined
          }
          statLabel={t('stat_passes')}
          statSubLabel={t('stat_successful_passes')}
          icon={faCircleCheck}
        />
      )}
      {isKeeper && <StatsItem stat={saves} statLabel={t('stat_saves')} />}
      <StMobileStatsDivider />
      <CardVisual
        cardType="yellow"
        amount={yellowCards ? Number(yellowCards) : undefined}
      >
        {t('stat_yellow_card')}
      </CardVisual>
      <StatsDivider />
      <CardVisual
        cardType="red"
        amount={redCards ? Number(redCards) : undefined}
      >
        {t('stat_red_card')}
      </CardVisual>
    </StatsCard>
  )
}
