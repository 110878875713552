import { StoryblokComponent } from '@storyblok/react'

import { StContainer } from './MaxWidthSectionContainer.styled'

import type { ISbStoryData } from '@storyblok/react'

type MaxWidthSectionContainerProps = {
  blok: {
    content: {
      _uid: number
    }[]
  }
  locale?: string
  relations?: ISbStoryData<unknown>[]
}

export const MaxWidthSectionContainer = ({
  blok,
  locale,
  relations,
}: MaxWidthSectionContainerProps) => {
  return (
    <StContainer>
      {blok &&
        blok.content &&
        blok.content.length > 0 &&
        blok.content.map((nestedBlok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            relations={relations}
            locale={locale}
          />
        ))}
    </StContainer>
  )
}
