import styled from 'styled-components'

import {
  BodySmallBold,
  BodySmallRegular,
} from '../../../shared/components/typography'

export const FooterSitemapContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10} 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${({ theme }) => theme.Colors.Neutrals.Darker};
  }
`

export const FooterSitemapColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: flex-start;
`

export const FooterSitemapTitle = styled(BodySmallBold)``

export const FooterSitemapLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const FooterSitemapLink = styled.a`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-decoration: none;
`

export const FooterSitemapLinkText = styled(BodySmallRegular)``

export const FooterSitemapDivder = styled.hr`
  border-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  border-style: solid;
  border-width: 1px;
  margin: 0;
`
