import { storyblokEditable } from '@storyblok/react'

import { useUser } from '../../../authentication/hooks/useUser'
import { AccountData as AccountDataComponent } from '../../../mycercle/components/account-data'

export type AccountDataProps = {
  blok: {
    _uid: string
    title: string
    firstname_label: string
    lastname_label: string
    email_label: string
    birthdate_label: string
  }
}

export const AccountData = ({ blok }: AccountDataProps) => {
  const {
    title,
    firstname_label,
    lastname_label,
    email_label,
    birthdate_label,
  } = blok
  const { user, idTokenClaims } = useUser()

  return (
    <div {...storyblokEditable(blok)}>
      <AccountDataComponent
        title={title}
        firstnameLabel={firstname_label}
        firstnameValue={user?.firstName}
        lastnameLabel={lastname_label}
        lastnameValue={user?.lastName}
        emailLabel={email_label}
        emailValue={idTokenClaims?.email}
        birthdateLabel={birthdate_label}
        birthdateValue={user?.birthDate}
      />
    </div>
  )
}
