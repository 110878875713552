import type { StoryblokDatasourceEntry } from 'types'

const findInTranslations = (
  key: string,
  translations: StoryblokDatasourceEntry[]
) => {
  if (translations.length <= 0) {
    return ''
  }
  const translation = translations.find(
    (translation) => translation.name === key
  )
  return translation?.dimension_value ?? translation?.value ?? ''
}

export const useTranslations = (translations: StoryblokDatasourceEntry[]) => {
  return {
    t: (key: string) => findInTranslations(key, translations) || key,
  }
}
