import styled from 'styled-components'

import { SpacerIcon } from '../../../shared/components/spacer-icon'
import { BodySmallRegular } from '../../../shared/components/typography'

export const ContactContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    margin-bottom: 0;
  }
`

export const ContactOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  }
`

export const ContactCompanyName = styled(BodySmallRegular)`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const ContactOption = styled(BodySmallRegular)``

export const ContactLink = styled.a`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-decoration: none;
`

export const ContactSpacerIcon = styled(SpacerIcon)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`
