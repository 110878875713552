import styled from 'styled-components'

import { Heading2 } from '../typography'

export const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const DividerLine = styled.hr`
  border-top: 1px solid #eee;
  flex: 1;
`

export const DividerTitle = styled(Heading2)`
  padding-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`
