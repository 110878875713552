import styled from 'styled-components'

export const StHamburgerContainer = styled.div`
  cursor: pointer;
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: ${({ theme }) => theme.UI.SpacingPx.Space6};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1} 0;
  transform: skew(-10deg);

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    width: ${({ theme }) => theme.UI.SpacingPx.Space8};
    height: ${({ theme }) => theme.UI.SpacingPx.Space8};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space2} 0;
  }
`

export const StHamburgerBar = styled.div`
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: 2px;
  background: ${({ theme }) => theme.Colors.Neutrals.Light};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    background: ${({ theme }) => theme.Colors.Neutrals.White};
    height: 3px;
    width: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`
