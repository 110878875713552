import {
  StTabContent,
  TabsContent,
  TabsList,
  TabsRoot,
  TabsTrigger,
} from './Tabs.styled'

import type { Dispatch, ReactNode, SetStateAction } from 'react'

export type TabsProps<O = string> = {
  tabs: { label: string; key: O; content: ReactNode }[]
  currentTab: O
  onChange: Dispatch<SetStateAction<O>>
}

export const Tabs = <O extends string>({
  tabs,
  currentTab,
  onChange,
}: TabsProps<O>) => (
  <TabsRoot
    defaultValue={tabs[0].key}
    value={currentTab}
    onValueChange={(value) => onChange(value as O)}
  >
    <TabsList aria-label="Manage your account">
      {tabs.map(({ label, key }) => (
        <TabsTrigger key={key} value={key}>
          <StTabContent>{label}</StTabContent>
        </TabsTrigger>
      ))}
    </TabsList>

    {tabs.map(({ key, content }) => (
      <TabsContent key={key} value={key}>
        {content}
      </TabsContent>
    ))}
  </TabsRoot>
)
