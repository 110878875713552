import { motion } from 'framer-motion'
import styled from 'styled-components'

import { Button } from '../button'

export const StBackdrop = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000e1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  z-index: 1000;
`

export const StModalContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  max-width: 560px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.Colors.Neutrals.Darker};
`

export const StModalContent = styled.div`
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
`

export const StModalCloseButton = styled(Button)`
  position: absolute;
  top: -40px;
  right: 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    top: -50px;
  }
`
