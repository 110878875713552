import Image from 'next/image'
import styled from 'styled-components'

export const StImage = styled(Image)<{
  focus?: { top: number; left: number }
  dimensions?: { height: number; width: number }
}>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  ${({ focus, dimensions }) =>
    focus && dimensions
      ? `object-position: calc(${focus.left / dimensions.width} * 100%) calc(${
          focus.top / dimensions.height
        } * 100%)`
      : 'object-position: center'};
`
