import styled from 'styled-components'

import {
  BodyMediumBold,
  BodySmallRegular,
} from '../../../shared/components/typography'

export const StContainer = styled.div``

export const StDataLabel = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StDataValue = styled(BodyMediumBold)``
