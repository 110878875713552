import styled from 'styled-components'

export const StContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 0;
  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: 420px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`
