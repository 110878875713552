interface SpacerIconProps {
  className?: string
}

export const SpacerIcon = ({ className }: SpacerIconProps) => {
  return (
    <svg
      className={className}
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7203 0L0 4.93984L4.5 8L9 4.93984L7.2797 0H1.7203Z"
        fill="#60B22C"
      />
    </svg>
  )
}
