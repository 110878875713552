import styled from 'styled-components'

import { BodyMediumBold } from '../typography'

export const StRadioButtonLabel = styled.label`
  display: flex;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};
`

export const StLabelText = styled(BodyMediumBold)<{ selected: boolean }>`
  color: ${({ theme, selected }) =>
    selected ? theme.Colors.Primary.Base : theme.Colors.Neutrals.White};
`
export const StLabelContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const StRadioButton = styled.input`
  appearance: none;
  background-color: ${({ theme }) => theme.Colors.Neutrals.White};
  font: inherit;
  color: currentColor;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em ${({ theme }) => theme.Colors.Primary.Base};
  }
  &:checked::before {
    transform: scale(1);
  }
`
