import { storyblokEditable } from '@storyblok/react'

import { useUser } from '../../../authentication/hooks/useUser'
import { AccountWelcome as AccountWelcomeComponent } from '../../../mycercle/components/account-welcome'

export type AccountWelcomeProps = {
  blok: {
    _uid: string
    welcome_message: string
  }
}

export const AccountWelcome = ({ blok }: AccountWelcomeProps) => {
  const { user } = useUser()

  return (
    <div {...storyblokEditable(blok)}>
      <AccountWelcomeComponent
        message={blok.welcome_message}
        name={user?.firstName}
      />
    </div>
  )
}
