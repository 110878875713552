import { Button } from '../../../shared/components/button'
import { Heading3 } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StButtonContainer,
  StCardNumber,
  StDeleteModalContainer,
} from './AccountCardDelete.styled'

import type { MyCercleCard } from '../../types/myCercleCards'

interface AccountCardDeleteProps {
  card: MyCercleCard | undefined
  onCancel: () => void
  onConfirm: () => void
}

export const AccountCardDelete = ({
  card,
  onCancel,
  onConfirm,
}: AccountCardDeleteProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  return (
    <StDeleteModalContainer>
      <Heading3>{t('account_card_delete_message')}</Heading3>
      <StCardNumber>{card?.number}</StCardNumber>
      <StButtonContainer>
        <Button
          variant="secondary"
          onClick={onCancel}
          gaEventLabel="press_cancel_delete_card"
        >
          {t('account_card_delete_cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          gaEventLabel="press_delete_card"
        >
          {t('account_card_delete_confirm')}
        </Button>
      </StButtonContainer>
    </StDeleteModalContainer>
  )
}
