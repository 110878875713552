import { useState, useCallback } from 'react'

import { HeaderDesktop } from '../../../navigation/components/header-desktop'
import { HeaderMobile } from '../../../navigation/components/header-mobile'
import { Sidebar } from '../../../navigation/components/sidebar'
import { useStoryblokTranslationsContext } from '../../context/translations-provider'
import { useTranslations } from '../../hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'

import {
  StContainer,
  StNavigationMobile,
  StNavigationDesktop,
} from './Navigation.styled'

import type { StoryblokNavigation } from './Navigation.types'
import type { DropdownItem } from '../../../navigation/types/DropdownItem.types'
import type { SbButtonProps } from '../button/Button.types'
import type { StoryblokTextLink } from '../text-link/TextLink.types'
import type { StoryblokTextLinksWithTitle } from '../text-links-with-title/TextLinksWithTitle.types'
import type { LinkTarget } from 'types'

const normalizeActions = (actions?: SbButtonProps[]) => {
  if (actions && actions.length > 0) {
    return actions.map((action) => {
      return {
        id: action._uid,
        icon: action.prefix_icon,
        label: action.label,
        link: {
          href: getHrefFromStoryblokLink(action.link),
        },
      }
    })
  }
  return []
}

const normalizeDropdownLinks = (links?: StoryblokTextLink[]) => {
  if (links && links.length > 0) {
    return links.map((textLink: StoryblokTextLink) => {
      return {
        id: textLink._uid,
        title: textLink.name,
        link: {
          href: getHrefFromStoryblokLink(textLink.link) ?? '',
          target: (textLink.link.target ?? '_self') as LinkTarget,
        },
      }
    })
  }
  return []
}

const normalizeDropdownItems = (
  items?: StoryblokTextLinksWithTitle[]
): DropdownItem[] => {
  if (items && items.length > 0) {
    return items.map((item: StoryblokTextLinksWithTitle) => {
      return {
        id: item._uid,
        title: item.title,
        links: normalizeDropdownLinks(item.links),
      }
    })
  }
  return []
}

const normalizeOtherDropdownItems = (
  items?: (StoryblokTextLinksWithTitle & { show: boolean })[]
): DropdownItem[] => {
  if (items && items.length > 0) {
    return items.map((item) => {
      return {
        id: item._uid,
        title: item.title,
        links: normalizeDropdownLinks(item.links),
        show: item.show,
      }
    })
  }
  return []
}

interface NavigationProps {
  blok?: StoryblokNavigation
}

export const Navigation = ({ blok }: NavigationProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const handleSidebarToggle = useCallback(() => {
    setIsSidebarOpen((previous) => !previous)
  }, [])

  return (
    <StContainer>
      <StNavigationMobile>
        <HeaderMobile
          homeLogo={blok?.home_logo}
          homeLink={{
            href: getHrefFromStoryblokLink(blok?.home_link) ?? '',
          }}
          homeLabel={t('home') ?? 'Home'}
          menuLabel={t('menu') ?? 'Menu'}
          mainLinks={normalizeActions(blok?.primary_actions) ?? []}
          handleSidebarToggle={handleSidebarToggle}
        />
      </StNavigationMobile>
      <StNavigationDesktop>
        <HeaderDesktop
          homeLogo={blok?.home_logo}
          homeLink={{
            href: getHrefFromStoryblokLink(blok?.home_link) ?? '',
          }}
          buttons={blok?.primary_actions ?? []}
          teamsDropdownItems={normalizeDropdownItems(blok?.header_teams_links)}
          otherDropdownItems={normalizeOtherDropdownItems(blok?.header_links)}
          handleSidebarToggle={handleSidebarToggle}
          teamsLabel={t('teams') ?? 'Teams'}
        />
        <Sidebar
          isActive={isSidebarOpen}
          handleSidebarToggle={handleSidebarToggle}
          closeLabel={t('close') ?? 'Sluiten'}
          backLabel={t('back') ?? 'Terug'}
          teamsLabel={t('teams') ?? 'Teams'}
          teamsDropdownItems={normalizeDropdownItems(blok?.header_teams_links)}
          otherDropdownItems={normalizeOtherDropdownItems(blok?.header_links)}
          socialLinks={blok?.social_links ?? []}
        />
      </StNavigationDesktop>
    </StContainer>
  )
}
