import styled from 'styled-components'

import { Card } from '../../../shared/components/card'

export const StCard = styled(Card)`
  flex-direction: column;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

export const StStatsRow = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`
