import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose'
import { AnimatePresence } from 'framer-motion'
import { useEffect } from 'react'
import { Portal } from 'react-portal'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import { Backdrop } from './Backdrop'
import {
  StModalCloseButton,
  StModalContainer,
  StModalContent,
} from './Modal.styled'

import type { ReactNode } from 'react'

const dropIn = {
  hidden: {
    y: '-100vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
    transition: {
      duration: 0.1,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: '100vh',
    opacity: 0,
  },
}

interface ModalProps {
  open: boolean
  onClose: () => void
  children?: ReactNode
}

export const Modal = ({ open, onClose, children }: ModalProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const handleClose = () => {
    onClose()
  }

  useEffect(() => {
    if (open) {
      document.body.classList.add('overlay-open')
    } else {
      document.body.classList.remove('overlay-open')
    }
  }, [open])

  return (
    <Portal>
      <AnimatePresence>
        {open ? (
          <>
            <Backdrop onClick={handleClose}>
              <StModalContainer
                onClick={(event) => event.stopPropagation()}
                variants={dropIn}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <StModalCloseButton
                  variant="tertiary"
                  gaEventLabel="press_close_my_cercle_card_modal"
                  prefixIcon={faClose}
                  onClick={handleClose}
                >
                  {t('modal_close')}
                </StModalCloseButton>
                <StModalContent>{children}</StModalContent>
              </StModalContainer>
            </Backdrop>
          </>
        ) : null}
      </AnimatePresence>
    </Portal>
  )
}
