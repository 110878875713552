import {
  StContainer,
  StIcon,
  StStat,
  StStatSubLabel,
  StStatLabel,
  StStatContainer,
} from './StatsItem.styled'

import type { IconProp } from '@fortawesome/fontawesome-svg-core'

interface Props {
  className?: string

  stat?: string
  statLabel: string
  statSub?: string
  statSubLabel?: string

  icon?: IconProp
}

export const StatsItem = ({
  className,
  stat = '-',
  statLabel,
  statSub = '-',
  statSubLabel,
  icon,
}: Props) => {
  return (
    <StContainer className={className}>
      <StStatContainer>
        <StStat>{stat}</StStat>
        <StStatLabel>{statLabel}</StStatLabel>
      </StStatContainer>
      {(icon || statSubLabel) && (
        <StStatSubLabel>
          {icon && <StIcon icon={icon} />}
          {statSub} {statSubLabel}
        </StStatSubLabel>
      )}
    </StContainer>
  )
}
