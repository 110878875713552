import {
  StActionsContainer,
  StContainer,
  StHeading2,
} from './SectionHeader.styled'

import type { ReactNode } from 'react'

interface Props {
  className?: string
  actions?: ReactNode
  title: string
}

export const SectionHeader = ({ className, actions, title }: Props) => {
  return (
    <StContainer className={className}>
      <StHeading2>{title}</StHeading2>
      {actions && <StActionsContainer>{actions}</StActionsContainer>}
    </StContainer>
  )
}
