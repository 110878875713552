import styled, { css } from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  Heading1,
  BodySmallRegular,
} from '../../../shared/components/typography'
import { hypeFont } from '../../../shared/fonts/hype'

const SKEW_AMOUNT = 10
const IMAGE_HEIGHT = 450
const TRANSLATE_AMOUNT = 56
const IMAGE_TRANSLATE_AMOUNT = Math.round(
  (Math.tan((SKEW_AMOUNT * Math.PI) / 180) * IMAGE_HEIGHT) / 2
)
const GRADIENT_HEIGHT = 132
const GRADIENT_TRANSLATE_AMOUNT = Math.round(
  (Math.tan((SKEW_AMOUNT * Math.PI) / 180) * GRADIENT_HEIGHT) / 2
)

export const StContainer = styled.div<{ $marginBottom?: boolean }>`
  width: 100%;
  overflow-x: hidden;
  height: 180px;
  position: relative;
  margin-bottom: ${({ theme, $marginBottom }) =>
    $marginBottom ? theme.UI.SpacingPx.Space20 : 0};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    height: ${IMAGE_HEIGHT}px;
    transform: ${() =>
      `skew(-${SKEW_AMOUNT}deg) translate(-${IMAGE_TRANSLATE_AMOUNT}px, 0)`};
    overflow: hidden;
    margin-left: -2rem;
  }
`

export const StImage = styled(StoryblokServiceImage)`
  max-height: 450px;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    transform: ${() =>
      `skew(${SKEW_AMOUNT}deg) translate(${IMAGE_TRANSLATE_AMOUNT}px, 0)`};
  }
`

export const StBackgroundGradient = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${GRADIENT_HEIGHT / 2}px;
  background-image: ${({ theme }) =>
    `linear-gradient(to right, ${theme.Colors.Neutrals.Darker}, transparent)`};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    height: ${GRADIENT_HEIGHT}px;
    transform: ${() =>
      `skew(${SKEW_AMOUNT}deg) translate(${
        8 * GRADIENT_TRANSLATE_AMOUNT
      }px, 0)`};
  }
`

export const StGradientContent = styled.div`
  height: ${GRADIENT_HEIGHT / 2}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    height: ${GRADIENT_HEIGHT}px;
  }
`

export const Container = styled.div<{ $marginBottom?: boolean }>`
  width: 100%;
  overflow-x: hidden;
  position: relative;
  height: auto;
  margin-bottom: ${({ theme, $marginBottom }) =>
    $marginBottom ? theme.UI.SpacingPx.Space20 : 0};
`

export const BackgroundLeftFill = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
`

export const Background = styled.div`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.Page};
  transform: ${() =>
    `skew(-${SKEW_AMOUNT}deg) translate(-${TRANSLATE_AMOUNT}px, 0)`};
  background-color: ${({ theme }) =>
    css`
      ${theme.Colors.Neutrals.Dark}
    `};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;
`

export const Content = styled.div`
  transform: ${() =>
    `skew(${SKEW_AMOUNT}deg) translate(${TRANSLATE_AMOUNT}px, 0)`};
`

export const ImageContent = styled.div`
  transform: ${() =>
    `skew(${SKEW_AMOUNT}deg) translate(${2 * IMAGE_TRANSLATE_AMOUNT}px, 0)`};
`

export const Title = styled(Heading1)`
  ${() =>
    css`
      ${hypeFont.style}
    `}
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-transform: uppercase;
`

export const Subtitle = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  padding-left: ${({ theme }) => theme.UI.SpacingPx.Space1};
`
