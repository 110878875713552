import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled, { css } from 'styled-components'
import { theme } from 'theme'

import { ButtonLabel, ButtonLabelTertiary } from '../typography'

const primaryStyle = css`
  display: inline-block;
  background-color: ${theme.Colors.Components.Button.Primary.Fill};
  color: ${theme.Colors.Components.Button.Primary.Text};
  transform: skew(-10deg);
  width: fit-content;
  text-decoration: none;
  transition: 0.2s ease;
  padding: 0;
  margin: 0;

  &:hover {
    background-color: ${theme.Colors.Components.Button.Primary.HoverFill};
    color: ${theme.Colors.Components.Button.Primary.HoverText};
    transition: 0.2s ease;
    cursor: pointer;
  }

  &:active {
    background-color: ${theme.Colors.Components.Button.Primary.ActiveFill};
    color: ${theme.Colors.Components.Button.Primary.ActiveText};
    transition: 0.2s ease;
  }
`

const primaryDisabledStyle = css`
  background-color: ${theme.Colors.Components.Button.Primary.DisabledFill};
  color: ${theme.Colors.Components.Button.Primary.DisabledText};
  cursor: not-allowed;

  &:hover {
    background-color: ${theme.Colors.Components.Button.Primary.DisabledFill};
    color: ${theme.Colors.Components.Button.Primary.DisabledText};
    cursor: not-allowed;
  }

  &:active {
    background-color: ${theme.Colors.Components.Button.Primary.DisabledFill};
    color: ${theme.Colors.Components.Button.Primary.DisabledText};
  }
`

const secondaryStyle = css`
  background-color: ${theme.Colors.Components.Button.Secondary.Fill};
  color: ${theme.Colors.Components.Button.Secondary.Text};

  &:hover {
    background-color: ${theme.Colors.Components.Button.Secondary.HoverFill};
    color: ${theme.Colors.Components.Button.Secondary.HoverText};
  }

  &:active {
    background-color: ${theme.Colors.Components.Button.Secondary.ActiveFill};
    color: ${theme.Colors.Components.Button.Secondary.ActiveText};
  }
`

const secondaryDisabledStyle = css`
  background-color: ${theme.Colors.Components.Button.Secondary.DisabledFill};
  color: ${theme.Colors.Components.Button.Secondary.DisabledText};

  &:hover {
    background-color: ${theme.Colors.Components.Button.Secondary.DisabledFill};
    color: ${theme.Colors.Components.Button.Secondary.DisabledText};
  }

  &:active {
    background-color: ${theme.Colors.Components.Button.Secondary.DisabledFill};
    color: ${theme.Colors.Components.Button.Secondary.DisabledText};
  }
`

const tertiaryStyle = css`
  background-color: ${theme.Colors.Components.Button.Tertiary.Fill};
  color: ${theme.Colors.Components.Button.Tertiary.Text};

  &:hover {
    background-color: ${theme.Colors.Components.Button.Tertiary.HoverFill};
    color: ${theme.Colors.Components.Button.Tertiary.HoverText};
  }

  &:active {
    background-color: ${theme.Colors.Components.Button.Tertiary.ActiveFill};
    color: ${theme.Colors.Components.Button.Tertiary.ActiveText};
  }
`

const tertiaryDisabledStyle = css`
  background-color: ${theme.Colors.Components.Button.Tertiary.DisabledFill};
  color: ${theme.Colors.Components.Button.Tertiary.DisabledText};

  &:hover {
    background-color: ${theme.Colors.Components.Button.Tertiary.DisabledFill};
    color: ${theme.Colors.Components.Button.Tertiary.DisabledText};
  }

  &:active {
    background-color: ${theme.Colors.Components.Button.Tertiary.DisabledFill};
    color: ${theme.Colors.Components.Button.Tertiary.DisabledText};
  }
`

export const StButtonIcon = styled(FontAwesomeIcon)`
  width: ${theme.UI.Spacing.Space4};
  transition: 0.2s ease;
`

export const StButtonIconTertiary = styled(StButtonIcon)`
  color: ${theme.Colors.Components.Button.Tertiary.Icon};
`

export const StButtonLabel = styled(ButtonLabel)`
  display: inline-block;
`

export const StButtonLabelTeriary = styled(ButtonLabelTertiary)`
  display: inline-block;
  transition: 0.2s ease;
`

export const StButtonLinkContainer = styled.div<{
  size: 'small' | 'normal'
}>`
  min-width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ size }) =>
    size == 'small' ? theme.UI.SpacingPx.Space2 : theme.UI.SpacingPx.Space3};
  padding: ${({ size }) =>
    size == 'small' ? theme.UI.SpacingPx.Space2 : theme.UI.SpacingPx.Space3};
  background-color: transparent;
  width: fit-content;
  transform: skew(10deg);

  @media (min-width: ${theme.UI.BreakpointPx.Desktop}) {
    padding: ${({ size }) =>
      size == 'small'
        ? theme.UI.SpacingPx.Space2
        : `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space3}`};
  }
`
export const StButtonContainer = styled.div<{
  size: 'small' | 'normal'
}>`
  min-width: 100%;
  display: flex;
  justify-content: center;
  padding: ${({ size }) =>
    size == 'small' ? theme.UI.SpacingPx.Space2 : theme.UI.SpacingPx.Space3};
  background-color: transparent;
  width: fit-content;
  transform: skew(10deg);

  @media (min-width: ${theme.UI.BreakpointPx.Desktop}) {
    padding: ${({ size }) =>
      size == 'small'
        ? theme.UI.SpacingPx.Space2
        : `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space3}`};
  }
`

export const StButtonLinkPrimary = styled.a`
  ${primaryStyle}
`

export const StButtonLinkPrimaryDisabled = styled.div`
  ${primaryStyle}
  ${primaryDisabledStyle}
`

export const StButtonLinkSecondary = styled(StButtonLinkPrimary)`
  ${secondaryStyle}
`

export const StButtonLinkSecondaryDisabled = styled(
  StButtonLinkPrimaryDisabled
)`
  ${secondaryDisabledStyle}
`

export const StButtonLinkTertiary = styled(StButtonLinkPrimary)`
  ${tertiaryStyle}

  &:hover {
    ${StButtonIconTertiary} {
      color: ${theme.Colors.Components.Button.Tertiary.HoverIcon};
    }
  }

  &:active {
    ${StButtonIconTertiary} {
      color: ${theme.Colors.Components.Button.Tertiary.ActiveIcon};
    }
  }
`

export const StButtonLinkTertiaryDisabled = styled(StButtonLinkPrimaryDisabled)`
  ${tertiaryDisabledStyle}

  ${StButtonIconTertiary} {
    color: ${theme.Colors.Components.Button.Tertiary.DisabledIcon};
  }
`

export const StButtonPrimary = styled.button`
  border: none;
  ${primaryStyle}

  &:disabled {
    ${primaryDisabledStyle}
  }
`

export const StButtonSecondary = styled(StButtonPrimary)`
  ${secondaryStyle}

  &:disabled {
    ${secondaryDisabledStyle}
  }
`

export const StButtonTertiary = styled(StButtonPrimary)`
  ${tertiaryStyle}

  &:hover {
    ${StButtonIconTertiary} {
      color: ${theme.Colors.Components.Button.Tertiary.HoverIcon};
    }
  }

  &:active {
    ${StButtonIconTertiary} {
      color: ${theme.Colors.Components.Button.Tertiary.ActiveIcon};
    }
  }

  &:disabled {
    ${tertiaryDisabledStyle}
    ${StButtonIconTertiary} {
      color: ${theme.Colors.Components.Button.Tertiary.DisabledIcon};
    }
  }
`
export const StButtonContentContainer = styled.div<{
  loading: boolean
  size: 'small' | 'normal'
}>`
  display: flex;
  align-items: center;
  gap: ${({ size }) =>
    size == 'small' ? theme.UI.SpacingPx.Space2 : theme.UI.SpacingPx.Space3};
  visibility: ${({ loading }) => (loading ? 'hidden' : 'visible')};
`

export const StButtonLoading = styled.div<{ loading: boolean }>`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  visibility: ${({ loading }) => (loading ? 'visible' : 'hidden')};
`
