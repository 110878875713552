import styled from 'styled-components'
import { theme } from 'theme'

import { StoryblokServiceImage } from '../storyblok-service-image'
import { BodyMediumRegular, Heading2 } from '../typography'

export const StOuterCard = styled.div<{
  useColor: boolean
  firstColor?: string
  useGradient: boolean
  secondColor?: string
  gradientAngle?: number
  isSquare?: boolean
}>`
  ${({ useColor, firstColor, useGradient, secondColor, gradientAngle }) =>
    useColor
      ? useGradient
        ? `background: linear-gradient(${
            gradientAngle || 0
          }deg, ${firstColor}, ${secondColor})`
        : `background-color: ${firstColor}`
      : `background-color: ${theme.Colors.Primary.Base}`};

  position: relative;

  ${({ isSquare = false, theme }) =>
    isSquare
      ? `
  aspect-ratio: 1 / 1;
  `
      : `
   height: 220px;
  @media screen and (min-width: ${theme.UI.BreakpointPx.Tablet}) {
    height: 350px;
  }
  @media screen and (min-width: ${theme.UI.BreakpointPx.Desktop}) {
    height: 400px;
  }
  `}
`

export const StCard = styled.div<{ hasOverlay: boolean }>`
  background-color: transparent;
  padding: ${({ theme }) => theme.UI.Spacing.Space6}px;
  height: 100%;
  width: 100%;

  position: relative;
  z-index: 4;

  ${({ hasOverlay, theme }) =>
    hasOverlay
      ? `background: linear-gradient(
    ${theme.Colors.Neutrals.Darkest}20,
    ${theme.Colors.Neutrals.Darkest}80
  );`
      : ''}

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.UI.Spacing.Space4}px;

  @media screen and (min-width: 480px) {
    padding: ${({ theme }) => theme.UI.Spacing.Space10}px;
  }
`

export const StCardTitle = styled(Heading2)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`

export const StCardSubtitle = styled(BodyMediumRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`

export const StBackgroundImage = styled(StoryblokServiceImage)`
  position: absolute;

  height: 100%;
  width: 100%;

  z-index: 1;
`
