import { faFutbol } from '@fortawesome/pro-solid-svg-icons/faFutbol'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/sharp-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/sharp-solid-svg-icons/faArrowRight'
import { faBandage } from '@fortawesome/sharp-solid-svg-icons/faBandage'
import { faBarChart } from '@fortawesome/sharp-solid-svg-icons/faBarChart'
import { faBrainCircuit } from '@fortawesome/sharp-solid-svg-icons/faBrainCircuit'
import { faCourtSport } from '@fortawesome/sharp-solid-svg-icons/faCourtSport'
import { faGoalNet } from '@fortawesome/sharp-solid-svg-icons/faGoalNet'
import { faRepeat } from '@fortawesome/sharp-solid-svg-icons/faRepeat'
import { faVideo } from '@fortawesome/sharp-solid-svg-icons/faVideo'
import { faWhistle } from '@fortawesome/sharp-solid-svg-icons/faWhistle'
import { storyblokEditable, type ISbStoryData } from '@storyblok/react'
import { format } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'
import { useEffect, useMemo, useState } from 'react'
import { useTheme } from 'styled-components'

import { StClubLogo } from '../../../matches/components/match-time-line/MatchTimeLine.styled'
import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodyLargeRegular,
  Heading2,
} from '../../../shared/components/typography'
import { GenericEmbed } from '../../../storyblok/components/generic-embed'
import { StoryblokMatchLiveUpdateType } from '../../../storyblok/content-types/match'

import { FoulCardIcon } from './icons/FoulCard'
import {
  StContainer,
  StContentContainer,
  StContentHeader,
  StHeaderTitle,
  StMarker,
  StMinute,
  StPlayerChange,
  StPlayerChangeRow,
  StTimeLineColumn,
} from './MatchLiveUpdate.styled'

import type { SbMatchLiveUpdateData } from './MatchLiveUpdate.types'

interface Props {
  blok: SbMatchLiveUpdateData
  locale: string
  relations: ISbStoryData<unknown>[]
  halfTime: boolean
}

export const MatchLiveUpdate = ({ blok, halfTime }: Props) => {
  const theme = useTheme()

  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  const icon = useMemo(() => {
    switch (blok.type) {
      case StoryblokMatchLiveUpdateType.Goal: {
        return <FontAwesomeIcon icon={faFutbol} color="white" fontSize={32} />
      }
      case StoryblokMatchLiveUpdateType.YellowCard:
      case StoryblokMatchLiveUpdateType.RedCard: {
        return (
          <FoulCardIcon
            color={
              blok.type === StoryblokMatchLiveUpdateType.YellowCard
                ? theme.Colors.Components.StatsCardYellow
                : theme.Colors.Components.StatsCardRed
            }
          />
        )
      }
      case StoryblokMatchLiveUpdateType.PlayerChange: {
        return <FontAwesomeIcon icon={faRepeat} color="white" fontSize={32} />
      }
      case StoryblokMatchLiveUpdateType.Shot: {
        return <FontAwesomeIcon icon={faGoalNet} color="white" fontSize={32} />
      }
      case StoryblokMatchLiveUpdateType.Video: {
        return <FontAwesomeIcon icon={faVideo} color="white" fontSize={32} />
      }
      case StoryblokMatchLiveUpdateType.Statistic: {
        return <FontAwesomeIcon icon={faBarChart} color="white" fontSize={32} />
      }
      case StoryblokMatchLiveUpdateType.Fact: {
        return (
          <FontAwesomeIcon icon={faBrainCircuit} color="white" fontSize={32} />
        )
      }
      case StoryblokMatchLiveUpdateType.Injury: {
        return <FontAwesomeIcon icon={faBandage} color="white" fontSize={32} />
      }
      case StoryblokMatchLiveUpdateType.MatchEvent: {
        return (
          <FontAwesomeIcon icon={faCourtSport} color="white" fontSize={32} />
        )
      }
      case StoryblokMatchLiveUpdateType.Referee: {
        return <FontAwesomeIcon icon={faWhistle} color="white" fontSize={32} />
      }
      default: {
        return null
      }
    }
  }, [
    blok.type,
    theme.Colors.Components.StatsCardRed,
    theme.Colors.Components.StatsCardYellow,
  ])

  const timelineLabel = useMemo(() => {
    if (halfTime) {
      return 'HT'
    }

    if (blok.minute) {
      return `${blok.minute}${
        blok.minutes_extra ? `+${blok.minutes_extra}’` : '’'
      }`
    }

    return format(fromZonedTime(new Date(blok.created_at), 'UTC'), 'HH:mm')
  }, [blok.created_at, blok.minute, blok.minutes_extra, halfTime])

  if (!hasMounted) {
    return null
  }

  return (
    <StContainer {...storyblokEditable(blok)}>
      <StTimeLineColumn>
        <StMarker />
        <StMinute>{timelineLabel}</StMinute>
      </StTimeLineColumn>
      <StContentContainer
        $accent={
          blok.type === StoryblokMatchLiveUpdateType.Goal &&
          blok.team?.name === 'Cercle Brugge'
        }
      >
        {(blok.title || blok.team?.content?.logo) && (
          <StContentHeader>
            <StHeaderTitle>
              {icon}
              <Heading2>{blok.title}</Heading2>
            </StHeaderTitle>
            {blok.team?.content?.logo && (
              <StClubLogo
                image={blok.team.content.logo}
                width={48}
                height={52}
                sizeMobile={'28px'}
                sizeTablet={'48px'}
                sizeDesktop={'48px'}
              />
            )}
          </StContentHeader>
        )}
        {blok.image?.filename && <StoryblokServiceImage image={blok.image} />}
        {blok.player_in && blok.player_out && (
          <StPlayerChangeRow>
            <StPlayerChange>
              <FontAwesomeIcon
                icon={faArrowLeft}
                color={theme.Colors.Primary.Darker}
                fontSize={20}
              />
              <BodyLargeRegular>{blok.player_in}</BodyLargeRegular>
            </StPlayerChange>
            <StPlayerChange>
              <FontAwesomeIcon
                icon={faArrowRight}
                color={theme.Colors.Components.StatsCardRed}
                fontSize={20}
              />
              <BodyLargeRegular>{blok.player_out}</BodyLargeRegular>
            </StPlayerChange>
          </StPlayerChangeRow>
        )}
        {blok.text && <BodyLargeRegular>{blok.text}</BodyLargeRegular>}
        {blok.embedded_content?.[0] && (
          <GenericEmbed blok={blok.embedded_content[0]} />
        )}
      </StContentContainer>
    </StContainer>
  )
}
