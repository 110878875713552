import { storyblokEditable } from '@storyblok/react'
import remarkUnwrapImages from 'remark-unwrap-images'

import { isSbRichTextData } from './/RichText.types'
import {
  StContainer,
  StHeading2,
  StHeading3,
  StHeading4,
  StParagraph,
  StImage,
  StLi,
  StUl,
  StBlockQuote,
  StQuoteLine,
  StQuoteContentContainer,
  StQuoteContainer,
  StAnchor,
  StMarkdrown,
} from './RichText.styled'

import type { SbBlokData } from '@storyblok/react'

type Props = {
  blok: SbBlokData
}

export const RichText = ({ blok }: Props) => {
  return (
    <StContainer {...storyblokEditable(blok)}>
      {isSbRichTextData(blok) && (
        <StMarkdrown
          components={{
            /* eslint-disable no-unused-vars, @typescript-eslint/no-unused-vars */
            p: ({ node: _, ...props }) => <StParagraph {...props} />,
            h2: ({ node: _, ...props }) => <StHeading2 {...props} />,
            h3: ({ node: _, ...props }) => <StHeading3 {...props} />,
            h4: ({ node: _, ...props }) => <StHeading4 {...props} />,
            ul: ({ node: _, ...props }) => <StUl {...props} />,
            li: ({ node: _, ...props }) => <StLi {...props} />,
            a: ({ node: _, href = '', ...props }) => {
              if (
                /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|.(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\dA-Za-z-]+\.)+[A-Za-z]{2,}))$/.test(
                  href.toLowerCase()
                ) &&
                !href.includes('mailto:')
              ) {
                return <StAnchor href={`mailto:${href}`} {...props} />
              }

              if (
                href.startsWith(
                  'https://storage.googleapis.com/cercle-images-archive/'
                )
              ) {
                return <StImage src={href} alt="" height={644} width={1000} />
              }
              return <StAnchor href={href} {...props} />
            },
            img: ({ src = '', alt = '' }) =>
              src.includes('storyblok') ? (
                <StImage
                  src={`${src}/m/1920x0`}
                  alt={alt}
                  height={644}
                  width={1000}
                />
              ) : (
                <StImage src={src} alt={alt} height={644} width={1000} />
              ),
            blockquote: ({ node: _, children, ...props }) => (
              <StBlockQuote {...props}>
                <StQuoteContainer>
                  <StQuoteLine />
                  <StQuoteContentContainer>{children}</StQuoteContentContainer>
                </StQuoteContainer>
              </StBlockQuote>
            ),
            /* eslint-enable no-unused-vars, @typescript-eslint/no-unused-vars */
          }}
          remarkPlugins={[remarkUnwrapImages]}
        >
          {blok.content}
        </StMarkdrown>
      )}
    </StContainer>
  )
}
