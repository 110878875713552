import { storyblokEditable } from '@storyblok/react'

import { ButtonLink } from '../../../shared/components/button'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'

import type { SbButtonProps } from './Button.types'

export type StoryblokButtonProps = {
  blok: SbButtonProps
}

export const Button = ({ blok }: StoryblokButtonProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <ButtonLink
        variant={blok.type}
        size={blok.size}
        disabled={!blok.enabled}
        prefixIcon={blok.prefix_icon}
        suffixIcon={blok.suffix_icon}
        href={getHrefFromStoryblokLink(blok.link)}
        target={blok.link.target}
        gaEventLabel={blok.ga_event_label}
      >
        {blok.label}
      </ButtonLink>
    </div>
  )
}
