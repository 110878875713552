import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaskedInput from 'react-text-mask'
import styled, { css } from 'styled-components'

import { BodyMediumRegularStyles } from '../typography'

const inputStyles = css`
  ${BodyMediumRegularStyles}

  appearance: none;
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space3} ${theme.UI.SpacingPx.Space4}`};
  border: 1px solid ${({ theme }) => `${theme.Colors.Components.Input.Border}`};
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  background-color: ${({ theme }) => theme.Colors.Components.Input.Fill};
  width: 100%;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.Colors.Neutrals.Light};
  }
`

export const StInput = styled.input<{ error: boolean }>`
  ${inputStyles}

  border-color: ${({ theme, error }) =>
    error ? theme.Colors.Components.Input.ErrorBorder : 'inherit'};
`

export const StMaskedInput = styled(MaskedInput)`
  ${inputStyles}
`

export const StInputContainer = styled.div`
  position: relative;
`

export const StErrorIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: ${({ theme }) => theme.UI.SpacingPx.Space4};
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.Colors.Components.Input.ErrorText};
`
