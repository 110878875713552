import Image from 'next/image'

import spinner from './Spinner.gif'

interface Props {
  className?: string
}

export const LoaderGif = ({ className }: Props) => {
  return (
    <Image
      width={680}
      height={680}
      priority
      className={className}
      src={spinner}
      alt="Loading animation"
      unoptimized
    />
  )
}
