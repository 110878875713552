import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodySmallBold,
  BodySmallLink,
  Heading2,
  Heading4,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    padding: 0;
  }
`

export const StTitleContainer = styled.div`
  display: none;
  width: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  text-align: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`

export const StTitle = styled(BodySmallBold)``

export const StContentContainer = styled.div<{
  background?: string
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  flex: 1;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex-direction: column;
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;
    background: ${({ background, theme }) =>
      background && background !== ''
        ? `linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${background})`
        : theme.Colors.Neutrals.Darker};
    background-size: cover;
    background-position: center;
  }
`

export const StTeamContainer = styled.div`
  display: flex;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StClubLogo = styled(StoryblokServiceImage)`
  width: 40px;
  height: 40px;
`

export const StTeam = styled(Heading4)``

export const StLink = styled(BodySmallLink)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
  text-decoration: underline;

  &&:visited,
  &&:link,
  &&:active {
    color: ${({ theme }) => theme.Colors.Neutrals.Light};
    text-decoration: underline;
  }
`

export const StResultContainer = styled.div``

export const StScore = styled(Heading2)`
  font-size: 24px;
  line-height: 30px;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    font-size: 48px;
    line-height: 56px;
  }
`

export const StScoreAmount = styled.span<{ isCercle: boolean }>`
  color: ${({ isCercle, theme }) =>
    isCercle ? theme.Colors.Primary.Base : theme.Colors.Neutrals.White};
`

export const StButtonContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`
