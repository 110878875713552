import { StClubLogo, StClubName, StContainer } from './MatchClub.styled'

import type { StoryblokImage } from 'types'

type MatchClubProps = {
  className?: string
  name: string
  logo?: StoryblokImage
  variant?: 'light' | 'dark'
}

export const MatchClub = ({
  className,
  logo,
  name,
  variant = 'dark',
}: MatchClubProps) => {
  return (
    <StContainer className={className}>
      {logo && (
        <StClubLogo
          image={logo}
          width={48}
          height={52}
          sizeMobile={'150px'}
          sizeTablet={'150px'}
          sizeDesktop={'48px'}
        />
      )}
      <StClubName variant={variant}>{name}</StClubName>
    </StContainer>
  )
}
