import { ButtonLink } from '../../../shared/components/button'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'
import { timestampToLocaleDatestring } from '../../utils/timestampToLocaleDate'
import { MatchClub } from '../match-club'
import { MatchHeader } from '../match-header'
import { MatchLeague } from '../match-league'
import { MatchLiveCard, isValidLink } from '../match-live-card'

import {
  StContainer,
  StDate,
  StMatchday,
  StTime,
  StLeftContainer,
  StCenterContainer,
  StRightContainer,
  StTimeContainer,
} from './MatchCard.styled'

import type { Hyperlink, StoryblokImage, StoryblokLink } from 'types'

type MatchCardProps = {
  className?: string
  index: number
  time?: string
  date: string
  homeTeam: string
  homeTeamLogo?: StoryblokImage
  homeTeamScore?: string
  awayTeam: string
  awayTeamLogo?: StoryblokImage
  awayTeamScore?: string
  leagueName?: string
  leagueLogo?: StoryblokImage
  leagueMatchInfo?: string
  locale: string
  vipLink?: Hyperlink
  infoLink?: Hyperlink
  ticketLink?: Hyperlink
  sponsorsLogo?: StoryblokImage
  sponsorsLink?: StoryblokLink
  sponsorsLiveLogo?: StoryblokImage
  sponsorsLiveLink?: StoryblokLink
  liveUpdates?: string
  matchIsLive: boolean
}

export const MatchCard = ({
  className,
  index,
  time,
  date,
  homeTeam,
  homeTeamLogo,
  homeTeamScore,
  awayTeam,
  awayTeamLogo,
  awayTeamScore,
  leagueName,
  leagueLogo,
  leagueMatchInfo,
  locale,
  vipLink,
  infoLink,
  ticketLink,
  sponsorsLogo,
  sponsorsLink,
  sponsorsLiveLogo,
  sponsorsLiveLink,
  liveUpdates,
  matchIsLive,
}: MatchCardProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  if (matchIsLive) {
    return (
      <MatchLiveCard
        homeTeamName={homeTeam ?? ''}
        homeTeamLogo={homeTeamLogo}
        homeTeamScore={homeTeamScore}
        awayTeamName={awayTeam ?? ''}
        awayTeamLogo={awayTeamLogo}
        awayTeamScore={awayTeamScore}
        matchDate={date}
        matchTime={time}
        sponsorImage={sponsorsLiveLogo}
        sponsorLink={{
          href: getHrefFromStoryblokLink(sponsorsLiveLink) ?? '',
        }}
        ticketLink={ticketLink}
        vipLink={vipLink}
        infoLink={infoLink}
        locale={locale}
        liveUpdates={liveUpdates}
      />
    )
  }

  return (
    <>
      {index === 0 && (
        <MatchHeader
          image={sponsorsLogo}
          link={{
            url: getHrefFromStoryblokLink(sponsorsLink),
            rel: sponsorsLink?.rel,
            title: sponsorsLink?.title,
            target: sponsorsLink?.target,
          }}
        />
      )}
      <StContainer className={className}>
        <StLeftContainer>
          <MatchLeague
            name={leagueName ?? ''}
            logo={leagueLogo}
            info={leagueMatchInfo}
          ></MatchLeague>
        </StLeftContainer>
        <StCenterContainer>
          <MatchClub name={homeTeam} logo={homeTeamLogo} />
          <StMatchday>
            <StDate>
              {timestampToLocaleDatestring(date.toString(), locale)}
            </StDate>
            <StTimeContainer>
              <StTime>{time}</StTime>
            </StTimeContainer>
          </StMatchday>
          <MatchClub name={awayTeam} logo={awayTeamLogo} />
        </StCenterContainer>
        <StRightContainer>
          {liveUpdates && liveUpdates !== '' && (
            <ButtonLink
              variant="secondary"
              href={liveUpdates}
              size="small"
              gaEventLabel="press_calender_match_live"
            >
              {t('match_card_live')}
            </ButtonLink>
          )}
          {vipLink && isValidLink(vipLink) && (
            <ButtonLink
              variant="secondary"
              href={vipLink.href}
              size="small"
              gaEventLabel="press_calender_match_vip"
            >
              {t('match_card_vip')}
            </ButtonLink>
          )}
          {infoLink && isValidLink(infoLink) && (
            <ButtonLink
              variant="secondary"
              href={infoLink.href}
              size="small"
              gaEventLabel="press_calender_match_info"
            >
              {t('match_card_info')}
            </ButtonLink>
          )}
          {ticketLink && isValidLink(ticketLink) && (
            <ButtonLink
              variant="primary"
              prefixIcon="ticket"
              href={ticketLink.href}
              size="small"
              gaEventLabel="press_calender_match_tickets"
            >
              {t('match_card_tickets')}
            </ButtonLink>
          )}
        </StRightContainer>
      </StContainer>
    </>
  )
}
