import styled from 'styled-components'

export const StContainer = styled.section`
  width: 100%;
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
    margin-right: auto;
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space16};
    margin-left: auto;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space8} 0;
  }
`
