import { differenceInYears, format } from 'date-fns'
import { useMemo } from 'react'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StCard,
  StCercleCircle,
  StDivider,
  StImage,
  StImageContainer,
  StLabelTd,
  StTable,
  StValueTd,
} from './PlayerInformation.styled'

import type { StoryblokImage } from 'types'

interface Props {
  className?: string
  height?: number
  playerNumber?: string
  birthDate?: Date
  homeTown?: string

  image?: StoryblokImage
}

export const PlayerInformation = ({
  className,
  height,
  playerNumber,
  birthDate,
  homeTown,
  image,
}: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const age = useMemo(() => {
    if (!birthDate) {
      return
    }

    return differenceInYears(new Date(), birthDate)
  }, [birthDate])

  return (
    <StCard className={className}>
      <StTable>
        <tbody>
          {birthDate && (
            <>
              <tr>
                <StLabelTd>{t('stats_info_born')}</StLabelTd>
                <StValueTd>{format(birthDate, 'dd-MM-yyyy')}</StValueTd>
              </tr>
              <StDivider />
            </>
          )}
          {age && (
            <>
              <tr>
                <StLabelTd>{t('stats_info_age')}</StLabelTd>
                <StValueTd>{age}</StValueTd>
              </tr>
              <StDivider />
            </>
          )}
          {homeTown && (
            <>
              <tr>
                <StLabelTd>{t('stats_info_origin')}</StLabelTd>
                <StValueTd>{homeTown}</StValueTd>
              </tr>
              <StDivider />
            </>
          )}
          {height && (
            <>
              <tr>
                <StLabelTd>{t('stats_info_height')}</StLabelTd>
                <StValueTd>{height}cm</StValueTd>
              </tr>
              <StDivider />
            </>
          )}
          {playerNumber && (
            <>
              <tr>
                <StLabelTd>{t('stats_info_number')}</StLabelTd>
                <StValueTd>{playerNumber}</StValueTd>
              </tr>
              <StDivider />
            </>
          )}
        </tbody>
      </StTable>
      <StImageContainer>
        <StCercleCircle />
        {image && <StImage image={image} />}
      </StImageContainer>
    </StCard>
  )
}
