import {
  StContainer,
  StDataLabel,
  StDataValue,
} from './AccountDataBlock.styled'

interface AccountDataBlockProps {
  className?: string
  label: string
  value: string
}

export const AccountDataBlock = ({
  label,
  value,
  className,
}: AccountDataBlockProps) => {
  return (
    <StContainer className={className}>
      <StDataLabel>{label}</StDataLabel>
      <StDataValue>{value}</StDataValue>
    </StContainer>
  )
}
