import { isObject, isStoryblokImage, isStoryblokLink } from 'types'

import type { StoryblokImage, StoryblokLink } from 'types'

export type StoryblokImageLink = {
  _uid: string
  image: StoryblokImage
  link: StoryblokLink
}

export function isStoryblokImageLink(
  value: unknown
): value is StoryblokImageLink {
  if (isObject(value)) {
    // UID
    const { _uid } = value
    if (typeof _uid !== 'string') {
      return false
    }
    // Image
    const { image } = value
    if (!isStoryblokImage(image)) {
      return false
    }
    // Link
    const { link } = value
    if (!isStoryblokLink(link)) {
      return false
    }
  } else {
    return false
  }
  return true
}
