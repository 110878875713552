import styled from 'styled-components'

export const StContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space16};
`

export const StLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: 80px;
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space16};
`

export const StMatchCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space16};
  }
`

export const StMatchResultCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.Colors.Neutrals.Darker};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-top: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`
