import { useStoryblokApi } from '@storyblok/react'
import { useEffect, useState } from 'react'

import { getStoryblokStoryVersion } from '../utils/env'

import type { ISbStoryData, ISbStoryParams } from '@storyblok/react'

export const useStoryblokStory = <T>(
  slug: string,
  parameters?: ISbStoryParams
) => {
  const [story, setStory] = useState<ISbStoryData<T>>()
  const [isLoading, setIsLoading] = useState(true)
  const storyblokApi = useStoryblokApi()

  useEffect(() => {
    const storyParameters: ISbStoryParams = {
      version: getStoryblokStoryVersion(),
      ...parameters,
    }

    const getStories = async () => {
      if (storyblokApi) {
        setIsLoading(true)
        try {
          const response = await storyblokApi.get(
            `cdn/stories/${slug}`,
            storyParameters
          )
          setStory(response.data.story as ISbStoryData<T>)
          setIsLoading(false)
        } catch (error) {
          console.error(error)
          setStory(undefined)
        }
      }
    }

    getStories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyblokApi, slug])

  return { story, isLoading }
}
