import styled from 'styled-components'

import { BodySmallRegular } from '../../../shared/components/typography'

export const StCardList = styled.div`
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space10};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StCard = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  padding: ${({ theme }) =>
    `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space6}`};
`

export const StCardNumber = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`
