import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

import { StTwoThirds, StOneThird, StContainer } from './TwoColumns.styled'

import type { SbBlokData, ISbStoryData } from '@storyblok/react'

type ThirdLeftStoryblokProps = {
  blok: {
    columns: SbBlokData[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const ColumnsOneThirdTwoThirds = ({
  blok,
  locale,
  relations,
}: ThirdLeftStoryblokProps) => {
  if (!blok.columns || blok.columns.length <= 0) {
    return null
  }

  return (
    <StContainer {...storyblokEditable(blok)}>
      {blok.columns.length > 0 && (
        <StOneThird>
          <StoryblokComponent
            blok={blok.columns[0]}
            locale={locale}
            relations={relations}
          />
        </StOneThird>
      )}
      {blok.columns.length > 1 && (
        <StTwoThirds>
          <StoryblokComponent
            blok={blok.columns[1]}
            locale={locale}
            relations={relations}
          />
        </StTwoThirds>
      )}
    </StContainer>
  )
}
