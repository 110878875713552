import styled from 'styled-components'

import {
  StatCardLabel,
  StatCardNumber,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;

  overflow: hidden;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex: 0 0
      calc(33.33% - ${({ theme }) => (theme.UI.Spacing.Space40 + 2) / 3}px);
  }
`

export const StCardContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: row;

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StCard = styled.div<{ cardType: 'yellow' | 'red' }>`
  width: 28px;
  height: 38px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 3px;
  background-color: ${({ cardType, theme }) =>
    cardType === 'red'
      ? theme.Colors.Components.StatsCardRed
      : theme.Colors.Components.StatsCardYellow};
  z-index: 2;
`

export const StPhatLine = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  width: 30px;
  height: 38px;
  position: absolute;

  top: 0px;
  left: 0px;

  z-index: 1;
`

export const StPartiallyHiddenCard = styled.div<{ cardType: 'yellow' | 'red' }>`
  width: 20px;
  height: 35px;

  position: absolute;

  transform: rotate(6deg);

  border-radius: 3px;
  background-color: ${({ cardType, theme }) =>
    cardType === 'red'
      ? theme.Colors.Components.StatsCardRed
      : theme.Colors.Components.StatsCardYellow};
  right: -4px;
  top: 2px;

  z-index: 0;
`

export const StStatCardNumber = styled(StatCardNumber)`
  color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
`

export const StStatCardLabel = styled(StatCardLabel)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
