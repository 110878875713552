import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons/faTriangleExclamation'

import { StErrorIcon, StInput, StInputContainer } from './Input.styled'

import type { InputHTMLAttributes } from 'react'

type InputProps = {
  error?: boolean
} & InputHTMLAttributes<HTMLInputElement>

export const Input = ({ error = false, ...props }: InputProps) => {
  return (
    <StInputContainer>
      <StInput {...props} error={error} />
      {error && <StErrorIcon icon={faTriangleExclamation} />}
    </StInputContainer>
  )
}
