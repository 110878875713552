import { DividerContainer, DividerLine, DividerTitle } from './Divider.styled'

type DividerProps = {
  title: string | undefined
}

export function Divider({ title }: DividerProps) {
  return (
    <DividerContainer>
      <DividerTitle>{title}</DividerTitle>
      <DividerLine />
    </DividerContainer>
  )
}
