import type { HtmlHTMLAttributes } from 'react'

type SvgProps = HtmlHTMLAttributes<SVGElement> & {
  width?: number
  height?: number
}

export const BulletPointIcon = (props: SvgProps) => {
  return (
    <svg
      width={13}
      height={12}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2.485 0L0 7.41 6.5 12 13 7.41 10.515 0h-8.03z" fill="#60B22C" />
    </svg>
  )
}
