import styled from 'styled-components'

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

export const StItem = styled.div`
  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex: 0 1 calc(50% - ${({ theme }) => theme.UI.SpacingPx.Space5});
  }
`

export const StLoadingContainer = styled.div`
  flex: column;
  justify-content: center;
  align-items: center;

  height: 460px;
`
