import { DOMParser as XMLDOMParser } from '@xmldom/xmldom'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StErrorMessage,
  StGenericEmbed,
  StVideoContainer,
} from './IFrameEmbed.styled'

interface IFrameEmbedProps {
  iFrameCode: string
  isVideo?: boolean
  containerId?: string
}

function isHTML(input: string) {
  try {
    new XMLDOMParser({
      errorHandler: {
        error: (error) => {
          if (
            !error.includes(
              'Only one element can be added and only after doctype'
            )
          ) {
            throw new Error('Invalid HTML')
          }
          return false
        },
        fatalError: () => {
          throw new Error('Invalid HTML')
        },
      },
    }).parseFromString(input, 'text/html')

    return true
  } catch {
    return false
  }
}

export const IFrameEmbed = ({
  iFrameCode,
  isVideo = false,
  containerId,
}: IFrameEmbedProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  if (!isHTML(iFrameCode)) {
    return <StErrorMessage>{t('embed.component.error')}</StErrorMessage>
  }
  return isVideo ? (
    <StVideoContainer
      dangerouslySetInnerHTML={{ __html: iFrameCode }}
    ></StVideoContainer>
  ) : (
    <StGenericEmbed
      id={containerId}
      dangerouslySetInnerHTML={{ __html: iFrameCode }}
    />
  )
}
