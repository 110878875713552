import { storyblokEditable } from '@storyblok/react'
import { formatInTimeZone } from 'date-fns-tz'
import { useRouter } from 'next/router'
import { isEmptyStoryblokImage } from 'utils'

import { ArticleCard } from '../../../content/components/article-card'
import { StLoadingContainer } from '../../../mycercle/components/account-card-settings/AccountCardSettings.styled'
import { Loader } from '../../../shared/components/loader'
import { useStoryblokCercleGeneralSettings } from '../../hooks/useStoryblokCercleGeneralSettings'
import { useStoryblokStories } from '../../hooks/useStoryblokStories'

import { StContainer, StItem } from './RelatedArticles.styled'

import type { SbRelatedArticlesData } from './RelatedArticles.types'
import type { SbArticlePageData } from '../article-page/ArticlePage.types'

type Props = {
  blok: SbRelatedArticlesData
}

type ParameterProps = {
  content_type: 'article_page'
  per_page: number
  sort_by: string
  language?: string
  filter_query?: {
    players?: {
      any_in_array: string | number | true | object
    }
    matches?: {
      any_in_array: string | number | true | object
    }
    languages?: {
      any_in_array?: string
    }
    __or?: {
      release_date?: {
        lt_date?: string
        is?: string
      }
    }[]
  }
}

export const RelatedArticles = ({ blok }: Props) => {
  const { locale } = useRouter()

  let parameters: ParameterProps = {
    content_type: 'article_page',
    per_page: blok.amount_of_articles,
    sort_by: 'first_published_at:desc',
    language: locale,
    filter_query: {
      __or: [
        {
          release_date: {
            lt_date: formatInTimeZone(new Date(), 'UTC', 'yyyy-MM-dd HH:mm'),
          },
        },
        {
          release_date: {
            is: 'empty',
          },
        },
      ],
      languages: {
        any_in_array: locale,
      },
    },
  }

  if (blok.player) {
    parameters = {
      ...parameters,
      filter_query: {
        ...parameters.filter_query,
        players: {
          any_in_array: blok.player,
        },
      },
    }
  }
  if (blok.match) {
    parameters = {
      ...parameters,
      filter_query: {
        ...parameters.filter_query,
        matches: {
          any_in_array: blok.match,
        },
      },
    }
  }

  const { config, isLoading: areAppSettingsLoading } =
    useStoryblokCercleGeneralSettings()
  const { stories: articles, isLoading: areArticleLoading } =
    useStoryblokStories<SbArticlePageData>(parameters)

  if (areAppSettingsLoading || areArticleLoading) {
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )
  }

  return (
    <div {...storyblokEditable(blok)}>
      <StContainer>
        {articles.slice(0, blok.amount_of_articles).map((article) => {
          return (
            <StItem key={article.id}>
              <ArticleCard
                image={
                  isEmptyStoryblokImage(article.content.thumbnail)
                    ? config?.content.default_article_thumbnail
                    : article.content.thumbnail
                }
                title={article.content.title}
                href={`/${article.full_slug}`}
                publishDate={
                  article.first_published_at
                    ? new Date(article.first_published_at)
                    : new Date()
                }
              />
            </StItem>
          )
        })}
      </StContainer>
    </div>
  )
}
