import { isObject } from 'types'

import type { SbBlokData } from '@storyblok/react'

export interface SbRichTextData extends SbBlokData {
  _uid: string
  content: string
}

export function isSbRichTextData(blok: SbBlokData): blok is SbRichTextData {
  if (isObject(blok)) {
    // UID
    const { _uid } = blok
    if (typeof _uid !== 'string') {
      return false
    }
    // Content
    const { content } = blok
    if (typeof content !== 'string') {
      return false
    }
  } else {
    return false
  }
  return true
}
