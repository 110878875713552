import styled from 'styled-components'

import { StClubLogo } from '../../../matches/components/match-time-line/MatchTimeLine.styled'
import { Heading2 } from '../../../shared/components/typography'

import { TimeLineMarkerIcon } from './icons/TimeLineMarker'

export const StTimeLineColumn = styled.div`
  position: relative;

  flex-shrink: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: ${({ theme }) => theme.UI.SpacingPx.Space8};

    width: 2px;
    height: 100%;
    background-color: ${({ theme }) => theme.Colors.Neutrals.Lighter};
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    width: 150px;

    &:before {
      left: 0;
    }
  }
`

export const StContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:last-of-type {
    ${StTimeLineColumn} {
      &:before {
        height: 60px;
      }
    }
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding-top: 0;

    &:first-of-type {
      ${StTimeLineColumn} {
        &:before {
          top: 60px;
        }
      }
    }

    &:only-child {
      ${StTimeLineColumn} {
        &:before {
          height: 0px;
        }
      }
    }
  }
`

export const StMinute = styled(Heading2)`
  position: absolute;
  top: 0;
  left: ${({ theme }) => theme.UI.SpacingPx.Space13};

  color: ${({ theme }) => theme.Colors.Primary.Base};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    top: ${({ theme }) => theme.UI.SpacingPx.Space13};
    left: ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

export const StMarker = styled(TimeLineMarkerIcon)`
  position: absolute;
  top: -3px;
  left: 33px;

  transform: translateX(-50%);

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    top: 56px;
    left: 1px;
  }
`

export const StContentContainer = styled.div<{ $accent?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  flex-grow: 1;

  padding: ${({ theme }) => theme.UI.SpacingPx.Space6};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space13};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space10};

  background-color: ${({ theme, $accent }) =>
    $accent ? theme.Colors.Primary.Darker : theme.Colors.Neutrals.Darkest};
  z-index: 10;

  ${StClubLogo} {
    filter: ${({ $accent }) => ($accent ? 'brightness(0) invert(1)' : 'none')};
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
    gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
    margin-bottom: 0;
  }
`

export const StContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
`

export const StHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space3};

  > svg {
    transform: scale(0.75);
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    gap: ${({ theme }) => theme.UI.SpacingPx.Space5};

    > svg {
      transform: scale(1);
    }
  }
`

export const StPlayerChangeRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space5};
  align-items: center;
`

export const StPlayerChange = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2};
  align-items: center;
`
