import { StoryblokComponent } from '@storyblok/react'

import { StFullWidthSectionContainer } from './FullWidthSectionContainer.styled'

import type { ISbStoryData } from '@storyblok/react'

type FullWidthSectionContainerProps = {
  blok: {
    content: {
      _uid: number
    }[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const FullWidthSectionContainer = ({
  blok,
  locale,
  relations,
}: FullWidthSectionContainerProps) => {
  return (
    <StFullWidthSectionContainer>
      {blok &&
        blok.content &&
        blok.content.length > 0 &&
        blok.content.map((nestedBlok) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            locale={locale}
            relations={relations}
          />
        ))}
    </StFullWidthSectionContainer>
  )
}
