import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'

export const StContainer = styled.div`
  width: 100%;
  height: ${({ theme }) => theme.UI.SpacingPx.Space13};
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space2};
  margin-bottom: -${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StLink = styled.a`
  display: block;
  max-width: 415px;
  height: 100%;
`

export const StImage = styled(StoryblokServiceImage)`
  max-width: 415px;
  height: 100%;
  object-fit: contain;
`
