export const setTimeOnDate = (
  dateInBrusselsTimezone: Date,
  matchTime?: string
) => {
  const dateWithoutTime = new Date(dateInBrusselsTimezone)

  if (
    matchTime &&
    typeof matchTime === 'string' &&
    /^\d\d:\d\d$/.test(matchTime)
  ) {
    const [hours, minutes] = matchTime.split(':')
    dateWithoutTime.setHours(Number.parseInt(hours))
    dateWithoutTime.setMinutes(Number.parseInt(minutes))
  }

  return dateWithoutTime
}
