import styled from 'styled-components'

import { Card } from '../../../shared/components/card'
import { StatsDivider } from '../stats-divider'

export const StCard = styled(Card)`
  flex-direction: column;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

export const StStatsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space6};

  row-gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
`

export const StMobileStatsDivider = styled(StatsDivider)`
  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: none;
  }
`
