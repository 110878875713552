import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import {
  StatHeading,
  StatLabel,
  StatSubLabel,
} from '../../../shared/components/typography'

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  gap: ${({ theme }) => theme.UI.Spacing.Space1 / 2}px;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    flex: 0 0
      calc(33.33% - ${({ theme }) => (theme.UI.Spacing.Space40 + 2) / 3}px);
  }
`

export const StStatContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    gap: ${({ theme }) => theme.UI.Spacing.Space1 / 2}px;
    flex-direction: column;
    align-items: unset;
  }
`

export const StStat = styled(StatHeading)`
  color: ${({ theme }) => theme.Colors.Primary.Base};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    margin: 0px;
  }
`

export const StStatLabel = styled(StatLabel)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StStatSubLabel = styled(StatSubLabel)`
  color: ${({ theme }) => theme.Colors.Neutrals.Medium};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const StIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.Colors.Neutrals.Medium};
  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space2};
`
