import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StLegendContainer,
  StLegendHeading,
  StLegendItem,
  StLegendPoint,
  StLegendRow,
  StLegendTable,
  StPointCell,
} from './StandingsLegend.styled'

export const StandingsLegend = () => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  return (
    <>
      <StLegendHeading>{t('legend')}</StLegendHeading>

      <StLegendContainer>
        <StLegendTable>
          <tbody>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>
                  {t('standings_matches_played_short')}
                </StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem> {t('standings_matches_played')}</StLegendItem>
              </td>
            </StLegendRow>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>
                  {t('standings_matches_won_short')}
                </StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem>{t('standings_matches_won')}</StLegendItem>
              </td>
            </StLegendRow>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>
                  {t('standings_matches_lost_short')}
                </StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem>{t('standings_matches_lost')}</StLegendItem>
              </td>
            </StLegendRow>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>
                  {t('standings_matches_draw_short')}
                </StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem>{t('standings_matches_draw')}</StLegendItem>
              </td>
            </StLegendRow>
          </tbody>
        </StLegendTable>

        <StLegendTable>
          <tbody>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>{t('standings_goals_for_short')}</StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem> {t('standings_goals_for')}</StLegendItem>
              </td>
            </StLegendRow>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>
                  {t('standings_goals_against_short')}
                </StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem>{t('standings_goals_against')}</StLegendItem>
              </td>
            </StLegendRow>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>
                  {t('standings_goals_difference_short')}
                </StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem>{t('standings_goals_difference')}</StLegendItem>
              </td>
            </StLegendRow>
            <StLegendRow>
              <StPointCell>
                <StLegendPoint>{t('standings_points_short')}</StLegendPoint>
              </StPointCell>
              <td>
                <StLegendItem>{t('standings_points')}</StLegendItem>
              </td>
            </StLegendRow>
          </tbody>
        </StLegendTable>
      </StLegendContainer>
    </>
  )
}
