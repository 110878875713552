import styled from 'styled-components'

export const StVideoContainer = styled.div`
  overflow: hidden;
  padding-top: 56.25%; /* 16:9*/
  position: relative;
  > iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export const StGenericEmbed = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StErrorMessage = styled.div`
  display: flex;
  justify-content: center;
`
