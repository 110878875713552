import type { StoryblokTeam } from '../../../../storyblok/content-types/team'
import type { StandingRow } from '../types/row'
import type { ISbStoryData } from '@storyblok/react'
import type { Standing } from 'types'

export const mapToStandingRows = (
  teams: ISbStoryData<StoryblokTeam>[],
  standings: Standing[]
) => {
  return standings.map<StandingRow>((standing) => {
    const matchingTeam = teams.find(
      (team) => team.content?.stats_data_id === standing.contestantId
    )

    return {
      rank: standing.rank,
      rankMove: standing.rankMove,
      points: standing.points,
      matchesPlayed: standing.matchesPlayed,
      matchesWon: standing.matchesWon,
      matchesLost: standing.matchesLost,
      matchesDrawn: standing.matchesDrawn,
      goalsFor: standing.goalsFor,
      goalsAgainst: standing.goalsAgainst,
      goalsDifference: standing.goalsDifference,
      lastSixResults: standing.lastSixResults,
      teamLogo: matchingTeam?.content?.logo,
      teamName: matchingTeam?.content?.name || standing.contestantName,
      teamNameShort:
        matchingTeam?.content.short_name || standing.contestantName.slice(0, 3),
      teamId: standing.contestantId,
    }
  })
}
