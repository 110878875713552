import { storyblokEditable, StoryblokComponent } from '@storyblok/react'

import {
  StColumn,
  StContainer,
  StContainerDoubleColumns,
} from './EqualColumns.styled'

import type { SbBlokData, ISbStoryData } from '@storyblok/react'

type EqualColumnsStoryblokProps = {
  blok: {
    columns: SbBlokData[]
  }
  locale: string
  relations: ISbStoryData<unknown>[]
}

export const EqualColumns = ({
  blok,
  locale,
  relations,
}: EqualColumnsStoryblokProps) => {
  if (!blok.columns || blok.columns.length <= 0) {
    return null
  }

  return blok.columns.length > 2 ? (
    <StContainer {...storyblokEditable(blok)}>
      {blok.columns.map((column, index) => (
        <StColumn key={index}>
          <StoryblokComponent
            blok={column}
            locale={locale}
            relations={relations}
          />
        </StColumn>
      ))}
    </StContainer>
  ) : (
    <StContainerDoubleColumns {...storyblokEditable(blok)}>
      {blok.columns.map((column, index) => (
        <StColumn key={index}>
          <StoryblokComponent
            blok={column}
            locale={locale}
            relations={relations}
          />
        </StColumn>
      ))}
    </StContainerDoubleColumns>
  )
}
