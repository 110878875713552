import { isAfter, isWithinInterval } from 'date-fns'
import { fromZonedTime } from 'date-fns-tz'

import { setTimeOnDate } from './setTimeOnDate'

import type { StoryblokMatch } from '../../storyblok/content-types/match'

export const matchIsLive = (match: StoryblokMatch) => {
  const startDate = setTimeOnDate(new Date(match?.matchday ?? ''), match?.time)

  const endDate = match?.extra_time_full_time
    ? fromZonedTime(new Date(match?.extra_time_full_time), 'UTC')
    : match?.full_time
    ? fromZonedTime(new Date(match.full_time), 'UTC')
    : undefined

  return endDate
    ? isWithinInterval(new Date(), { start: startDate, end: endDate })
    : isAfter(new Date(), startDate)
}
