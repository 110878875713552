import styled from 'styled-components'

export const StContainer = styled.div`
  margin: 0 auto ${({ theme }) => theme.UI.SpacingPx.Space6} auto;
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.HighlightBanner};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

export const StMobileContainer = styled.div`
  display: block;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StDesktopContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`

export const StLoadingContainer = styled.div`
  height: 400px;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    height: 500px;
  }
`
