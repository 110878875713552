import { storyblokEditable } from '@storyblok/react'

import { Divider as DividerComponent } from '../../../shared/components/divider'

type StoryblokDividerProps = {
  blok: {
    title: string | undefined
  }
}

export function Divider({ blok }: StoryblokDividerProps) {
  return (
    <div {...storyblokEditable(blok)}>
      <DividerComponent title={blok.title} />
    </div>
  )
}
