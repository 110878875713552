import { faCrosshairs } from '@fortawesome/pro-solid-svg-icons/faCrosshairs'
import { faHeadSide } from '@fortawesome/pro-solid-svg-icons/faHeadSide'
import { faRight } from '@fortawesome/pro-solid-svg-icons/faRight'
import { useMemo } from 'react'

import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { findStat } from '../../utils/findStat'
import { StatsCard } from '../stats-card'
import { StatsDivider } from '../stats-divider'
import { StatsItem } from '../stats-item'

import type { PlayerStat } from 'types'

interface Props {
  className?: string
  stats: PlayerStat[]
}

export const OffensiveStats = ({ stats, className }: Props) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  const { goals, goalsHeaded, assists, keyPasses, shotsOnTarget, gamesPlayed } =
    useMemo(() => {
      return {
        goals: findStat('Goals', stats),
        goalsHeaded: findStat('Headed Goals', stats) || '0',
        assists: findStat('Goal Assists', stats),
        keyPasses: findStat('Key Passes (Attempt Assists)', stats),
        shotsOnTarget: findStat('Shots On Target ( inc goals )', stats),
        gamesPlayed: findStat('Games Played', stats),
      }
    }, [stats])

  return (
    <StatsCard title={t('stats_title_offensive')} className={className}>
      <StatsItem
        stat={goals}
        statSub={goalsHeaded}
        statSubLabel={t('stat_goals')}
        statLabel={t('stat_goals_headed')}
        icon={faHeadSide}
      />
      <StatsDivider />
      <StatsItem
        stat={assists}
        statSub={keyPasses}
        statLabel={t('stat_assists')}
        statSubLabel={t('stat_key_passes')}
        icon={faRight}
      />
      <StatsDivider />
      <StatsItem
        stat={shotsOnTarget}
        statSub={
          shotsOnTarget && gamesPlayed
            ? `${
                Math.round((Number(shotsOnTarget) / Number(gamesPlayed)) * 10) /
                10
              }`
            : undefined
        }
        statLabel={t('stat_shots')}
        statSubLabel={t('stat_shots_per_game')}
        icon={faCrosshairs}
      />
    </StatsCard>
  )
}
