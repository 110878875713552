import styled from 'styled-components'

import { BodySmallRegular } from '../../../shared/components/typography'

export const BottomLinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex-direction: row;
    justify-content: end;
    margin-bottom: 5px;
  }
`

export const BottomLink = styled.a`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
  text-decoration: none;
`

export const BottomLinkText = styled(BodySmallRegular)``
