import { useUser } from '@auth0/nextjs-auth0/client'
import useAxios from 'axios-hooks'
import { createContext } from 'react'

import type {
  AuthenticatedUserContext,
  IdTokenClaims,
} from '../../types/authenticatedUserContext'
import type { ReactNode } from 'react'
import type { User } from 'types'

interface Props {
  children?: ReactNode
}

export const authenticatedUserContext = createContext<AuthenticatedUserContext>(
  {
    isLoading: false,
    isAuthenticated: false,
    refetchUser: () => undefined,
  }
)

export const AuthenticatedUserProvider = ({ children }: Props) => {
  const { user: idTokenClaims, isLoading: isTokenClaimsLoading } = useUser()
  const [{ data: token, loading: isTokenLoading }] = useAxios<{
    accessToken: string
  }>(`${process.env.NEXT_PUBLIC_FRONTEND_URL}/api/auth/token`, {
    useCache: false,
    ssr: false,
  })
  const [{ data: user, loading: isUserLoading }, refetchUser] = useAxios<User>(
    {
      url: `${process.env.NEXT_PUBLIC_BACKEND_URL || ''}/api/users/current`,
      headers: { Authorization: `Bearer ${token?.accessToken}` },
    },
    {
      manual: !token?.accessToken,
    }
  )

  return (
    <authenticatedUserContext.Provider
      value={{
        isLoading: isTokenClaimsLoading || isTokenLoading || isUserLoading,
        isAuthenticated: !!idTokenClaims,
        accessToken: token?.accessToken,
        idTokenClaims: idTokenClaims as IdTokenClaims,
        user,
        refetchUser,
      }}
    >
      {children}
    </authenticatedUserContext.Provider>
  )
}
