import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'

export const Icon = styled(StoryblokServiceImage)`
  width: ${({ theme }) => theme.UI.SpacingPx.Space6};
  height: auto;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    width: ${({ theme }) => theme.UI.SpacingPx.Space8};
  }
`
