import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { useRouter } from 'next/router'

import { useUser } from '../../../authentication/hooks/useUser'
import { ButtonLink } from '../../../shared/components/button'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'
import { getHrefFromStoryblokLink } from '../../../storyblok/utils/getHrefFromStoryblokLink'
import { HeaderDropdown } from '../header-dropdown'

import {
  StBackground,
  StContainer,
  StContentContainer,
  StHeaderSection,
  StHamburgerIcon,
  StButtonContainer,
  StHomeLink,
  StHomeLogo,
  StHeaderLinks,
  StHeaderSpacer,
} from './HeaderDesktop.styled'

import type { SbButtonProps } from '../../../storyblok/components/button'
import type { DropdownItem } from '../../types/DropdownItem.types'
import type { Hyperlink, StoryblokImage } from 'types'
interface HeaderDesktopProps {
  className?: string
  homeLogo?: StoryblokImage
  homeLink: Hyperlink
  buttons: SbButtonProps[]
  teamsDropdownItems: DropdownItem[]
  otherDropdownItems: DropdownItem[]
  handleSidebarToggle: () => void
  teamsLabel?: string
}

export const HeaderDesktop = ({
  className,
  homeLink,
  homeLogo,
  buttons,
  teamsDropdownItems,
  otherDropdownItems,
  handleSidebarToggle,
  teamsLabel,
}: HeaderDesktopProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { user, isAuthenticated } = useUser()
  const router = useRouter()

  return (
    <>
      <StContainer className={className}>
        <StBackground variation={3} />
        <StContentContainer>
          <StHeaderSection>
            <StHeaderLinks>
              <StHamburgerIcon handleClick={handleSidebarToggle} />
              <HeaderDropdown
                title={teamsLabel ?? 'Teams'}
                items={teamsDropdownItems}
              ></HeaderDropdown>
              {otherDropdownItems
                .filter((item) => item.show)
                .map((item) => {
                  return (
                    <HeaderDropdown
                      key={item.id}
                      title={item.title}
                      items={[{ ...item }]}
                    ></HeaderDropdown>
                  )
                })}
            </StHeaderLinks>
          </StHeaderSection>

          {homeLink && homeLink.href && homeLink.href !== '' && (
            <StHomeLink href={homeLink.href}>
              {homeLogo && <StHomeLogo image={homeLogo}></StHomeLogo>}
            </StHomeLink>
          )}

          <StHeaderSection>
            <StButtonContainer>
              {buttons &&
                buttons.length > 0 &&
                buttons.map((button) => {
                  return (
                    <ButtonLink
                      key={button._uid}
                      variant={button.type}
                      disabled={!button.enabled}
                      size={button.size}
                      prefixIcon={
                        button.label.toLowerCase() === 'shop'
                          ? undefined
                          : button.prefix_icon
                      }
                      suffixIcon={button.suffix_icon}
                      href={getHrefFromStoryblokLink(button.link)}
                      target={button.link.target}
                      gaEventLabel={button.ga_event_label}
                    >
                      {button.label}
                    </ButtonLink>
                  )
                })}
              <ButtonLink
                variant="secondary"
                prefixIcon={faUser}
                href={
                  isAuthenticated
                    ? '/my-cercle'
                    : `/api/auth/login?returnTo=${encodeURIComponent(
                        process.env.NEXT_PUBLIC_FRONTEND_URL + router.asPath
                      )}`
                }
                size="small"
                gaEventLabel={
                  isAuthenticated
                    ? 'press_navigation_my_cercle_desktop'
                    : 'press_navigation_login_desktop'
                }
              >
                {isAuthenticated
                  ? user?.firstName
                    ? `${user?.firstName} `
                    : t('navigation_my_cercle')
                  : t('navigation_my_cercle')}
              </ButtonLink>
            </StButtonContainer>
          </StHeaderSection>
        </StContentContainer>
      </StContainer>
      <StHeaderSpacer id="header-spacer"></StHeaderSpacer>
    </>
  )
}
