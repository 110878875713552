import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { useRouter } from 'next/router'

import { useUser } from '../../../authentication/hooks/useUser'
import { HamburgerIcon } from '../../../shared/components/hamburger-icon'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import {
  StContainer,
  StContentContainer,
  StHamburgerContainer,
  StHomeLogo,
  StIcon,
  StItem,
  StLabel,
} from './HeaderMobile.styled'

import type { NavigationLink } from '../../types/NavigationLink.types'
import type { Hyperlink, StoryblokImage } from 'types'

interface HeaderMobileProps {
  className?: string
  homeLogo?: StoryblokImage
  homeLink: Hyperlink
  homeLabel: string
  menuLabel: string
  mainLinks: NavigationLink[]
  handleSidebarToggle: () => void
}

export const HeaderMobile = ({
  className,
  homeLogo,
  homeLink,
  homeLabel,
  menuLabel,
  mainLinks,
  handleSidebarToggle,
}: HeaderMobileProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)
  const { isAuthenticated } = useUser()

  const router = useRouter()

  return (
    <>
      <StContainer className={className}>
        <StContentContainer>
          <StItem
            href={homeLink.href ?? '/home'}
            title="Home"
            target="_self"
            $isActive={
              router.asPath === homeLink.href || router.asPath === '/home'
            }
          >
            {homeLogo && <StHomeLogo image={homeLogo} width={24} height={26} />}
            <StLabel>{homeLabel}</StLabel>
          </StItem>
          {mainLinks &&
            mainLinks.length > 0 &&
            mainLinks.map((item) => {
              const href = item.link?.href
                .split('/')
                ?.map((item) =>
                  item === 'en' || item === 'nl' || item === 'fr' ? null : item
                )
                .filter((item) => item !== null)
                .join('/')
              const validatedHref = href?.endsWith('/')
                ? href.slice(0, Math.max(0, href.length - 1))
                : href
              return (
                <StItem
                  key={item.id}
                  href={validatedHref ?? '/'}
                  $isActive={router.asPath === validatedHref}
                >
                  <StIcon icon={item.icon} />
                  <StLabel>{item?.label ?? ''}</StLabel>
                </StItem>
              )
            })}
          <StItem
            href={
              isAuthenticated
                ? '/my-cercle'
                : `/api/auth/login?returnTo=${encodeURIComponent(
                    process.env.NEXT_PUBLIC_FRONTEND_URL + router.asPath
                  )}`
            }
            $isActive={router.asPath === '/my-cercle'}
            data-event-label={
              isAuthenticated
                ? 'press_navigation_my_cercle_mobile'
                : 'press_navigation_login_mobile'
            }
          >
            <StIcon icon={faUser} />
            <StLabel>{t('navigation_my_cercle')}</StLabel>
          </StItem>

          <StHamburgerContainer onClick={handleSidebarToggle}>
            <HamburgerIcon />
            <StLabel>{menuLabel}</StLabel>
          </StHamburgerContainer>
        </StContentContainer>
      </StContainer>
    </>
  )
}
