import { isObject } from 'types'

import type { SbBlokData } from '@storyblok/react'

export interface SbSectionHeaderData extends SbBlokData {
  _uid: string
  title: string
  action: SbBlokData[]
}

export function isSbSectionHeaderData(
  blok: SbBlokData
): blok is SbSectionHeaderData {
  if (isObject(blok)) {
    // UID
    const { _uid } = blok
    if (typeof _uid !== 'string') {
      return false
    }
    // Title
    const { title } = blok
    if (typeof title !== 'string') {
      return false
    }
  } else {
    return false
  }
  return true
}
