import { StContainer, StImage, StLink } from './MatchHeader.styled'

import type { StoryblokImage } from 'types'

interface MatchHeaderProps {
  className?: string
  image?: StoryblokImage
  link: {
    url?: string
    title?: string
    rel?: string
    target?: string
  }
}

export const MatchHeader = ({ className, image, link }: MatchHeaderProps) => {
  return (
    <StContainer className={className}>
      {image &&
        (link.url ? (
          <StLink
            href={link.url ?? '#'}
            rel={link.rel ?? 'external'}
            target={link.target ?? '_self'}
            title={link.title}
          >
            <StImage image={image} />
          </StLink>
        ) : (
          <StImage image={image} />
        ))}
    </StContainer>
  )
}
