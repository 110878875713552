import { Loader } from '../../../shared/components/loader'

import {
  StContainer,
  StPlayerInformation,
  StGeneralStats,
  StOffensiveStats,
  StDefensiveStats,
  StLoadingContainer,
} from './PlayerStats.styled'

import type { PlayerStat, StoryblokImage } from 'types'

interface Props {
  className?: string
  height?: number
  playerNumber?: string
  birthDate?: Date
  homeTown?: string

  isKeeper: boolean
  isLoading?: boolean

  stats: PlayerStat[]

  image?: StoryblokImage
}

export const PlayerStats = ({
  className,
  height,
  playerNumber,
  birthDate,
  homeTown,
  isLoading = false,
  isKeeper = false,
  stats,
  image,
}: Props) => {
  if (isLoading) {
    return (
      <StLoadingContainer>
        <Loader />
      </StLoadingContainer>
    )
  }

  return (
    <StContainer className={className}>
      <StPlayerInformation
        height={height}
        playerNumber={playerNumber}
        birthDate={birthDate}
        homeTown={homeTown}
        image={image}
      />
      <StGeneralStats stats={stats} isKeeper={isKeeper} />
      {!isKeeper && (
        <>
          <StOffensiveStats stats={stats} />
          <StDefensiveStats stats={stats} />
        </>
      )}
    </StContainer>
  )
}
