import { storyblokEditable } from '@storyblok/react'
import { useMemo } from 'react'

import { useSWR } from '../../../shared/hooks/useSWR'
import { StandingsTable } from '../../../standings/components/standings-table'
import { mapToStandingRows } from '../../../standings/components/standings-table/utils/mapToStandingRow'
import { useStoryblokStories } from '../../hooks/useStoryblokStories'

import type { StandingRow } from '../../../standings/components/standings-table/types/row'
import type { StoryblokTeam } from '../../content-types/team'
import type { Standing } from 'types'

type Props = {
  blok: {
    _uid: string
  }
}

export const YoungCercleStandings = ({ blok }: Props) => {
  const { stories: teams, isLoading: isStoryblokLoading } =
    useStoryblokStories<StoryblokTeam>({
      starts_with: 'configuration/clubs/young-cercle/',
      content_type: 'club',
      per_page: 100,
      sort_by: 'first_published_at:desc',
    })

  const { data: standings, isLoading: isStandingsLoading } = useSWR<Standing[]>(
    '/standings/young-cercle'
  )

  const rows = useMemo<StandingRow[]>(() => {
    if (!standings || !teams) {
      return []
    }

    return mapToStandingRows(teams, standings)
  }, [teams, standings])

  return (
    <div {...storyblokEditable(blok)}>
      <StandingsTable
        rows={rows}
        isLoading={isStoryblokLoading || isStandingsLoading}
      />
    </div>
  )
}
