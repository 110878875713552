import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodyMediumBold,
  BodySmallBold,
  BodySmallRegular,
  Heading4,
} from '../../../shared/components/typography'

const CLUB_NAME_BREAKPOINT = 1200

export const StContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  height: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    padding: 0;
  }
`
export const StTitleContainer = styled.div`
  display: none;
  width: 100%;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  text-align: center;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`

export const StTitle = styled(BodySmallBold)``

export const StContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StTeamContainer = styled.div`
  display: flex;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StTeam = styled(Heading4)``

export const StInfo = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StHomeLabel = styled.span`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space1};
  margin-left: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StCalendarContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex: 1;
    display: flex;
    background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
    padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
    flex-direction: column;
    justify-content: space-between;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  }
`

export const StCalendarMatchContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darker};
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space2} 0;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    padding: 0;
  }
`

export const StCalendarMatch = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StCalendarItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StCalendarClubName = styled(BodyMediumBold)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }

  @media (min-width: ${CLUB_NAME_BREAKPOINT}px) {
    display: block;
  }
`

export const StMobileCalendarClubName = styled(BodyMediumBold)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }

  @media (min-width: ${CLUB_NAME_BREAKPOINT}px) {
    display: none;
  }
`

export const StCalendarDate = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
  white-space: nowrap;
`

export const StCalendarHome = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  align-self: center;
  width: ${({ theme }) => theme.UI.SpacingPx.Space5};
  height: ${({ theme }) => theme.UI.SpacingPx.Space5};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StCalendarHomeLetter = styled(BodySmallRegular)`
  margin: 0;
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StCalendarButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const StClubLogo = styled(StoryblokServiceImage)`
  width: 40px;
  height: 40px;
`

export const StCalendarClubLogo = styled(StoryblokServiceImage)`
  width: 32px;
  height: 32px;
`
