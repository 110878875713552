import { storyblokEditable } from '@storyblok/react'

import { SocialIcon as SocialIconComponent } from '../../../footer/components/social-icon'
import { getHrefFromStoryblokLink } from '../../utils/getHrefFromStoryblokLink'

import { isStoryblokImageLink } from './ImageLink.types'

import type { SbBlokData } from '@storyblok/react'

type ImageLinkProps = {
  blok: SbBlokData
}

export const ImageLink = ({ blok }: ImageLinkProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      {isStoryblokImageLink(blok) && (
        <SocialIconComponent
          image={blok.image}
          url={getHrefFromStoryblokLink(blok.link)}
          title={blok.link.title}
        />
      )}
    </div>
  )
}
