import styled from 'styled-components'

import { Heading1 } from '../../../shared/components/typography'

export const StArticleContainer = styled.div`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  max-width: ${({ theme }) => theme.UI.MaxWidthPx.Article};

  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};
  }
`

export const StArticleTitle = styled(Heading1)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space6};
  margin-top: ${({ theme }) => theme.UI.SpacingPx.Space20};
`

export const StMatchReportContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space13};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    margin-bottom: 160px;
  }
`
