import { useState, useCallback } from 'react'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'

import {
  StContainer,
  StHighlightsContainer,
  StHighlightLink,
  StHighlightOverlay,
  StHighlightGradient,
  StHighlightTitle,
  StHighlightTitleShort,
  StStaticContainer,
  StStaticTitle,
  StImage,
} from './HighlightBannerSkewed.styled'

import type { HighlightBannerItem } from '../../types/HighlightBanner.types'

type HighlightBannerSkewedProps = {
  className?: string
  items: HighlightBannerItem[]
}

export const HighlightBannerSkewed = ({
  className,
  items,
}: HighlightBannerSkewedProps) => {
  const [activeHighlight, setActiveHighlight] = useState<number>(0)

  const handleHighlightHover = useCallback(
    (index: number) => {
      if (activeHighlight !== index) {
        setActiveHighlight(index)
      }
    },
    [activeHighlight]
  )

  if (!items || items.length <= 0) {
    return null
  }

  if (items.length === 1) {
    return (
      <StContainer className={className}>
        <StStaticContainer>
          <StHighlightLink
            key={items[0].id}
            href={items[0].link?.href ?? '#'}
            $isActive={true}
          >
            <StoryblokServiceImage
              image={items[0].image}
              sizeMobile="100vw"
              sizeTablet="100vw"
              sizeDesktop="100vw"
            />
            <StHighlightOverlay $isActive={true} />
            <StHighlightGradient />
            <StStaticTitle>{items[0]?.full_title}</StStaticTitle>
          </StHighlightLink>
        </StStaticContainer>
      </StContainer>
    )
  }

  return (
    <StContainer className={className}>
      <StHighlightsContainer>
        {items.map((item, index) => {
          return (
            <StHighlightLink
              key={item.id}
              href={item.link?.href ?? '#'}
              $isActive={activeHighlight === index}
              onMouseEnter={() => handleHighlightHover(index)}
            >
              <StImage
                priority
                image={item.image}
                sizeMobile="100vw"
                sizeTablet="100vw"
                sizeDesktop="60vw"
              />
              <StHighlightOverlay $isActive={activeHighlight === index} />
              <StHighlightGradient />
              <StHighlightTitle
                isFirst={index === 0}
                isShowing={activeHighlight === index}
              >
                {item?.full_title}
              </StHighlightTitle>
              <StHighlightTitleShort isShowing={activeHighlight !== index}>
                {item?.small_title}
              </StHighlightTitleShort>
            </StHighlightLink>
          )
        })}
      </StHighlightsContainer>
    </StContainer>
  )
}
