import { storyblokEditable } from '@storyblok/react'

import { Sponsors as SponsorsComponent } from '../../../shared/components/sponsors'

export type StoryblokSponsorsProps = {
  blok: {
    selection: 'b2c' | 'jeugd' | 'b2b'
  }
}

export const Sponsors = ({ blok }: StoryblokSponsorsProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <SponsorsComponent selection={blok.selection} />
    </div>
  )
}
