import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { BodyMediumLinkStyles } from '../../../shared/components/typography'

export const StPagination = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: calc(${({ theme }) => theme.UI.SpacingPx.Space35} / 2) auto
    ${({ theme }) => theme.UI.SpacingPx.Space10} auto;
  width: 100%;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    margin: ${({ theme }) => theme.UI.SpacingPx.Space20} auto
      ${({ theme }) => theme.UI.SpacingPx.Space30} auto;
  }
`

export const StPaginationIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.Colors.Primary.Base};
  height: 16px;
`

const StPaginationIconButton = styled.button`
  border: 0;
  background-color: ${({ theme }) =>
    theme.Colors.Components.Button.Secondary.DisabledFill};
  transform: skew(-10deg);
  padding: 10px 15px;
  line-height: 16px;

  &:hover {
    cursor: pointer;
  }
`

export const StPaginationIconButtonMarginRight = styled(StPaginationIconButton)`
  margin-right: 24px;
`

export const StPaginationIconButtonMarginLeft = styled(StPaginationIconButton)`
  margin-left: 24px;
`

export const StPaginationButtonContainer = styled.div`
  width: calc(30px + ${({ theme }) => theme.UI.SpacingPx.Space6});
  text-align: center;
`

export const StPaginationButton = styled.button`
  ${BodyMediumLinkStyles}
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};
  text-underline-offset: ${({ theme }) => theme.UI.SpacingPx.Space1};
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  &:hover {
    cursor: pointer;
  }
`

export const StPaginationButtonContainerMobile = styled(
  StPaginationButtonContainer
)`
  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: none;
  }
`

export const StPaginationButtonContainerTablet = styled(
  StPaginationButtonContainer
)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: block;
  }
`

export const StPaginationButtonTablet = styled(StPaginationButton)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: block;
  }
`

export const StPaginationButtonContainerDesktop = styled(
  StPaginationButtonContainer
)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`

export const StPaginationButtonDesktop = styled(StPaginationButton)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`

export const StPaginationDivider = styled.span`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space3};
`

export const StPaginationDividerMobile = styled(StPaginationDivider)`
  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: none;
  }
`

export const StPaginationDividerContainerTablet = styled(
  StPaginationButtonContainerTablet
)`
  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StPaginationDividerTablet = styled(StPaginationDivider)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    display: block;
  }

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    display: none;
  }
`

export const StPaginationDividerDesktop = styled(StPaginationDivider)`
  display: none;

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Desktop}) {
    display: block;
  }
`

export const StCurrentPage = styled(StPaginationButton)`
  text-decoration: none;
  color: ${({ theme }) => theme.Colors.Primary.Base};

  &:hover {
    cursor: default;
  }
`
