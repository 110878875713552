import type { HtmlHTMLAttributes } from 'react'

export const BulletPointHexagon = (props: HtmlHTMLAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={12}
      height={13}
      viewBox="0 0 12 13"
      {...props}
    >
      <path d="M12,3.25v6.5l-6,3.25L0,9.75V3.25L6,0l6,3.25Z" fill="#60b22c" />
    </svg>
  )
}
