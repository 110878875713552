export function timestampToLocaleDatestring(
  timestamp: string,
  locale: string
): string

// eslint-disable-next-line no-redeclare
export function timestampToLocaleDatestring(
  timestamp: Date,
  locale: string
): string

// eslint-disable-next-line no-redeclare
export function timestampToLocaleDatestring(
  timestamp: string | Date,
  locale: string
) {
  if (typeof timestamp === 'string') {
    return new Date(timestamp).toLocaleDateString(locale, {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    })
  }

  return timestamp.toLocaleDateString(locale, {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  })
}
