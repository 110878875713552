import { useStoryblokApi } from '@storyblok/react'
import { useEffect, useState } from 'react'

import { getStoryblokStoryVersion } from '../utils/env'

import type {
  ISbStoriesParams,
  ISbStoryData,
  ISbStories,
} from '@storyblok/react'

export const useStoryblokStories = <T>({ ...parameters }: ISbStoriesParams) => {
  const [isLoading, setIsLoading] = useState(true)
  const [stories, setStories] = useState<ISbStoryData<T>[]>([])
  const storyblokApi = useStoryblokApi()

  useEffect(() => {
    const getStories = async () => {
      if (storyblokApi) {
        setIsLoading(true)
        const storiesPage: ISbStories = await storyblokApi.getStories({
          ...parameters,
          version: getStoryblokStoryVersion(),
        })
        setStories(storiesPage.data.stories as ISbStoryData<T>[])
        setIsLoading(false)
      }
    }

    getStories()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storyblokApi, JSON.stringify(parameters)])

  return { stories, isLoading }
}
