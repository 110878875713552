import styled from 'styled-components'

import { Section } from '../../../shared/components/section'

export const StContainer = styled(Section)`
  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space4};
  max-width: ${({ theme }) =>
    theme.UI.MaxWidth.Article + theme.UI.Spacing.Space4}px;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space6};

    max-width: ${({ theme }) =>
      theme.UI.MaxWidth.Article + theme.UI.Spacing.Space6}px;
  }
`
