import styled from 'styled-components'

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex-direction: row;
    gap: ${({ theme }) => theme.UI.SpacingPx.Space10};
  }
`

export const StTwoThirds = styled.div`
  flex: 2;
`

export const StOneThird = styled.div`
  flex: 1;
`
