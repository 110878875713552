import styled from 'styled-components'

export const StContainer = styled.div<{
  alignment: 'flex-start' | 'center' | 'flex-end'
}>`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  justify-content: ${({ alignment }) => alignment};
  align-items: center;

  padding: 0 ${({ theme }) => theme.UI.SpacingPx.Space2};
`
