import { storyblokEditable } from '@storyblok/react'

import { PromoCard as PromoCardComponent } from '../../../shared/components/promo-card'

import type { SbButtonProps } from '../button'
import type { StoryblokImage } from 'types'

type PromoCardStoryblokProps = {
  blok: {
    title: string
    subtitle?: string
    button: SbButtonProps[]
    background_image?: StoryblokImage
    use_color: boolean
    is_square: boolean
    first_color?: { color: string }
    use_gradient: boolean
    second_color?: { color: string }
    gradient_angle?: number
    use_overlay: boolean
  }
}

export const PromoCard = ({ blok }: PromoCardStoryblokProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      <PromoCardComponent
        title={blok.title}
        subtitle={blok.subtitle}
        button={blok.button?.[0] || undefined}
        backgroundImage={blok.background_image}
        isSquare={blok.is_square}
        useColor={blok.use_color}
        firstColor={blok.first_color?.color}
        useGradient={blok.use_gradient}
        secondColor={blok.second_color?.color}
        gradientAngle={blok.gradient_angle}
        useOverlay={blok.use_overlay}
      />
    </div>
  )
}
