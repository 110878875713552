import styled from 'styled-components'

import { ButtonLabelTertiaryStyles } from '../typography'

export const StContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
`

export const StSelect = styled.select`
  // A reset of styles, including removing the default dropdown arrow
  appearance: none;
  // Additional resets for further consistency
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  border: none;
  padding: 8px;
  margin: 0;
  margin-left: -8px;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  ${ButtonLabelTertiaryStyles}
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  &&::-ms-expand {
    display: none;
  }
`
