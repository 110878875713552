import styled from 'styled-components'

export const StContainer = styled.div`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space4} 0px;
  height: 1px;
  background: ${({ theme }) =>
    `linear-gradient(90deg, ${theme.Colors.Neutrals.Dark} 0%, rgba(75, 75, 75, 0) 100%)`};

  @media screen and (min-width: ${({ theme }) =>
      theme.UI.BreakpointPx.Tablet}) {
    width: 1px;
    height: unset;
    margin: 0px ${({ theme }) => theme.UI.SpacingPx.Space10};
    background-color: ${({ theme }) => theme.Colors.Neutrals.Dark};
  }
`
