import { storyblokEditable } from '@storyblok/react'

import { useSWR } from '../../../shared/hooks/useSWR'
import { PlayerStats as PlayerStatsComponent } from '../../../stats/components/player-stats'
import { getValidStoryblokDate } from '../../utils/checkStoryblokDate'

import type { StoryblokPlayer } from '../player-card/PlayerCard.types'
import type { SquadPlayer, StoryblokImage } from 'types'

export type PlayerStatsProps = {
  blok: {
    _uid: string
    player: {
      content: StoryblokPlayer
    }
    image?: StoryblokImage
  }
}

export const PlayerStats = ({ blok }: PlayerStatsProps) => {
  const { data: squadPlayer, isLoading: isPlayerLoading } = useSWR<SquadPlayer>(
    blok.player.content?.stats_data_id
      ? `/stats/players/${blok.player.content.stats_data_id}`
      : ''
  )

  const validBirthDate = getValidStoryblokDate(blok.player.content?.birth_date)

  return (
    <div {...storyblokEditable(blok)}>
      <PlayerStatsComponent
        isLoading={isPlayerLoading}
        height={blok.player.content?.height}
        playerNumber={blok.player.content?.number}
        birthDate={validBirthDate}
        isKeeper={blok.player.content?.position === 'keeper'}
        homeTown={squadPlayer?.placeOfBirth}
        stats={squadPlayer?.stat || []}
        image={blok.image}
      />
    </div>
  )
}
