import { isObject, isStoryblokImage } from 'types'

import type { SbBlokData } from '@storyblok/react'
import type { StoryblokImage } from 'types'

export interface SbArticlePageData extends SbBlokData {
  _uid: string
  title: string
  banner?: StoryblokImage
  thumbnail?: StoryblokImage
  tags: string[]
  publish_date: string
  release_date?: string
  children: SbBlokData[]
}

export function isSbArticlePageData(
  blok: SbBlokData
): blok is SbArticlePageData {
  if (isObject(blok)) {
    // UID
    const { _uid } = blok
    if (typeof _uid !== 'string') {
      return false
    }
    // Title
    const { title } = blok
    if (typeof title !== 'string') {
      return false
    }
    // Banner
    const { banner } = blok
    if (banner && !isStoryblokImage(banner)) {
      return false
    }
    // Thumbnail
    const { thumbnail } = blok
    if (thumbnail && !isStoryblokImage(thumbnail)) {
      return false
    }
    // Tags
    const { tags } = blok
    if (!Array.isArray(tags)) {
      return false
    }
  } else {
    return false
  }
  return true
}
