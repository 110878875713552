import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import {
  BodyMediumBold,
  BodySmallBold,
  BodySmallRegular,
  Heading3,
  Heading4,
} from '../../../shared/components/typography'

import type { RowStyleProps } from './types/row'
import type { MatchResult } from 'types'

const customTableBreakPoint = '830px'

export const StTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space12};
`

export const StMobileHeaderCell = styled.th`
  text-align: left;
`

export const StHeaderCell = styled(StMobileHeaderCell)`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: table-cell;
  }
`

export const StHeaderRow = styled.tr`
  & > th {
    padding: ${({ theme }) =>
      `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space2}`};

    @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
      padding: ${({ theme }) =>
        `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
    }
  }

  & > th:first-child {
    padding-right: ${({ theme }) => theme.UI.SpacingPx.Space8};
    text-align: right;

    @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
      padding-right: ${({ theme }) => theme.UI.SpacingPx.Space14};
    }
  }

  border-bottom: 1px solid;
  border-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};

  margin-bottom: ${({ theme }) => theme.UI.SpacingPx.Space2};
`

export const StRow = styled.tr<RowStyleProps>`
  & > td {
    padding: ${({ theme }) =>
      `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space2}`};

    @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
      padding: ${({ theme }) =>
        `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
    }
  }

  & > td:first-child {
    padding-left: 0px;

    @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
      padding: ${({ theme }) =>
        `${theme.UI.SpacingPx.Space4} ${theme.UI.SpacingPx.Space5}`};
    }
  }

  background-color: ${({ theme, isActive }) =>
    isActive ? theme.Colors.Neutrals.White : 'transparent'};

  &:nth-child(even) {
    background-color: ${({ theme, isActive }) =>
      isActive ? theme.Colors.Neutrals.White : theme.Colors.Neutrals.Darkest};
  }
`

export const StDivider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  margin: ${({ theme }) => theme.UI.SpacingPx.Space2} 0px;
`

export const StRankMoveContainer = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: block;
  }
`

export const StMobileCell = styled.td``

export const StCell = styled(StMobileCell)`
  display: none;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    display: table-cell;
  }
`

export const StFormHeaderText = styled(BodySmallRegular)`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StHeaderText = styled(BodySmallRegular)`
  text-align: center;

  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StHeaderTextHighlight = styled(BodySmallBold)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};

  text-align: center;
`

export const StHeaderTextHighlightLarge = styled(BodyMediumBold)`
  color: ${({ theme }) => theme.Colors.Neutrals.White};
`

export const StContentText = styled(BodySmallRegular)<RowStyleProps>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.Colors.Primary.Base : theme.Colors.Neutrals.Light};

  text-align: center;
`

export const StContentHighlightText = styled(BodySmallBold)<RowStyleProps>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.Colors.Primary.Base : theme.Colors.Neutrals.White};

  text-align: center;
`

export const StRankNumber = styled(Heading3)<RowStyleProps>`
  color: ${({ theme, isActive }) =>
    isActive ? theme.Colors.Primary.Base : theme.Colors.Neutrals.White};

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StTeamIdentityContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StTeamRankContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

export const StTeamName = styled(Heading4)<RowStyleProps>`
  display: none;
  color: ${({ theme, isActive }) =>
    isActive ? theme.Colors.Primary.Base : theme.Colors.Neutrals.White};

  @media (min-width: ${customTableBreakPoint}) {
    display: inline;
  }
`

export const StTeamNameShort = styled(StTeamName)<RowStyleProps>`
  display: inline;

  @media (min-width: ${customTableBreakPoint}) {
    display: none;
  }
`

export const StTeamImage = styled(StoryblokServiceImage)`
  display: inline-block;
  height: 24px;
  width: 24px;
  object-fit: center;

  @media (min-width: ${customTableBreakPoint}) {
    height: 32px;
    width: 32px;
  }

  margin-right: ${({ theme }) => theme.UI.SpacingPx.Space4};
`

export const StIcon = styled(FontAwesomeIcon)`
  font-size: 16px;
`

export const StStayIcon = styled(StIcon).attrs({ icon: faMinus })`
  color: ${({ theme }) => theme.Colors.Neutrals.Light};
`

export const StUpIcon = styled(StIcon).attrs({ icon: faChevronUp })`
  color: ${({ theme }) => theme.Colors.Semantic.Success};
`
export const StDownIcon = styled(StIcon).attrs({ icon: faChevronDown })`
  color: ${({ theme }) => theme.Colors.Semantic.Error};
`

export const StPerformanceIndicator = styled.div<{ performance: MatchResult }>`
  width: 10px;
  height: 10px;

  border-radius: 50%;

  background-color: ${({ theme, performance }) =>
    performance === 'W'
      ? theme.Colors.Semantic.Success
      : performance === 'L'
      ? theme.Colors.Semantic.Error
      : theme.Colors.Neutrals.Light};
`

export const StLastFiveGamesContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: ${({ theme }) => theme.UI.SpacingPx.Space1};
`

export const StLoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
`
