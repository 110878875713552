import styled from 'styled-components'

import { StoryblokServiceImage } from '../../../shared/components/storyblok-service-image'
import { Heading1 } from '../../../shared/components/typography'

export const StContainer = styled.div`
  overflow: hidden;
  position: relative;
`

export const StContentContainer = styled.div`
  display: flex;
`
export const StSlide = styled.a`
  height: 210px;
  flex: 0 0 100%;
  min-width: 0;
  position: relative;

  @media (min-width: 425px) {
    height: 300px;
  }

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    height: 400px;
  }

  &&:visited,
  &&:link,
  &&:hover,
  &&:active {
    color: ${({ theme }) => theme.Colors.Neutrals.White};
    text-decoration: none;
  }
`

export const StImage = styled(StoryblokServiceImage)`
  width: 1024px;
  height: 400px;
`

export const StOverlayGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(34, 34, 34, 0) 100%
  );
`

export const StTitle = styled(Heading1)`
  position: absolute;
  padding: ${({ theme }) => theme.UI.SpacingPx.Space4};
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    bottom: ${({ theme }) => theme.UI.SpacingPx.Space12};
  }
`

export const StCounterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: ${({ theme }) => theme.UI.SpacingPx.Space4};
  padding: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space4}`};
  width: 100%;
  gap: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space2}`};

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Tablet}) {
    bottom: ${({ theme }) => theme.UI.SpacingPx.Space8};
    gap: ${({ theme }) => `0 ${theme.UI.SpacingPx.Space8}`};
  }
`

export const StCount = styled.div<{ isActive: boolean }>`
  height: 5px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.Colors.Primary.Base : '#ffffff'};
  opacity: ${({ isActive }) => (isActive ? '1' : '0.2')};
  width: 100%;
  transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
`
