import { faRightFromBracket } from '@fortawesome/pro-solid-svg-icons/faRightFromBracket'

import { Button } from '../../../shared/components/button'
import { Heading1 } from '../../../shared/components/typography'
import { useStoryblokTranslationsContext } from '../../../storyblok/context/translations-provider'
import { useTranslations } from '../../../storyblok/hooks/useTranslations'

import { StContainer, StName } from './AccountWelcome.styled'

type AccountWelcomeProps = {
  message: string
  name?: string | undefined | null
}

export const AccountWelcome = ({ message, name }: AccountWelcomeProps) => {
  const { translations } = useStoryblokTranslationsContext()
  const { t } = useTranslations(translations)

  return (
    <StContainer>
      <Heading1>
        {message} <br /> {name ? <StName>{name}</StName> : null}
      </Heading1>

      <Button
        variant="tertiary"
        prefixIcon={faRightFromBracket}
        onClick={() => (window.location.href = '/api/auth/logout')}
        gaEventLabel="press_logout_my_cercle"
      >
        {t('logout')}
      </Button>
    </StContainer>
  )
}
