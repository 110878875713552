import {
  arrow,
  autoUpdate,
  FloatingArrow,
  FloatingPortal,
  offset,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'
import { faRepeat } from '@fortawesome/pro-solid-svg-icons/faRepeat'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/sharp-solid-svg-icons/faArrowLeft'
import { faArrowRight } from '@fortawesome/sharp-solid-svg-icons/faArrowRight'
import { faFutbol } from '@fortawesome/sharp-solid-svg-icons/faFutbol'
import { Fragment, useRef, useState } from 'react'
import { useTheme } from 'styled-components'

import { BodyMediumRegular } from '../../../shared/components/typography'
import { FoulCardIcon } from '../../../storyblok/components/match-live-update/icons/FoulCard'
import { StoryblokMatchLiveUpdateType } from '../../../storyblok/content-types/match'

import {
  StEventIcons,
  StEventTooltip,
  StSpacer,
  StTooltipIcons,
} from './MatchTimeLine.styled'

import type { MatchEvent } from './MatchTimeLine'
import type { ReactElement } from 'react'

interface Props {
  minute: number
  events: MatchEvent[]
  isBothTeams: boolean
  isAwayTeam: boolean
  showTeam: 'home' | 'away'
}

export const TimeLineEventTooltip = ({
  minute,
  events,
  isBothTeams,
  isAwayTeam,
  showTeam,
}: Props) => {
  const theme = useTheme()

  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: showTeam === 'home' ? 'top' : 'bottom',
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(18),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const hover = useHover(context, { move: false })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  const getIcon = (
    type: StoryblokMatchLiveUpdateType | 'player_in' | 'player_out',
    tooltip?: boolean
  ) => {
    switch (type) {
      case StoryblokMatchLiveUpdateType.Goal: {
        return (
          <FontAwesomeIcon
            icon={faFutbol}
            color={tooltip ? 'black' : 'white'}
            fontSize={tooltip ? 16 : 22}
          />
        )
      }
      case StoryblokMatchLiveUpdateType.YellowCard:
      case StoryblokMatchLiveUpdateType.RedCard: {
        return (
          <FoulCardIcon
            color={
              type === StoryblokMatchLiveUpdateType.YellowCard
                ? theme.Colors.Components.StatsCardYellow
                : theme.Colors.Components.StatsCardRed
            }
            height={tooltip ? 18 : 22}
            width={tooltip ? 13 : 16}
          />
        )
      }
      case StoryblokMatchLiveUpdateType.PlayerChange: {
        return (
          <FontAwesomeIcon
            icon={faRepeat}
            color={tooltip ? 'black' : 'white'}
            fontSize={tooltip ? 16 : 22}
          />
        )
      }
      case 'player_in': {
        return (
          <FontAwesomeIcon
            icon={faArrowLeft}
            color={theme.Colors.Primary.Darker}
            fontSize={tooltip ? 16 : 22}
          />
        )
      }
      case 'player_out': {
        return (
          <FontAwesomeIcon
            icon={faArrowRight}
            color={theme.Colors.Components.StatsCardRed}
            fontSize={tooltip ? 16 : 22}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  const icons = (team: 'home' | 'away') => {
    const teamEvents = events
      .filter((event) => event.team === team)
      .filter(
        (event) => event.type !== 'player_in' && event.type !== 'player_out'
      )

    return [...new Set(teamEvents.map((event) => getIcon(event.type)))]
  }

  const filteredEvents = events
    .filter(
      (event) =>
        event.team === showTeam &&
        event.type !== StoryblokMatchLiveUpdateType.PlayerChange
    )
    .reduce(
      (acc, event) => {
        // group all events together that have the same text
        const index = acc.findIndex((e) => e.text === event.text)
        if (index === -1) {
          acc.push({
            icons: [getIcon(event.type, true)],
            text: event.text,
            spacer: event.type === 'player_out',
          })
        } else {
          acc[index].icons.push(getIcon(event.type, true))
        }

        return acc
      },
      [] as {
        icons: Array<ReactElement | null>
        text: string
        spacer: boolean
      }[]
    )

  return (
    <>
      <StEventIcons ref={refs.setReference} {...getReferenceProps()}>
        {(isBothTeams || (showTeam === 'home' ? !isAwayTeam : isAwayTeam)) &&
          icons(showTeam).map((icon, index) => (
            <Fragment key={`home_events_${minute}_${index}`}>{icon}</Fragment>
          ))}
      </StEventIcons>
      <FloatingPortal>
        {isOpen && (
          <StEventTooltip
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <FloatingArrow
              ref={arrowRef}
              context={context}
              fill="white"
              width={16}
              height={8}
              tipRadius={2}
            />
            {filteredEvents.map((event, index) => {
              return (
                <Fragment key={`tooltip_row_${showTeam}_${minute}_${index}`}>
                  {event.spacer && index !== 0 && <StSpacer />}
                  <StTooltipIcons>
                    {event.icons.map((icon, index) => (
                      <Fragment
                        key={`tooltip_icon_${showTeam}_${minute}_${index}`}
                      >
                        {icon}
                      </Fragment>
                    ))}
                  </StTooltipIcons>
                  <BodyMediumRegular>{event.text}</BodyMediumRegular>
                </Fragment>
              )
            })}
          </StEventTooltip>
        )}
      </FloatingPortal>
    </>
  )
}
