import { Heading4 } from '../../../shared/components/typography'

import { StCard, StStatsRow } from './StatsCard.styled'

import type { ReactNode } from 'react'

interface Props {
  title: string
  className?: string
  children?: ReactNode
}

export const StatsCard = ({ children, className, title }: Props) => {
  return (
    <StCard className={className}>
      <Heading4>{title}</Heading4>
      <StStatsRow>{children}</StStatsRow>
    </StCard>
  )
}
