enum SpacingNumeric {
  Space1 = 4,
  Space2 = 8,
  Space3 = 12,
  Space4 = 16,
  Space5 = 20,
  Space6 = 24,
  Space7 = 28,
  Space8 = 32,
  Space9 = 36,
  Space10 = 40,
  Space11 = 44,
  Space12 = 48,
  Space13 = 52,
  Space14 = 56,
  Space15 = 60,
  Space16 = 64,
  Space17 = 68,
  Space18 = 72,
  Space19 = 76,
  Space20 = 80,
  Space21 = 84,
  Space22 = 88,
  Space23 = 92,
  Space24 = 96,
  Space25 = 100,
  Space26 = 104,
  Space27 = 108,
  Space28 = 112,
  Space29 = 116,
  Space30 = 120,
  Space31 = 124,
  Space32 = 128,
  Space33 = 132,
  Space34 = 136,
  Space35 = 140,
  Space36 = 144,
  Space37 = 148,
  Space38 = 152,
  Space39 = 156,
  Space40 = 160,
  Space41 = 164,
  Space42 = 168,
  Space43 = 172,
  Space44 = 176,
  Space45 = 180,
  Space46 = 184,
  Space47 = 188,
  Space48 = 192,
  Space49 = 196,
  Space50 = 200,
  Space51 = 204,
}

// Helper method for typed enum key mapping
function enumKeys<O extends object, K extends keyof O = keyof O>(
  object: O
): K[] {
  return Object.keys(object).filter((k) => Number.isNaN(+k)) as K[]
}

const SpacingString = enumKeys(SpacingNumeric).reduce(
  (accumulator, spaceKey) => {
    accumulator[spaceKey] = SpacingNumeric[spaceKey] + 'px'

    return accumulator
  },
  {} as Record<keyof typeof SpacingNumeric, string>
)

export { SpacingNumeric, SpacingString }
