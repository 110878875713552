import styled from 'styled-components'

import { Section } from '../../../shared/components/section'

export const StFullWidthSectionContainer = styled(Section)`
  background-color: ${({ theme }) => theme.Colors.Neutrals.Darkest};
  padding-top: ${({ theme }) => theme.UI.SpacingPx.Space20};
  padding-bottom: ${({ theme }) => theme.UI.SpacingPx.Space20};
  margin-top: 0;

  *:last-child {
    margin-bottom: 0;
  }
`
