import styled from 'styled-components'

export const StContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.UI.SpacingPx.Space4} 0;
  flex: auto;

  @media (min-width: ${({ theme }) => theme.UI.BreakpointPx.Desktop}) {
    flex-direction: row;
    gap: 0 ${({ theme }) => theme.UI.SpacingPx.Space8};
    flex: 1;
  }
`

export const StFourth = styled.div`
  flex: 1;
`

export const StHalf = styled.div`
  flex: 2;
`

export const StLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 300px;
`
