import type { HtmlHTMLAttributes } from 'react'

type SvgProps = HtmlHTMLAttributes<SVGElement> & {
  width?: number
  height?: number
  color?: string
}

export const FoulCardIcon = (props: SvgProps) => {
  return (
    <svg
      width="23"
      height="32"
      viewBox="0 0 23 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="22.2891"
        y="31.4"
        width="22.2895"
        height="30.8"
        rx="3.65"
        transform="rotate(-180 22.2891 31.4)"
        fill={props.color ?? '#FFC21C'}
      />
    </svg>
  )
}
